// BarChart.js
import * as d3 from "d3";
import React, { useRef, useEffect } from "react";

function BarChart({ width, height, data }) {
  const ref = useRef();

  useEffect(() => {
    // const svg = d3
    //   .select(ref.current)
    //   .attr("width", width)
    //   .attr("height", height)
    //   .style("border", "1px solid black");
  }, []);

  useEffect(() => {
    draw();
  }, [data]);

  const draw = () => {
    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height);
    // .style("border", "1px solid black");
    // List of subgroups = header of the csv files = soil condition here
    var data = [
      {
        group: "Python",
        submitted: 80,
        notSubmitted: 20,
      },
      {
        group: "HTML",
        submitted: 70,
        notSubmitted: 30,
      },
      {
        group: "Scratch",
        submitted: 40,
        notSubmitted: 60,
      },
    ];
    // List of groups = species here = value of the first column called group -> I show them on the X axis
    var groups = d3.map(data, function (d) {
      return d.group;
    });

    // Add X axis
    const x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2]);
    svg
      .append("g")
      .attr("transform", `translate(0, ${height - 20})`)
      .call(d3.axisBottom(x).tickSize(1));

    // Add Y axis
    const y = d3.scaleLinear().domain([0, 100]).range([height, 0]);
    svg
      .append("g")
      .attr("transform", "translate(30,10)")
      .call(d3.axisLeft(y).tickSize(10));

    // Another scale for subgroup position?
    const xSubgroup = d3
      .scaleBand()
      .domain(["submitted", "notSubmitted"])
      .range([0, x.bandwidth()])
      .padding([0.05]);

    // color palette = one color per subgroup
    const color = d3
      .scaleOrdinal()
      .domain(["submitted", "notSubmitted"])
      .range(["#377eb8", "#e41a1c"]);

    // Show the bars
    svg
      .append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .join("g")
      .attr("transform", (d) => `translate(${x(d.group)}, -40)`)
      .selectAll("rect")
      .data(function (d) {
        return ["submitted", "notSubmitted"].map(function (key) {
          return { key: key, value: d[key] };
        });
      })
      .join("rect")
      .attr("transform", "translate(30,0)")
      .attr("x", (d) => xSubgroup(d.key))
      .attr("transform", "translate(30,20)")
      .attr("y", (d) => y(d.value))
      .attr("width", xSubgroup.bandwidth())
      .attr("height", (d) => height - y(d.value))
      .attr("fill", (d) => color(d.key));

    svg
      .append("circle")
      .attr("cx", width - 270)
      .attr("cy", 10)
      .attr("r", 6)
      .style("fill", "#377eb8");

    svg
      .append("text")
      .attr("x", width - 250)
      .attr("y", 30)
      .text("Assignments not submitted on time")
      .style("font-size", "15px")
      .attr("alignment-baseline", "middle");

    svg
      .append("circle")
      .attr("cx", width - 270)
      .attr("cy", 30)
      .attr("r", 6)
      .style("fill", "#e41a1c");

    svg
      .append("text")
      .attr("x", width - 250)
      .attr("y", 10)
      .text("Assignments submitted on time")
      .style("font-size", "15px")
      .attr("alignment-baseline", "middle");
  };

  return (
    <div className="chart">
      <svg ref={ref}></svg>
    </div>
  );
}

export default BarChart;
