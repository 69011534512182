import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";

const PrevNextBtns = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isHideBtns, setIsHideBtns] = useState(false);

  useEffect(() => {
    if (location.pathname === "/login") {
      setIsHideBtns(true);
    } else {
      setIsHideBtns(false);
    }
  }, [location.pathname]);

  return (
    <div
      className={`${
        isHideBtns === true
          ? "hidden"
          : "flex items-center gap-2 border p-1 rounded-md"
      }`}
    >
      <div
        onClick={() => window.history.back()}
        className="border-2 rounded-full border-primary bg-secondary p-1 font-bold cursor-pointer"
      >
        <GrLinkPrevious className="text-primary" />
      </div>

      <div
        onClick={() => navigate(1)}
        className="border-2 rounded-full border-primary bg-secondary p-1 font-bold cursor-pointer"
      >
        <GrLinkNext className="text-primary" />
      </div>
    </div>
  );
};

export default PrevNextBtns;
