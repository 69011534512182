import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import MainLogo from "../assets/MainLogo.png";
import StorageService from "../constants/StorageService";
import MobileSideBar from "./MobileSideBar";
import useWindowSize from "./UseWindowSize";
import Cookies from "universal-cookie";
import PrevNextBtns from "./PrevNextBtns";

const Header = ({
  classesHover,
  assignmentsHover,
  teacherSideHomeHover,
  schoolsHover,
  divisionHover,
  gradesHover,
  teachersHover,
  myAssignmentHover,
  projectsHover,
  mySubmissionsHover,
  myStudiesHover,
  teacherAssignmentSubmissionsHover,
  teacherProjectSubmissionsHover,
}) => {
  const newStorageService = new StorageService();
  const { width } = useWindowSize();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const location = useLocation();
  const [menu, setMenu] = useState(false);
  const [hideMenuIcon, setHideMenuIcon] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isStudentLogin, setIsStudentLogin] = useState(false);
  const [isTeacherLogin, setIsTeacherLogin] = useState(false);
  const [username, setUsername] = useState(null);

  // check user logged in status
  const checkUserLoggedIn = () => {
    if (cookies.get("token")) {
      setIsLogin(true);

      if (cookies.get("isStudentLogin")) {
        setIsStudentLogin(true);
      } else {
        setIsStudentLogin(false);
      }

      if (cookies.get("isTeacherLogin")) {
        setIsTeacherLogin(true);
      } else {
        setIsTeacherLogin(false);
      }
    } else {
      setIsLogin(false);
    }
  };

  useEffect(() => {
    checkUserLoggedIn();
  }, [cookies]);

  useEffect(() => {
    let userData = localStorage.getItem("userData");
    if (userData && JSON.parse(userData)) {
      console.log(JSON.parse(userData));
      setUsername(JSON.parse(userData)["username"]);
    }
  }, []);

  // useEffect(() => {
  //   newStorageService.changes.subscribe((localStoreObject) => {
  //     checkUserLoggedIn();
  //   });
  // }, []);

  // hide menu icon for desktop-laptop and for login page
  useEffect(() => {
    if (width >= 1000 || location.pathname === "/login") {
      setHideMenuIcon(true);
    } else {
      setHideMenuIcon(false);
    }
  }, [width, location.pathname]);

  // toggle sidebar on menu icon
  const handleMenu = () => {
    setMenu(!menu);
  };

  const LogoutUser = () => {
    if (cookies.get("token")) {
      cookies.remove("token", { path: "/" });

      if (cookies.get("isStudentLogin")) {
        cookies.remove("isStudentLogin", { path: "/" });
      } else if (cookies.get("isTeacherLogin")) {
        cookies.remove("isTeacherLogin", { path: "/" });
      } else if (cookies.get("isAdminLogin")) {
        cookies.remove("isAdminLogin", { path: "/" });
      } else {
        return;
      }
    }

    navigate("/login");
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="flex flex-col">
      <div
        style={{ height: "10vh" }}
        className="flex items-center px-5 shadow-md border-b border-pr fixed w-full z-30 bg-white"
      >
        <div className="w-full flex justify-between items-center text-black">
          <div className="laptop:hidden pc:hidden">
            <button onClick={handleMenu}>
              {hideMenuIcon === true ? "" : <MenuIcon sx={{ fontSize: 30 }} />}
            </button>
          </div>

          <div className="ipad:hidden mobile:hidden">
            <PrevNextBtns />
          </div>

          <div className="flex">
            <div className="mobile:hidden">
              <img src={MainLogo} alt="main logo" />
            </div>

            <button
              className="font-semibold text-primary text-4xl mobile:text-2xl"
              onClick={() => {
                isLogin === true
                  ? isStudentLogin
                    ? navigate("/student/my-studies")
                    : isTeacherLogin
                    ? navigate("/home")
                    : navigate("/")
                  : navigate("/login");
              }}
            >
              CODESQUAD
            </button>
          </div>

          <div className="flex flex-row gap-2">
            {username && isLogin === true ? (
              <div className="text-lg ipad:hidden mobile:hidden md:flex">
                Welcome,
                <span className="font-bold">{username?.slice(0, 6)}</span>
                {username?.length > 6 ? "..." : ""}
              </div>
            ) : (
              <></>
            )}
            {isLogin === true ? (
              <button
                onClick={LogoutUser}
                className="font-bold text-primary uppercase text-sm bg-secondary p-1 border rounded-md mobile:text-xs mobile:font-semibold"
              >
                Logout
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {menu === true ? (
        <MobileSideBar
          menu={menu}
          setMenu={setMenu}
          classesHover={classesHover}
          assignmentsHover={assignmentsHover}
          teacherSideHomeHover={teacherSideHomeHover}
          schoolsHover={schoolsHover}
          divisionHover={divisionHover}
          gradesHover={gradesHover}
          teachersHover={teachersHover}
          myAssignmentHover={myAssignmentHover}
          projectsHover={projectsHover}
          mySubmissionsHover={mySubmissionsHover}
          myStudiesHover={myStudiesHover}
          teacherAssignmentSubmissionsHover={teacherAssignmentSubmissionsHover}
          teacherProjectSubmissionsHover={teacherProjectSubmissionsHover}
          username={username}
        />
      ) : null}
    </div>
  );
};

export default Header;
