import React from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { EXPRESS_API_BASE_URL } from "../constant";
import axios from "axios";

const HomePageTeacher = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [updatesArr, setUpdatesArr] = useState([]);
  let token = cookies.get("token");

  // check user logged in or not
  const checkUserLogin = () => {
    if (!cookies.get("token")) {
      navigate("/login");
    }
  };

  useEffect(() => {
    checkUserLogin();
  }, []);

  // get Dashboard Stats
  const getDashboardStats = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${EXPRESS_API_BASE_URL}/dashboard/teacher-updates`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("getDashboardStats res", res.data.data);
      setUpdatesArr(res.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("getDashboardStats error", error);
    }
  };

  useEffect(() => {
    getDashboardStats();

    setInterval(() => {
      getDashboardStats();
    }, 10000);
  }, []);

  return (
    <>
      <div className="flex w-4/5 mobile:w-full ipad:w-full position-class pb-10 mobile:pb-5">
        <div className="flex flex-col w-full">
          <div className="bg-secondary pt-5 px-5 pb-2 flex justify-between items-center mb-5 stickyNavbar z-40 mobile:pt-2">
            <p className="text-2xl font-semibold">Dashboard</p>
          </div>

          <div className="updates-section mx-5 mobile:my-8">
            <div className="flex justify-between items-center my-3 mobile:mt-5">
              <p className="text-2xl font-semibold  text-gray-500">Updates</p>
            </div>

            <div className="w-full bg-secondary overflow-y-auto border rounded-lg shadow-md p-4">
              {updatesArr?.length === 0 ? (
                <p className="text-lg p-2">No updates found!</p>
              ) : (
                <ul>
                  {updatesArr?.map((val, index) => {
                    return (
                      <li key={index}>
                        <p
                          className="font-bold cursor-pointer"
                          onClick={() =>
                            val?.entityType === "PROJECT"
                              ? navigate(
                                  `/notification/view/project/${val?.entityId}`
                                )
                              : val?.entityType === "ASSIGNMENT"
                              ? navigate(
                                  `/notification/view/assignment/${val?.entityId}`,
                                  { state: val?.type }
                                )
                              : ""
                          }
                        >
                          {val?.text}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageTeacher;
