import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import useWindowSize from "../constants/UseWindowSize";
import axios from "axios";
import { EXPRESS_API_BASE_URL } from "../constant";
import MainLogo from "../assets/MainLogo.png";
import Loader from "../customs/Loader";
import moment from "moment";

const MySubmissions = () => {
  const cookies = new Cookies();
  const { width, height } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [coursesData, setCoursesData] = useState([]);
  const [submissionsArr, setSubmissionsArr] = useState([]);
  let token = cookies.get("token");

  const getCoursesData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${EXPRESS_API_BASE_URL}/course/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log("courses", res.data.data);
      setCoursesData(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("get my courses error", error);
    }
  };

  useEffect(() => {
    getCoursesData();
  }, []);

  const newFunt = async () => {
    if (coursesData) {
      let newAr = [];
      for (let index = 0; index < coursesData.length; index++) {
        const element = coursesData[index];

        for (let i = 0; i < element?.assignments.length; i++) {
          const newElement = element?.assignments[i];

          let newRes = await axios.get(
            `${EXPRESS_API_BASE_URL}/course/${parseInt(
              newElement?.CourseId
            )}/assignment/${parseInt(newElement?.assignmentId)}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (
            newRes?.data.data[0]?.submission[0]?.submissionType ===
              "SUBMITTED" ||
            newRes?.data.data[0]?.submission[0]?.submissionType === "REVIEWED"
          ) {
            newAr.push(newRes?.data.data[0]);
            // console.log("newRes", newRes.data.data);
          }
        }
      }
      setSubmissionsArr(newAr);
    }
  };

  useEffect(() => {
    newFunt();
  }, [coursesData]);

  // calculation for total of marks/total marks
  const calculateScore = (val) => {
    // console.log("calculateScore called...", val);
    let newArr = val?.map((s) => {
      return parseInt(s?.score);
    });
    const sumOfMarks = newArr?.reduce((v, a) => v + a, 0);

    let newTMArr = val?.map((s) => {
      return parseInt(s?.question?.maxScore);
    });
    const TotalOfMaxScore = newTMArr?.reduce((v, a) => v + a, 0);

    let finalRes = "";
    if (sumOfMarks && TotalOfMaxScore) {
      finalRes = `${sumOfMarks}/${TotalOfMaxScore}`;
    }

    // console.log("finalRes", finalRes);
    return finalRes;
  };

  // console.log("submissionsArr", submissionsArr);

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="w-4/5 mobile:w-full ipad:w-full position-class pb-10">
          <div className="bg-secondary flex justify-between items-center p-5 stickyNavbar z-40">
            <p className="text-2xl font-semibold">Submissions</p>
          </div>

          {submissionsArr?.length === 0 ? (
            <p
              style={{
                position: "absolute",
                left: width < 500 ? "35%" : "40%",
              }}
              className="font-semibold text-xl pt-20"
            >
              No submissions found!
            </p>
          ) : (
            <div className="px-5">
              {submissionsArr?.map((val, index) => {
                return (
                  <div
                    key={index}
                    className="flex justify-start items-start bg-secondary w-full px-4 border rounded-xl shadow-md 
                      mobile:flex-col mt-4 h-36 laptop:h-32 ipad:h-36 mobile:h-80"
                  >
                    <div className="w-1/6 mobile:w-full overflow-hidden h-full flex justify-center items-center mobile:pt-4">
                      <img
                        src={
                          val && val?.assignment?.featureImage
                            ? val?.assignment?.featureImage
                            : MainLogo
                        }
                        alt="course logo"
                        className="w-3/5 laptop:w-4/5 ipad:w-4/5 mobile:w-1/2 mx-auto"
                      />
                    </div>

                    <div className="flex justify-between items-center w-4/5 pl-2 mobile:w-full h-full">
                      <div className="flex flex-col">
                        <p className="font-bold uppercase text-xl ipad:text-lg mobile:text-base">
                          {val?.assignment?.title}
                        </p>
                        <p className="my-2 font-semibold text-gray-800 capitalize mobile:text-sm">
                          {moment(val?.assignment?.createdAt).format(
                            "DD/MM/YYYY hh:mm"
                          )}
                        </p>
                      </div>

                      <div className="flex flex-col text-right">
                        <p className="font-bold">
                          {val?.submission[0]?.submissionType === "REVIEWED" &&
                          calculateScore(val?.submission) ? (
                            <span className="text-teal-400 uppercase text-lg mobile:text-sm">
                              Reviewed
                            </span>
                          ) : (
                            <span className="text-gray-500 uppercase text-lg mobile:text-sm">
                              Awating Review
                            </span>
                          )}
                        </p>

                        {val?.submission[0]?.submissionType === "REVIEWED" ? (
                          <p className="text-gray-700 font-bold">
                            {calculateScore(val?.submission)}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MySubmissions;
