import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../customs/Loader";
import { Rating, TextField } from "@mui/material";
import Cookies from "universal-cookie";
import FilledButton from "../customs/FilledButton";
import axios from "axios";
import { EXPRESS_API_BASE_URL } from "../constant";
import useWindowSize from "../constants/UseWindowSize";

const ScratchProjectEditor = () => {
  const cookies = new Cookies();
  const { projectId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  let token = cookies.get("token");
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);

  const getProjectDetailsByID = async () => {
    try {
      if (projectId) {
        const res = await axios.get(
          `${EXPRESS_API_BASE_URL}/project/${projectId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // console.log("ress", res.data?.data);
        setComment(res.data?.data?.comments);
        setRating(res.data?.data?.rating);
      }
    } catch (error) {
      console.log("getProjectDetailsByID error", error);
    }
  };

  useEffect(() => {
    getProjectDetailsByID();
  }, [projectId]);

  const evaluateProject = async () => {
    try {
      // console.log("states", comment, rating);
      const res = await axios.post(
        `${EXPRESS_API_BASE_URL}/project/${projectId}/evaluation`,
        { comments: comment, ratings: rating },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("evaluateProject res", res);
      // navigate(-1);
      window.history.back();
    } catch (error) {
      console.log("evaluateProject error", error);
    }
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="w-full mt-5 mx-auto mb-5 h-screen pb-10">
          <div className="w-full flex justify-center items-center h-full">
            <iframe
              src={state.url}
              style={{ width: "95%", height: "80%" }}
              title="Iframe Example"
            ></iframe>
          </div>

          {state?.dontEvaluate ? (
            <> </>
          ) : (
            <div>
              {state?.comments ? (
                <div className=" w-4/5 ipad:w-full mobile:w-full mx-auto relative -mt-16">
                  <p
                    className="text-xl mobile:text-base font-semibold flex justify-end items-center gap-2
                    ipad:justify-start ipad:items-start mobile:justify-start mobile:items-start mobile:right-2 "
                  >
                    Comment:{" "}
                    <span className="uppercase">{state?.comments} </span>
                    and Ratings:{" "}
                    <Rating
                      name="simple-controlled"
                      className=""
                      value={rating}
                      disabled
                    />
                  </p>
                </div>
              ) : cookies.get("isTeacherLogin") ? (
                <div className="w-4/5 ipad:w-full mobile:w-full mx-auto relative -mt-16">
                  <div
                    className="w-full mobile:right-2 flex justify-end items-center gap-5 ipad:flex-col
                    ipad:justify-start ipad:items-start mobile:flex-col mobile:justify-start mobile:items-start"
                  >
                    <div className="my-4 ipad:mb-0 mobile:mb-0 w-full">
                      <TextField
                        style={{
                          width: width > 900 ? "50%" : "100%",
                          float: "right",
                        }}
                        id="outlined-multiline-flexible"
                        label={"Add Comment"}
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      style={{
                        width: width > 900 ? "20%" : "50%",
                      }}
                    >
                      <p className="text-lg mobile:text-base font-semibold mx-auto">
                        Select Ratings
                      </p>

                      <Rating
                        name="simple-controlled"
                        value={rating}
                        onChange={(event, newValue) => {
                          setRating(newValue);
                        }}
                      />
                    </div>

                    <FilledButton
                      onClickFilled={() => evaluateProject()}
                      filledBtnText="Submit"
                      loading={loading}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ScratchProjectEditor;
