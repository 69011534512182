import React from "react";
import UnFilledButton from "./UnFilledButton";
import { RiDeleteBin6Line } from "react-icons/ri";
import MainLogo from "../assets/MainLogo.png";
import moment from "moment";

const ListAssignmentCard = (props) => {
  return (
    <div className="relative mb-4">
      {props?.isDelete === true ? (
        <button
          onClick={props?.handleRemove}
          className="absolute -right-3 -top-3 border rounded-full p-1 bg-red-500 z-30"
        >
          <RiDeleteBin6Line className="text-white text-lg" />
        </button>
      ) : (
        <></>
      )}

      <div
        key={props?.keyIndex}
        className="flex justify-start items-center bg-secondary border rounded-xl shadow-md
          w-full px-4 mobile:px-1 h-28 mobile:h-44 overflow-y-auto"
      >
        <div className="w-1/12 h-20 flex justify-center items-center overflow-hidden mobile:w-1/4 mx-2">
          <img
            src={props?.courseImg ? props.courseImg : MainLogo}
            alt="course logo"
            className="rounded-md mx-auto"
          />
        </div>

        <div className="flex justify-between items-center w-11/12 mobile:w-3/4 px-2 mobile:pt-6 mobile:flex-col h-full">
          <div className={`my-2 w-full`} onClick={props.onClickDiv}>
            <p
              className={`font-bold uppercase text-xl ipad:text-lg mobile:text-sm text-primary ${
                props?.onClickDiv ? "cursor-pointer" : ""
              } break-words`}
            >
              {props?.Title}
            </p>

            {/* {props?.CreatedBy ? (
              <p className="font-semibold text-xs">
                created by: <span className="text-sm">{props?.CreatedBy}</span>
              </p>
            ) : (
              <></>
            )} */}

            {props?.submittedBy ? (
              <p className="font-semibold text-xs">
                submitted by:{" "}
                <span className="text-sm">{props?.submittedBy}</span>
              </p>
            ) : (
              <></>
            )}

            {props?.ReviewedBy ? (
              <p className="font-semibold text-xs">
                reviewed by:{" "}
                <span className="text-sm">{props?.ReviewedBy}</span>
              </p>
            ) : (
              <></>
            )}

            {props?.submittedAt ? (
              <p className="font-semibold text-xs">
                submitted at:{" "}
                <span className="text-sm">
                  {moment(props?.submittedAt).format("DD-MM-YYYY hh:mm")}
                </span>
              </p>
            ) : (
              <></>
            )}
          </div>

          {props?.calculatedScore && props?.submissionType === "REVIEWED" ? (
            <p className="text-gray-700 font-bold">{props?.calculatedScore}</p>
          ) : (
            ""
          )}

          <div className="">
            {props?.unFilledBtnText ? (
              <UnFilledButton
                unFilledBtnText={props?.unFilledBtnText}
                onClickUnFilled={props?.onClickUnFilled}
                loading={props?.loading}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListAssignmentCard;
