import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AssignmentCard from "../customs/AssignmentCard";
import SchoolCard from "../customs/SchoolCard";
import FilledButton from "../customs/FilledButton";
import Loader from "../customs/Loader";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Cookies from "universal-cookie";
import axios from "axios";
import { EXPRESS_API_BASE_URL } from "../constant";
import moment from "moment";
import DeleteAlertBox from "../customs/DeleteAlertBox";
import UnFilledButton from "../customs/UnFilledButton";
import { toast } from "react-toastify";
import MainLogo from "../assets/MainLogo.png";

// select multiple style
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

// modal style
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fafdfa",
  boxShadow: 24,
  p: 2,
};

const CourseDetailsPage = () => {
  const { id } = useParams();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isUpdateAssignments, setUpdateAssignments] = useState(false);
  const [deleteObj, setDeleteObj] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assignementCalled, setAssignementCalled] = useState(true);
  const [schoolsCalled, setSchoolsCalled] = useState(false);
  const [detailsParamsData, setDetailsParamsData] = useState(null);
  const [enrolledStudents, setEnrolledStudents] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [selectedAssignments, setSelectedAssignments] = useState([]); // selected value from dropdown
  const [assignmentsResSelect, setAssignmentsResSelect] = useState([]); // assignments data for dropdown
  const [assignmentsResSelected, setAssignmentsResSelected] = useState([]); // getting assignments data to map from Course details
  let token = cookies.get("token");
  const [isTeacherLogin, setIsTeacherLogin] = useState(false);

  const checkUser = () => {
    // if (cookies.get("isStudentLogin")) {
    //   setIsStudentLogin(true);
    // } else {
    //   setIsStudentLogin(false);
    // }

    // if (cookies.get("isAdminLogin")) {
    //   setIsAdminLogin(true);
    // } else {
    //   setIsAdminLogin(false);
    // }

    if (cookies.get("isTeacherLogin")) {
      setIsTeacherLogin(true);
    } else {
      setIsTeacherLogin(false);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  // get course details page dynamic data
  const getCourseDetails = async () => {
    let newArr;
    if (id) {
      try {
        setLoading(true);
        const res = await axios.get(
          `${EXPRESS_API_BASE_URL}/course/${parseInt(id)}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        // console.log("getCourseDetails res", res.data.data);
        setDetailsParamsData(res.data.data);
        setAssignmentsResSelected(
          res.data.data?.assignments.map((v) => v?.assignment)
        );
        newArr = res.data.data?.cohorts?.map(
          (val) => val?.cohort?._count?.students
        );
        setEnrolledStudents(
          newArr?.reduce((partialSum, a) => partialSum + a, 0)
        );
        // console.log("newArr", res.data.data?.cohorts);
        setCreatedAt(res.data.data?.createdAt);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("getCourseDetails error", error);
      }
    }
  };

  useEffect(() => {
    getCourseDetails();
  }, []);

  // get assignments dynamic list for dropdown
  const getAssignments = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${EXPRESS_API_BASE_URL}/assignment`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log("getAssignments res", res.data.data);
      let newArr1 = res.data.data?.map((v) => v);
      let newArr2 = assignmentsResSelected?.map((v) => v);
      let anotherArr = newArr1.filter(
        (n) => !newArr2.find((rm) => rm.assignmentCode === n.assignmentCode)
      );
      let finalArr = anotherArr.filter(
        (val) => val.submittedStatus === "SUBMITTED"
      );
      setAssignmentsResSelect(finalArr);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("getAssignments error", error);
    }
  };

  useEffect(() => {
    getAssignments();
  }, [assignmentsResSelected]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log("value", value);
    if (assignementCalled === true) {
      setSelectedAssignments(
        typeof value === "string" ? value.split(",") : value
      );
    }
  };

  // adding assignment to the course
  const handleAddAssignments = async () => {
    if (selectedAssignments.length === 0) {
      toast.warn("Select atleast one assignment to Add!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      try {
        setLoading(true);
        await axios.post(
          `${EXPRESS_API_BASE_URL}/course/${id}/assignment/`,
          { assignments: selectedAssignments },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        // console.log("add assignments res", res.data.data);
        getCourseDetails();
        setSelectedAssignments([]);
        setOpen(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("add assignments error", error);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.warn(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      }
    }
  };

  // updating assignment to the course
  const handleUpdateAssignments = async () => {
    if (selectedAssignments.length === 0) {
      toast.warn("Select atleast one assignment to Update!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      try {
        setLoading(true);
        await axios.put(
          `${EXPRESS_API_BASE_URL}/course/${id}/assignment/`,
          { assignments: selectedAssignments },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        // console.log("Update assignments res", res.data.data);
        getCourseDetails();
        setSelectedAssignments([]);
        setUpdateAssignments(false);
        setOpen(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("Update assignments error", error);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.warn(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      }
    }
  };

  // removing assignment from course
  const handleRemove = async (val) => {
    try {
      setLoading(true);
      await axios.delete(`${EXPRESS_API_BASE_URL}/course/${id}/assignment/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { assignments: [val?.assignmentCode] },
      });
      // console.log("delete assignment res", res.data.data);
      getCourseDetails();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("delete assignment error", error);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.warn(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    }
  };

  // displaying names of selcted courses as Chip
  const handleSelectChip = (selected) => {
    let newArr = [];
    if (selected) {
      let matchedData = assignmentsResSelect?.find(
        (element) => element?.assignmentCode === selected
      );
      newArr.push(matchedData);
    }
    return newArr?.map((value, ind) => <Chip key={ind} label={value?.title} />);
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <>
          <div className="w-4/5 mobile:w-full ipad:w-full position-class">
            <div className="bg-secondary pt-5 border-b stickyNavbar z-40">
              <div className="flex justify-between items-start border-b px-5 pb-3 mobile:flex-col mobile:items-start">
                <div className="flex items-center gap-5 mobile:gap-2 ipad:flex-col mobile:flex-col ipad:items-start mobile:items-start">
                  <p className="text-3xl font-semibold mobile:text-xl mobile:font-bold break-words">
                    {detailsParamsData?.name}
                  </p>
                  <p className="font-semibold mobile:text-sm">
                    Enrolled Students:{" "}
                    <span className="font-bold mobile:text-sm">
                      {enrolledStudents}
                    </span>
                  </p>
                  <p className="font-semibold mobile:text-sm">
                    Created At:{" "}
                    <span className="font-bold mobile:text-sm">
                      {moment(createdAt).format("MM/DD/YYYY")}
                    </span>
                  </p>
                </div>

                <p className="font-semibold bg-primary px-2 py-1 text-white rounded-lg mobile:text-xs mobile:mt-2">
                  Course code:{" "}
                  <span className="font-bold mobile:text-xs">
                    {detailsParamsData?.CourseCode}
                  </span>
                </p>
              </div>

              <div className="flex justify-between items-center px-4 mobile:px-2">
                <div className="flex">
                  <p
                    onClick={() => {
                      setSchoolsCalled(false);
                      setAssignementCalled(true);
                    }}
                    className={`font-semibold ml-2 px-3 pt-2 pb-3 cursor-pointer bg-${
                      assignementCalled === true ? "primary" : "secondary"
                    } text-${assignementCalled === true ? "white" : "black"}`}
                  >
                    Assignments
                  </p>

                  {/* <p
                    onClick={() => {
                      setSchoolsCalled(true);
                      setAssignementCalled(false);
                    }}
                    className={`font-semibold px-3 pt-2 pb-3 cursor-pointer bg-${
                      schoolsCalled === true ? "primary" : "secondary"
                    } text-${schoolsCalled === true ? "white" : "black"}`}
                  >
                    Classes
                  </p> */}
                </div>

                {assignementCalled === true ? (
                  <div className="flex gap-2 mobile:my-2">
                    {assignmentsResSelect.length > 0 ? (
                      <FilledButton
                        onClickFilled={() => {
                          setOpen(true);
                          setUpdateAssignments(false);
                        }}
                        filledBtnText="Map assignments"
                        loading={loading}
                      />
                    ) : (
                      <></>
                    )}

                    {/* {assignmentsResSelect.length > 0 &&
                    assignmentsResSelected.length > 0 ? (
                      <UnFilledButton
                        onClickUnFilled={() => {
                          setOpen(true);
                          setUpdateAssignments(true);
                        }}
                        unFilledBtnText="Update assignments"
                        loading={loading}
                      />
                    ) : (
                      <></>
                    )} */}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            {assignementCalled === true ? (
              <div className="p-5">
                <div className="flex flex-wrap gap-5 mobile:justify-center">
                  {assignmentsResSelected?.map((val, index) => {
                    return (
                      <div key={index}>
                        <AssignmentCard
                          imageClassname="assignment-card-image-width"
                          loading={loading}
                          courseImg={
                            val?.featureImage ? val?.featureImage : MainLogo
                          }
                          Title={`${val?.title}`}
                          Desc={`${val?.description}`}
                          onClickDiv={() => {
                            navigate(
                              `/student/my-assignments-details/${parseInt(
                                val?.id
                              )}`
                            );
                          }}
                          deleteFilledBtnText="Remove"
                          onClickDeleteFilled={() => {
                            setIsDeleteOpen(true);
                            setDeleteObj(val);
                          }}
                          DeleteAlertBox
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}

            {schoolsCalled === true ? (
              <div className="p-5">
                <div className="flex flex-wrap gap-5 mobile:justify-center">
                  {detailsParamsData?.cohorts?.map((val, index) => {
                    return (
                      <div key={index}>
                        <SchoolCard
                          name={val?.cohort?.name}
                          onClickDiv={() =>
                            navigate(`/division-details/${val?.cohort?.id}`)
                          }
                          // unFilledBtnText="Details"
                          // onClickUnFilled={() =>
                          //   navigate(`/division-details/${val?.cohort?.id}`)
                          // }
                          noOfChildsText="Number of Students"
                          noOfChilds={parseInt(val?.cohort?._count?.students)}
                          loading={loading}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div>
            <DeleteAlertBox
              isDeleteOpen={isDeleteOpen}
              setIsDeleteOpen={setIsDeleteOpen}
              handleRemove={handleRemove}
              deleteObj={deleteObj}
              deleteModalTitle={`Are you sure you want to remove this assignment from the class?`}
            />
          </div>

          <div className="">
            <Modal
              open={open}
              onClose={() => {
                setOpen(false);
                setSelectedAssignments([]);
                setUpdateAssignments(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="w-96 rounded-xl create-class-modal">
                <div className="my-5">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-chip-label">
                      Select assignments
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={selectedAssignments}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Select assignments"
                        />
                      }
                      renderValue={(selected) => (
                        // <Box
                        //   sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        // >
                        //   {selected.map((value) => (
                        //     <Chip key={value} label={value} />
                        //   ))}
                        // </Box>

                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={handleSelectChip(value)} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {assignmentsResSelect?.map((val, index) =>
                        val?.title ? (
                          <MenuItem key={index} value={val?.assignmentCode}>
                            {val?.title}
                          </MenuItem>
                        ) : (
                          ""
                        )
                      )}
                    </Select>
                  </FormControl>

                  <div className="mt-5 mb-2 w-full flex justify-center items-center">
                    <button
                      onClick={
                        isUpdateAssignments === true
                          ? handleUpdateAssignments
                          : handleAddAssignments
                      }
                      className={`border border-primary bg-primary text-white text-center rounded-xl py-2 w-full`}
                    >
                      {isUpdateAssignments === true
                        ? "Update Assignments"
                        : "Map Assignments"}
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default CourseDetailsPage;
