import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { EXPRESS_API_BASE_URL } from "../constant";
import FilledButton from "../customs/FilledButton";
import Loader from "../customs/Loader";
import DeleteAlertBox from "../customs/DeleteAlertBox";
import { toast } from "react-toastify";
import SchoolCard from "../customs/SchoolCard";

const SchoolDetailsPage = () => {
  const { id } = useParams();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isTeacherLogin, setIsTeacherLogin] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteObj, setDeleteObj] = useState("");
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [schoolInfo, setSchoolInfo] = useState(null);
  let token = cookies.get("token");

  const checkUser = () => {
    if (cookies.get("isTeacherLogin")) {
      setIsTeacherLogin(true);
    } else {
      setIsTeacherLogin(false);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  // get schools dynamic list
  const getSchoolDetails = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${EXPRESS_API_BASE_URL}/school/${parseInt(id)}/grades`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.data.data) {
        console.log("getSchoolDetails Res", res.data.data);
        setSchoolInfo(res.data.data);
        setSchoolDetails(res.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("getSchoolDetails error", error);
    }
  };

  useEffect(() => {
    getSchoolDetails();
  }, [id]);

  // activate/ deactive Grade function
  const handleRemove = async (val) => {
    if (val && (val?.softDelete === 0 || val?.softDelete === null)) {
      try {
        setLoading(true);
        const res = await axios.get(
          `${EXPRESS_API_BASE_URL}/grade/${parseInt(val?.id)}/deactivate`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // console.log("deactivate grade res", res.data.data);
        toast.success("Grade has been Deactivated!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("deactivate grade error", error);
      }
    } else {
      try {
        setLoading(true);
        const res = await axios.get(
          `${EXPRESS_API_BASE_URL}/grade/${parseInt(val?.id)}/activate`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // console.log("activate grade res", res.data.data);
        toast.success("Grade has been Activated!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("activate grade error", error);
      }
    }
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="w-4/5 mobile:w-full ipad:w-full position-class">
          <div className="bg-secondary pt-5 pb-2 px-5 flex justify-between items-center mb-10 stickyNavbar z-40">
            <div className="flex flex-wrap gap-1 items-center">
              <p
                onClick={() => {
                  navigate("/schools");
                }}
                className="cursor-pointer text-primary text-xl font-semibold mobile:text-sm mobile:font-bold"
              >
                {schoolInfo ? schoolInfo[0]?.school?.name + " / " : "School / "}
              </p>
              <p className="text-black text-xl font-semibold mobile:text-sm mobile:font-bold">
                {"Grades"}
              </p>
            </div>

            {isTeacherLogin === true ? (
              <></>
            ) : (
              <div className="flex mobile:justify-end">
                <FilledButton
                  onClickFilled={() =>
                    navigate(`/school-details/${id}/create/grade`)
                  }
                  filledBtnText="Add Grade"
                />
              </div>
            )}
          </div>

          <div className="px-5">
            <div className="flex flex-wrap mt-5 mb-2 gap-5 mobile:w-full mobile:mx-auto mobile:justify-center">
              {schoolDetails?.map((val, index) => {
                return (
                  <div key={index}>
                    {val.name ? (
                      <SchoolCard
                        loading={loading}
                        onClickDiv={() =>
                          val?.softDelete === 1
                            ? ""
                            : navigate(`/grade-details/${parseInt(val?.id)}`, {
                                state: {
                                  schoolID: val?.schoolId,
                                },
                              })
                        }
                        name={val?.name}
                        unFilledBtnText={val?.softDelete === 1 ? "" : "Edit"}
                        onClickUnFilled={() =>
                          navigate(`/edit/grade/${parseInt(val?.id)}`)
                        }
                        deleteBtnOnClick={() => {
                          setIsDeleteOpen(true);
                          setDeleteObj(val);
                        }}
                        deleteBtnText={
                          val?.softDelete === null || val?.softDelete === 0
                            ? "Deactivate"
                            : "Activate"
                        }
                        softDelete={val?.softDelete}
                        noOfChildsText="Number of Divisions"
                        noOfChilds={val?.cohorts?.length}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            <DeleteAlertBox
              isDeleteOpen={isDeleteOpen}
              setIsDeleteOpen={setIsDeleteOpen}
              handleRemove={handleRemove}
              deleteObj={deleteObj}
              deleteModalTitle={
                deleteObj.softDelete === 1
                  ? "Are you sure you want to activate this grade? All logins under this grade will be restored."
                  : "Are you sure you want to deactivate? All logins under this grade will be revoked."
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SchoolDetailsPage;
