import { PictureAsPdf } from "@mui/icons-material";
import { Box, Card, Link, Tab, Tabs, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/system";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import plusIcon from "../assets/icons/plusIcon.png";
import FilledButton from "../customs/FilledButton";

export default function Resources() {
  const cookies = new Cookies();
  const [isTeacherLogin, setIsTeacherLogin] = useState(false);
  const checkUser = () => {
    if (cookies.get("isTeacherLogin")) {
      setIsTeacherLogin(true);
    } else {
      setIsTeacherLogin(false);
    }
  };
  const [value, setValue] = React.useState(0);

  const [documentData, setDocumentData] = useState([]);

  useEffect(() => {
    setDocumentData([
      {
        link: "https://code-squad-scratch.s3.ap-south-1.amazonaws.com/resources/Grade+4+sample.pdf",
        title: "Grade 4 Course Summary",
        description: "",
      },
      {
        link: "https://code-squad-scratch.s3.ap-south-1.amazonaws.com/resources/Grade+9+sample.pdf",
        title: "Grade 9 Course Summary",
        description: "",
      },
    ]);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    checkUser();
  }, []);
  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: green[500],
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#11cb5f",
      },
    },
  });

  return (
    <div className="flex w-4/5 mobile:w-full ipad:w-full position-class pb-10 flex-col">
      <div className="flex flex-col w-full">
        <div className="bg-secondary pt-5 px-5 pb-2 flex justify-between items-center mb-5 stickyNavbar z-40">
          <p className="text-2xl font-semibold">Resources</p>
          {/* <div className="flex items-center gap-5">
            {isTeacherLogin ? (
              <></>
            ) : (
              <FilledButton
                onClickFilled={() => {}}
                filledBtnIcon={plusIcon}
                filledBtnText="Create"
                loading={false}
              />
            )}
          </div> */}
        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: "#006b5c" } }}
        >
          <Tab label="Documents" style={{ color: "#006b5c" }} />
          <Tab label="Links" style={{ color: "#006b5c" }} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Box>
          <div className="flex flex-col space-y-4">
            {documentData.map((d) => (
              <DocumentResourceCard {...d} />
            ))}
          </div>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Nothing to show here..
      </TabPanel>
    </div>
  );
}

function DocumentResourceCard({ link, title, description }) {
  return (
    <Card className="p-4">
      <Box className="flex flex-row space-x-4">
        <PictureAsPdf style={{ fontSize: "48px" }} />
        <Link href={link} style={{ color: "#006b5c" }}>
          <Typography>{title}</Typography>
        </Link>
      </Box>
    </Card>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
