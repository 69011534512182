import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import useWindowSize from "../constants/UseWindowSize";
import UnFilledButton from "./UnFilledButton";

const NewStudentCard = ({
  firstName,
  lastName,
  username,
  email,
  isDelete,
  handleRemove,
  unFilledBtnText,
  onClickUnFilled,
  loading,
}) => {
  const { width, height } = useWindowSize();

  return (
    <div className="relative">
      {isDelete === true ? (
        <button
          onClick={handleRemove}
          className="absolute -right-3 -top-3 border rounded-full p-1 bg-red-500 z-30"
        >
          <RiDeleteBin6Line className="text-white text-lg" />
        </button>
      ) : (
        <></>
      )}

      <div
        style={{ width: width < 500 ? "320px" : "400px" }}
        className="border rounded-md shadow-md px-4 h-40 flex items-center bg-secondary overflow-y-auto overflow-x-hidden"
      >
        <div>
          {firstName ? (
            <div className="my-2 w-full">
              <p className="font-semibold text-lg pb-2 text-gray-800">
                First name:{" "}
                <span className="font-normal text-gray-600">{firstName}</span>
              </p>
            </div>
          ) : (
            <></>
          )}

          {lastName ? (
            <div className="my-2 w-full">
              <p className="font-semibold text-lg pb-2 text-gray-800">
                Last name:{" "}
                <span className="font-normal text-gray-600">{lastName}</span>
              </p>
            </div>
          ) : (
            <></>
          )}

          {username ? (
            <div className="my-2 w-full">
              <p className="font-semibold text-lg pb-2 text-gray-800">
                User name:{" "}
                <span className="font-normal text-gray-600">{username}</span>
              </p>
            </div>
          ) : (
            <></>
          )}

          {email ? (
            <div className="my-2 w-full">
              <p className="font-semibold text-lg pb-2 text-gray-800">
                Email:{" "}
                <span className="font-normal text-gray-600">{email}</span>
              </p>
            </div>
          ) : (
            <></>
          )}

          {unFilledBtnText ? (
            <UnFilledButton
              unFilledBtnText={unFilledBtnText}
              onClickUnFilled={onClickUnFilled}
              loading={loading}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NewStudentCard;
