import React, { useState, useEffect } from "react";
import DashboardCardComponent from "./cards/DashboardCardComponent";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { AssignmentInd, Business, SchoolSharp } from "@mui/icons-material";
import useWindowSize from "../constants/UseWindowSize";
import Cookies from "universal-cookie";
import BarChart from "./BarChart";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { EXPRESS_API_BASE_URL } from "../constant";

export default function HomePageAdmin() {
  const { width, height } = useWindowSize();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [AssignmentsCount, setAssignmentsCount] = useState(0);
  const [SchoolsCount, setSchoolsCount] = useState(0);
  const [TeachersCount, setTeachersCount] = useState(0);
  const [StudentsCount, setStudentsCount] = useState(0);
  const [updatesArr, setUpdatesArr] = useState([]);
  let token = cookies.get("token");

  // check user logged in or not
  const checkUserLogin = () => {
    if (!cookies.get("token")) {
      navigate("/login");
    }
  };

  useEffect(() => {
    checkUserLogin();
  }, []);

  const datas = [
    [10, 30, 40, 20],
    [10, 40, 30, 20, 50, 10],
    [60, 30, 40, 20, 30],
  ];

  var margin = { top: 10, right: 30, bottom: 20, left: 50 };

  const [barWidth, setBarWidth] = useState(0);
  const [barHeight, setBarHeight] = useState(0);

  useEffect(() => {
    if (width < 500) {
      setBarWidth(380 - margin.top - margin.bottom);
      setBarHeight(350 - margin.top - margin.bottom);
    } else {
      setBarWidth(800 - margin.left - margin.right);
      setBarHeight(400 - margin.top - margin.bottom);
    }
  }, [width]);

  // get Dashboard Stats
  const getDashboardStats = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${EXPRESS_API_BASE_URL}/dashboard/admin`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // console.log("getDashboardStats res", res.data.data);
      setAssignmentsCount(res.data.data?.numberOfAssignments);
      setSchoolsCount(res.data.data?.numberOfSchools);
      setTeachersCount(res.data.data?.numberOfTeachers);
      setStudentsCount(res.data.data?.numberOfStudents);
      setUpdatesArr(res.data.data?.updates);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("getDashboardStats error", error);
    }
  };

  useEffect(() => {
    getDashboardStats();

    setInterval(() => {
      getDashboardStats();
    }, 10000);
  }, []);

  return (
    <>
      <div className="flex w-4/5 mobile:w-full ipad:w-full position-class pb-10 mobile:pb-5">
        <div className="flex flex-col w-full">
          <div className="bg-secondary pt-5 px-5 pb-2 flex justify-between items-center mb-5 stickyNavbar z-40 mobile:pt-2">
            <p className="text-2xl font-semibold">Dashboard</p>
          </div>

          <div className="flex flex-col p-4">
            <div className="flex justify-between items-center mb-3">
              <p className="text-2xl font-semibold text-gray-500">Stats</p>
            </div>

            <div className="flex flex-row justify-around space-x-5 mobile:flex-col mobile:space-x-0 mobile:gap-5">
              <DashboardCardComponent
                icon={
                  <AssignmentIcon
                    className="text-2xl text-gray-500"
                    style={{ fontSize: "30px" }}
                  />
                }
                title={"Assignments"}
                metric={AssignmentsCount}
              />

              <DashboardCardComponent
                icon={
                  <Business
                    className="text-2xl text-gray-500"
                    style={{ fontSize: "30px" }}
                  />
                }
                title={"Schools"}
                metric={SchoolsCount}
              />

              <DashboardCardComponent
                icon={
                  <AssignmentInd
                    className="text-2xl text-gray-500"
                    style={{ fontSize: "30px" }}
                  />
                }
                title={"Teachers"}
                metric={TeachersCount}
              />

              <DashboardCardComponent
                icon={
                  <SchoolSharp
                    className="text-2xl text-gray-500"
                    style={{ fontSize: "30px" }}
                  />
                }
                title={"Students"}
                metric={StudentsCount}
              />
            </div>

            <div className="updates-section mobile:my-8">
              <div className="flex justify-between items-center my-3 mobile:mt-5">
                <p className="text-2xl font-semibold  text-gray-500">Updates</p>
              </div>

              <div className="w-full bg-secondary overflow-y-auto border rounded-lg shadow-md p-4">
                {updatesArr?.length === 0 ? (
                  <p className="text-lg p-2">No updates found!</p>
                ) : (
                  <ul>
                    {updatesArr?.map((val, index) => {
                      return (
                        <li key={index}>
                          <p
                            className="font-bold cursor-pointer"
                            onClick={() =>
                              val?.entityType === "PROJECT"
                                ? navigate(
                                    `/notification/view/project/${val?.entityId}`
                                  )
                                : val?.entityType === "ASSIGNMENT"
                                ? navigate(
                                    `/notification/view/assignment/${val?.entityId}`
                                  )
                                : ""
                            }
                          >
                            {val?.text}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>

            <div className="flex justify-between items-center my-3">
              <p className="text-2xl font-semibold  text-gray-500">
                Submissions Trend
              </p>
            </div>

            <div className="chart-section mx-auto mt-3">
              <BarChart height={barHeight} width={barWidth} data={datas[0]} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
