import React, { useEffect, useState } from "react";
import DesktopSideBar from "./DesktopSideBar";
import MobileSideBar from "./MobileSideBar";
import useWindowSize from "./UseWindowSize";

const SideBar = ({
  isSidebarOpen,
  resourcesHover,
  dashboardHover,
  teacherSideHomeHover,
  classesHover,
  assignmentsHover,
  schoolsHover,
  divisionHover,
  gradesHover,
  teachersHover,
  myAssignmentHover,
  projectsHover,
  mySubmissionsHover,
  myStudiesHover,
  teacherAssignmentSubmissionsHover,
  teacherProjectSubmissionsHover,
}) => {
  const { width } = useWindowSize();
  const [isMobile, setisMobile] = useState(false);

  useEffect(() => {
    if (width <= 1000) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  }, [width]);

  return (
    <div>
      {isMobile === true ? (
        <MobileSideBar
          isMobile={isMobile}
          resourcesHover={resourcesHover}
          isSidebarOpen={isSidebarOpen}
          dashboardHover={dashboardHover}
          classesHover={classesHover}
          teacherSideHomeHover={teacherSideHomeHover}
          assignmentsHover={assignmentsHover}
          schoolsHover={schoolsHover}
          divisionHover={divisionHover}
          gradesHover={gradesHover}
          teachersHover={teachersHover}
          myAssignmentHover={myAssignmentHover}
          projectsHover={projectsHover}
          mySubmissionsHover={mySubmissionsHover}
          myStudiesHover={myStudiesHover}
          teacherAssignmentSubmissionsHover={teacherAssignmentSubmissionsHover}
          teacherProjectSubmissionsHover={teacherProjectSubmissionsHover}
        />
      ) : (
        <DesktopSideBar
          resourcesHover={resourcesHover}
          isSidebarOpen={isSidebarOpen}
          dashboardHover={dashboardHover}
          teacherSideHomeHover={teacherSideHomeHover}
          classesHover={classesHover}
          assignmentsHover={assignmentsHover}
          schoolsHover={schoolsHover}
          divisionHover={divisionHover}
          gradesHover={gradesHover}
          teachersHover={teachersHover}
          myAssignmentHover={myAssignmentHover}
          projectsHover={projectsHover}
          mySubmissionsHover={mySubmissionsHover}
          myStudiesHover={myStudiesHover}
          teacherAssignmentSubmissionsHover={teacherAssignmentSubmissionsHover}
          teacherProjectSubmissionsHover={teacherProjectSubmissionsHover}
        />
      )}
    </div>
  );
};

export default SideBar;
