import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { EXPRESS_API_BASE_URL } from "../constant";
import useWindowSize from "../constants/UseWindowSize";
import FilledButton from "../customs/FilledButton";
import Loader from "../customs/Loader";
import TeacherCard from "../customs/TeacherCard";

const Teachers = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { width, height } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [teachersCalled, setTeachersCalled] = useState(false);
  const [studentsCalled, setStudentsCalled] = useState(false);
  const [isDataAvailable, setisDataAvailable] = useState(false);
  const [teachersRes, setTeachersRes] = useState([]);
  const [studentsRes, setStudentsRes] = useState([]);
  let token = cookies.get("token");

  const redirectToNewTab = (tabVal) => {
    navigate(`/users?tab=${tabVal}`);
  };

  useEffect(() => {
    try {
      let tabVal = searchParams.get("tab");
      setTeachersCalled(tabVal === "teachers" || !tabVal ? true : false);
      setStudentsCalled(tabVal === "students" ? true : false);
    } catch (err) {
      console.error("Tab error", err);
    }
  }, [searchParams.get("tab")]);

  // get teachers dynamic list
  const getTeachers = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${EXPRESS_API_BASE_URL}/users?type=TEACHER`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTeachersRes(res.data.data);
      setLoading(false);
      // console.log("getTeachers res", res.data.data);
    } catch (error) {
      setLoading(false);
      console.log("getTeachers error", error);
    }
  };

  // get students dynamic list
  const getstudents = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${EXPRESS_API_BASE_URL}/users?type=STUDENT`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setStudentsRes(res.data.data);
      setLoading(false);
      // console.log("getstudents res", res.data.data);
    } catch (error) {
      setLoading(false);
      console.log("getstudents error", error);
    }
  };

  useEffect(() => {
    getTeachers();
    getstudents();
  }, []);

  useEffect(() => {
    if (teachersRes?.length === 0) {
      setisDataAvailable(true);
    } else {
      setisDataAvailable(false);
    }
  }, [teachersRes]);

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="w-4/5 mobile:w-full ipad:w-full position-class pb-10">
          <div className="stickyNavbar z-40 w-full">
            <div className="bg-secondary pt-5 pb-2 px-5 flex justify-between items-start">
              <p className="text-3xl font-semibold mobile:text-xl mobile:font-bold">
                Users
              </p>

              <FilledButton
                onClickFilled={() =>
                  navigate("/create/user", {
                    state: {
                      addTeacher: teachersCalled ? true : false,
                    },
                  })
                }
                filledBtnText={teachersCalled ? "Add Teacher" : "Add Student"}
              />
            </div>

            <div className="flex px-5 border mb-8 bg-secondary">
              <p
                onClick={() => {
                  redirectToNewTab("teachers");
                }}
                className={`font-semibold px-3 py-3 cursor-pointer bg-${
                  teachersCalled === true ? "primary" : "secondary"
                } text-${teachersCalled === true ? "white" : "black"}`}
              >
                Teachers
              </p>

              <p
                onClick={() => {
                  redirectToNewTab("students");
                }}
                className={`font-semibold px-3 py-3 cursor-pointer bg-${
                  studentsCalled === true ? "primary" : "secondary"
                } text-${studentsCalled === true ? "white" : "black"}`}
              >
                Students
              </p>
            </div>
          </div>

          {teachersCalled ? (
            <div>
              {/* <p className="p-5 text-xl font-semibold mobile:text-xl mobile:font-bold">
              Teachers
            </p> */}

              {isDataAvailable === true ? (
                <p
                  style={{
                    position: "absolute",
                    left: width < 500 ? "35%" : "40%",
                  }}
                  className="font-semibold text-xl pt-60"
                >
                  No teachers found!
                </p>
              ) : (
                <div className="px-5 mobile:px-0">
                  <div className="flex flex-wrap gap-5 mobile:flex-col">
                    {teachersRes?.map((val, index) => {
                      return (
                        <div key={index}>
                          <TeacherCard
                            firstName={val?.firstName}
                            lastName={val?.lastName}
                            email={val?.email}
                            username={val?.username}
                            password={val?.password}
                            unFilledBtnText="Edit"
                            onClickUnFilled={() =>
                              navigate(`/edit/user/${parseInt(val?.id)}`, {
                                state: {
                                  addTeacher: teachersCalled ? true : false,
                                },
                              })
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}

          {studentsCalled ? (
            <div>
              {/* <p className="p-5 text-xl font-semibold mobile:text-xl mobile:font-bold">
              students
            </p> */}

              {isDataAvailable === true ? (
                <p
                  style={{
                    position: "absolute",
                    left: width < 500 ? "35%" : "40%",
                  }}
                  className="font-semibold text-xl pt-60"
                >
                  No students found!
                </p>
              ) : (
                <div className="px-5 mobile:px-0">
                  <div className="flex flex-wrap gap-5 mobile:flex-col">
                    {studentsRes?.map((val, index) => {
                      return (
                        <div key={index}>
                          <TeacherCard
                            firstName={val?.firstName}
                            lastName={val?.lastName}
                            email={val?.email}
                            username={val?.username}
                            password={val?.password}
                            unFilledBtnText="Edit"
                            onClickUnFilled={() =>
                              navigate(`/edit/user/${parseInt(val?.id)}`, {
                                state: {
                                  addTeacher: teachersCalled ? true : false,
                                },
                              })
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default Teachers;
