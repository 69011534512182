import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

export default function DeleteAlertBox({
  isDeleteOpen,
  setIsDeleteOpen,
  deleteModalTitle,
  handleRemove,
  deleteObj,
}) {
  return (
    <>
      <Dialog
        open={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{deleteModalTitle}</DialogTitle>

        <DialogActions>
          <Button color="success" onClick={() => setIsDeleteOpen(false)}>
            Cancel
          </Button>

          <Button
            color="success"
            onClick={() => {
              setIsDeleteOpen(false);
              handleRemove(deleteObj);
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
