import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { EXPRESS_API_BASE_URL } from "../../constant";
import useWindowSize from "../../constants/UseWindowSize";
import AssignmentCard from "../../customs/AssignmentCard";
import Loader from "../../customs/Loader";

const AssignmentSubmissionCourses = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [isDataAvailable, setisDataAvailable] = useState(false);
  const [coursesArr, setCoursesArr] = useState([]);

  // get course dynamic list
  const getCourse = async () => {
    let token = cookies.get("token");
    try {
      setLoading(true);
      const res = await axios.get(`${EXPRESS_API_BASE_URL}/course`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCoursesArr(res.data.data);
      setLoading(false);
      //   console.log("getCourseRes", res.data.data);
    } catch (error) {
      setLoading(false);
      console.log("getCourse error", error.response.data.message);
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  useEffect(() => {
    if (coursesArr?.length === 0) {
      setisDataAvailable(true);
    } else {
      setisDataAvailable(false);
    }
  }, [coursesArr]);

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="flex w-4/5 mobile:w-full ipad:w-full position-class pb-10">
          {isDataAvailable === true ? (
            <p
              style={{
                position: "absolute",
                left: width < 500 ? "35%" : "40%",
              }}
              className="font-semibold text-xl pt-60"
            >
              No data found!
            </p>
          ) : (
            <div className="flex flex-col w-full">
              <div className="bg-secondary pt-5 px-5 pb-2 mb-5 flex justify-between items-center stickyNavbar z-40">
                <p className="text-2xl font-semibold">Courses</p>
              </div>

              <div className="flex mobile:justify-center">
                <div className="flex flex-wrap my-2 gap-5 ipad:gap-10 ipad:justify-center mobile:justify-center mobile:w-full">
                  {coursesArr?.map((val, index) => {
                    return (
                      <div key={index}>
                        {val?.name && val?.assignments.length > 0 ? (
                          <AssignmentCard
                            imageClassname="course-card-image-width"
                            courseImg={val?.imageUrl}
                            Title={val?.name}
                            unFilledBtnText="View Assignments"
                            onClickUnFilled={() =>
                              navigate(
                                `/teacher/assignment/submissions/${parseInt(
                                  val?.id
                                )}`,
                                {
                                  state: { coursesArr },
                                }
                              )
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AssignmentSubmissionCourses;
