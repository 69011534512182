import React from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";

export default function DashboardCardComponent({ icon, title, metric }) {
  return (
    <div className=" flex flex-col items-center p-4 border border-primary rounded-lg shadow-md flex-1 space-y-2 hover:shadow-xl">
      <div className="text-2xl mb-2 items-center text-center">{icon}</div>
      <div className="text-3xl text-primary font-bold fade-in">{metric}</div>
      <div className="text-md text-gray-500 font-bold">{title}</div>
    </div>
  );
}
