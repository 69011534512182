import { TextField } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { EXPRESS_API_BASE_URL } from "../../constant";
import { usePrompt } from "../../constants/UsePrompt";
import FilledButton from "../../customs/FilledButton";
import Loader from "../../customs/Loader";
import { v4 as uuidv4 } from "uuid";
import DeleteAlertBox from "../../customs/DeleteAlertBox";

const CreateDivision = () => {
  const { gradeId, id } = useParams();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteObj, setDeleteObj] = useState("");
  const [addStudentCounter, setAddStudentCounter] = useState(0);
  const [divisionName, setDivisionName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailId, setEmailId] = useState("");
  // const [password, setPassword] = useState("");
  const [studentArr, setStudentArr] = useState([]);
  const [navBlocker, setNavBlocker] = useState(false);
  let token = cookies.get("token");

  // handle unsaved prompt
  useEffect(() => {
    if (
      firstName.length !== 0 ||
      lastName.length !== 0 ||
      emailId.length !== 0 ||
      divisionName.length !== 0
    ) {
      setNavBlocker(true);
    } else {
      setNavBlocker(false);
    }
  }, [firstName, lastName, emailId, divisionName]);
  usePrompt("Confirm it and save the changes!", navBlocker);

  // generate random password using firstname
  function generatePassword() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    let newPassword = firstName + "_" + retVal;
    return newPassword;
  }

  // adding Student to array
  const handleAddStudent = () => {
    let finalFirstName = firstName.trim();
    let finalLastName = lastName.trim();
    let finalDivisionName = divisionName.trim();
    let randomThreeDigitNo = Math.floor(Math.random() * (999 - 100 + 1) + 100);

    let userName =
      finalFirstName.toLowerCase() +
      "_" +
      finalLastName.toLowerCase() +
      finalDivisionName.toLowerCase() +
      randomThreeDigitNo;
    let password = generatePassword().toLowerCase();

    if (firstName.length === 0) {
      toast.warn("First name required!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else if (password.length === 0) {
      toast.warn("First name required!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      setStudentArr([
        ...studentArr,
        {
          id: uuidv4(),
          firstName: firstName,
          lastName: lastName,
          email: emailId,
          username: userName?.replace(/ /g, ""),
          password: password?.replace(/ /g, ""),
        },
      ]);

      setFirstName("");
      setLastName("");
      setEmailId("");
      toast.success("Student added successfully!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  // removing student from array
  const handleRemoveStudent = (index) => {
    let newCounterValue = addStudentCounter;
    setStudentArr(studentArr?.filter((val) => val.id !== deleteObj));

    setAddStudentCounter(0);
    setTimeout(() => {
      setAddStudentCounter(newCounterValue);
    }, 200);

    toast.success("Student removed successfully!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  };

  // get Cohorts dynamic list
  const getCohorts = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${EXPRESS_API_BASE_URL}/cohort/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (res.data.data) {
        setDivisionName(res.data.data.name);
        setStudentArr(res.data.data.students);
        // console.log("getCohorts res", res.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("getCohorts error", error);
    }
  };

  useEffect(() => {
    getCohorts();
  }, []);

  // add division with students api integration
  const onSaveDivision = async () => {
    const checkTitleLength = divisionName.length > 1;

    if (!checkTitleLength) {
      toast.warn("Division Name should have minimum 1 character!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else if (studentArr.length === 0) {
      toast.warn("Add atleast one Student!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      try {
        setNavBlocker(false);
        setLoading(true);

        if (id) {
          const res = await axios.put(
            `${EXPRESS_API_BASE_URL}/cohort/${id}`,
            {
              name: divisionName,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );

          // console.log("edit division res", res.data.data);
          navigate("/schools");
          toast.success("Division updated successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          // removing id while saving division
          for (let index = 0; index < studentArr.length; index++) {
            delete studentArr[index]["id"];
          }

          const res = await axios.post(
            `${EXPRESS_API_BASE_URL}/cohort/`,
            {
              name: divisionName,
              gradeId: parseInt(gradeId),
              students: studentArr,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );

          // console.log("create division res", res.data.data);
          navigate("/schools");
          toast.success("Division added successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("create division error", error);
      }
    }
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="w-4/5 mobile:w-full ipad:w-full position-class pb-10">
          <div className="bg-secondary flex justify-between items-center px-5 py-3 stickyNavbar z-40">
            <p className="text-2xl font-semibold">
              {id ? "Edit Division" : "Add Division"}
            </p>

            <FilledButton
              onClickFilled={onSaveDivision}
              filledBtnText="Save"
              loading={loading}
            />
          </div>

          <div className="w-3/4 ipad:w-full mobile:w-full my-5 ipad:mx-auto mobile:mx-auto">
            <div className="px-5">
              <div className="border border-primary rounded-md p-2 bg-secondary">
                <div className="my-2 w-full">
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-multiline-flexible"
                    label="Division name *"
                    value={divisionName}
                    onChange={(e) => setDivisionName(e.target.value)}
                  />
                </div>
              </div>

              {id ? (
                <></>
              ) : (
                <div className="border border-primary rounded-md p-2 mt-6 relative">
                  <div className="mb-2 w-full">
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-multiline-flexible"
                      label="First name *"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>

                  <div className="mb-2 w-full">
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-multiline-flexible"
                      label="Last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>

                  <div className="mb-2 w-full">
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-multiline-flexible"
                      label="Email Id"
                      type="email"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </div>

                  {/* <div className="mb-2 w-full">
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-multiline-flexible"
                      label="Password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div> */}

                  <div className="mt-5 mb-2 w-full flex justify-center items-center">
                    <button
                      onClick={handleAddStudent}
                      className="border border-primary bg-primary text-white text-center rounded-xl py-2 w-full"
                    >
                      Add Student
                    </button>
                  </div>
                </div>
              )}

              <p className="text-2xl font-semibold mt-2">
                {id && studentArr?.length > 0 ? "Students" : ""}
              </p>

              {studentArr?.map((c, index) => {
                return (
                  <div
                    className="border border-primary rounded-md p-2 mt-5 relative"
                    key={index}
                  >
                    {id ? (
                      <></>
                    ) : (
                      <button
                        onClick={() => {
                          // handleRemoveStudent(studentArr[index]?.id)
                          setIsDeleteOpen(true);
                          setDeleteObj(studentArr[index]?.id);
                        }}
                        className="absolute -right-3 -top-3 border rounded-full p-1 bg-red-500 z-10"
                      >
                        <RiDeleteBin6Line className="text-white text-lg" />
                      </button>
                    )}

                    {c?.firstName ? (
                      <div className="my-2 w-full">
                        <p className="font-semibold text-lg pb-2 text-gray-600">
                          First name: {c?.firstName}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}

                    {c?.lastName ? (
                      <div className="my-2 w-full">
                        <p className="font-semibold text-lg pb-2 text-gray-600">
                          Last name: {c?.lastName}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}

                    {c?.email ? (
                      <div className="my-2 w-full">
                        <p className="font-semibold text-lg pb-2 text-gray-600">
                          Email ID: {c?.email}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}

                    {c?.username ? (
                      <div className="my-2 w-full">
                        <p className="font-semibold text-lg pb-2 text-gray-600">
                          Username: {c?.username}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            <DeleteAlertBox
              isDeleteOpen={isDeleteOpen}
              setIsDeleteOpen={setIsDeleteOpen}
              handleRemove={handleRemoveStudent}
              deleteObj={deleteObj}
              deleteModalTitle={"Are you sure you want to remove this Student?"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CreateDivision;
