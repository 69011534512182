import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { EXPRESS_API_BASE_URL } from "../constant";
import FilledButton from "../customs/FilledButton";
import Loader from "../customs/Loader";
import SchoolCard from "../customs/SchoolCard";
import DeleteAlertBox from "../customs/DeleteAlertBox";
import useWindowSize from "../constants/UseWindowSize";

const Schools = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [isDataAvailable, setisDataAvailable] = useState(false);
  const [isTeacherLogin, setIsTeacherLogin] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteObj, setDeleteObj] = useState("");
  const [schoolsRes, setSchoolsRes] = useState([]);
  let token = cookies.get("token");

  const checkUser = () => {
    if (cookies.get("isTeacherLogin")) {
      setIsTeacherLogin(true);
    } else {
      setIsTeacherLogin(false);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  // get schools dynamic list
  const getSchools = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${EXPRESS_API_BASE_URL}/school`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSchoolsRes(res.data.data);
      setLoading(false);
      // console.log("getSchoolsRes", res.data.data);
    } catch (error) {
      setLoading(false);
      console.log("getSchools error", error);
    }
  };

  useEffect(() => {
    getSchools();
  }, []);

  // activate/ deactive Grade function
  const handleRemove = async (val) => {
    if (val && (val?.softDelete === 0 || val?.softDelete === null)) {
      try {
        setLoading(true);
        const res = await axios.get(
          `${EXPRESS_API_BASE_URL}/school/${parseInt(val?.id)}/deactivate`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // console.log("deactivate school res", res.data.data);
        toast.success("School has been Deactivated!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("deactivate school error", error);
      }
    } else {
      try {
        setLoading(true);
        const res = await axios.get(
          `${EXPRESS_API_BASE_URL}/school/${parseInt(val?.id)}/activate`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // console.log("activate school res", res.data.data);
        toast.success("School has been Activated!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("activate school error", error);
      }
    }
  };

  useEffect(() => {
    if (schoolsRes?.length === 0) {
      setisDataAvailable(true);
    } else {
      setisDataAvailable(false);
    }
  }, [schoolsRes]);

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="w-4/5 mobile:w-full ipad:w-full position-class pb-10">
          <div className="bg-secondary pt-5 pb-2 px-5 flex justify-between items-start mb-10 stickyNavbar z-40">
            <p className="text-3xl font-semibold mobile:text-xl mobile:font-bold">
              Schools
            </p>

            {isTeacherLogin === true ? (
              <></>
            ) : (
              <FilledButton
                onClickFilled={() => navigate("/create/school")}
                filledBtnText="Add School"
              />
            )}
          </div>

          {isDataAvailable === true ? (
            <p
              style={{
                position: "absolute",
                left: width < 500 ? "35%" : "40%",
              }}
              className="font-semibold text-xl pt-60"
            >
              No schools found!
            </p>
          ) : (
            <div className="px-5">
              <div className="flex flex-wrap gap-5 mobile:justify-center mobile:mx-auto mobile:w-full">
                {schoolsRes?.map((val, index) => {
                  return (
                    <div key={index}>
                      <SchoolCard
                        onClickDiv={() =>
                          val?.softDelete === 1
                            ? ""
                            : navigate(`/school-details/${parseInt(val?.id)}`)
                        }
                        name={val?.name}
                        unFilledBtnText={val?.softDelete === 1 ? "" : "Edit"}
                        onClickUnFilled={() =>
                          navigate(`/edit/school/${parseInt(val?.id)}`)
                        }
                        // deleteBtnOnClick={() => handleRemove(val)}
                        deleteBtnOnClick={() => {
                          setIsDeleteOpen(true);
                          setDeleteObj(val);
                        }}
                        deleteBtnText={
                          val?.softDelete === null || val?.softDelete === 0
                            ? "Deactivate"
                            : "Activate"
                        }
                        softDelete={val.softDelete}
                        loading={loading}
                        noOfChildsText="Number of Grades"
                        noOfChilds={val?.grades?.length}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div>
            <DeleteAlertBox
              isDeleteOpen={isDeleteOpen}
              setIsDeleteOpen={setIsDeleteOpen}
              handleRemove={handleRemove}
              deleteObj={deleteObj}
              deleteModalTitle={
                deleteObj.softDelete === 1
                  ? "Are you sure you want to activate this school? All logins under this school will be restored."
                  : "Are you sure you want to deactivate? All logins under this school will be revoked."
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Schools;
