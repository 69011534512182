import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { EXPRESS_API_BASE_URL } from "../../constant";
import FilledButton from "../../customs/FilledButton";
import ListAssignmentCard from "../../customs/ListAssignmentCard";
import Loader from "../../customs/Loader";
import MainLogo from "../../assets/MainLogo.png";
import useWindowSize from "../../constants/UseWindowSize";

const Assignments = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [isDataAvailable, setisDataAvailable] = useState(false);
  const [assignmentsRes, setAssignmentsRes] = useState([]);
  const [isTeacherLogin, setIsTeacherLogin] = useState(false);

  const checkUser = () => {
    if (cookies.get("isTeacherLogin")) {
      setIsTeacherLogin(true);
    } else {
      setIsTeacherLogin(false);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  // get assginments dynamic list
  const getAssignments = async () => {
    let token = cookies.get("token");
    try {
      setLoading(true);
      const res = await axios.get(`${EXPRESS_API_BASE_URL}/assignment`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAssignmentsRes(res.data.data);
      setLoading(false);
      // console.log("getAssignments res", res.data.data);
    } catch (error) {
      setLoading(false);
      console.log("getAssignments error", error);
    }
  };

  useEffect(() => {
    getAssignments();
  }, []);

  useEffect(() => {
    if (assignmentsRes?.length === 0) {
      setisDataAvailable(true);
    } else {
      setisDataAvailable(false);
    }
  }, [assignmentsRes]);

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="w-4/5 mobile:w-full ipad:w-full position-class pb-10">
          <div className="bg-secondary pt-5 pb-2 px-5 flex justify-between items-start mb-8 stickyNavbar z-40">
            <p className="text-3xl font-semibold mobile:text-xl mobile:font-bold">
              Assignments
            </p>

            <FilledButton
              onClickFilled={() => navigate("/assignment/builder")}
              filledBtnText="Add Assignment"
            />
          </div>

          {isDataAvailable === true ? (
            <p
              style={{
                position: "absolute",
                left: width < 500 ? "35%" : "40%",
              }}
              className="font-semibold text-xl pt-60"
            >
              No assignments found!
            </p>
          ) : (
            <div className="px-5">
              <div className="w-11/12 mobile:w-full ipad:w-full pb-10 ipad:mx-auto mobile:mx-auto">
                {assignmentsRes?.map((val, index) => {
                  return (
                    <div key={index}>
                      <ListAssignmentCard
                        keyIndex={index}
                        courseImg={
                          val?.featureImage ? val?.featureImage : MainLogo
                        }
                        CreatedBy={val?.createdBy?.username}
                        Title={`${val?.title}`}
                        Desc={`${val?.description}`}
                        // unFilledBtnText="Details"
                        onClickDiv={() => {
                          val?.submittedStatus === "DRAFT"
                            ? navigate(
                                `/assignment/builder/${parseInt(val?.id)}`
                              )
                            : navigate(
                                `/student/my-assignments-details/${parseInt(
                                  val?.id
                                )}`
                              );
                        }}
                        loading={loading}
                        isTeacherLogin={isTeacherLogin}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Assignments;
