import React, { Component } from "react";
import { Subject, share } from "rxjs";

export default class StorageService extends Component {
  constructor() {
    super();
    this.start();
    this.onSubject = new Subject();
    this.changes = this.onSubject.asObservable().pipe(share());
  }

  componentWillUnmount() {
    this.stop();
  }

  getStorage() {
    let s = [];
    for (let i = 0; i < localStorage.length; i++) {
      s.push({
        key: localStorage.key(i),
        value: JSON.parse(localStorage.getItem(localStorage.key(i))),
      });
    }
    return s;
  }

  store(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
    this.onSubject.next({ key: key, value: data });
  }

  clear(key) {
    localStorage.removeItem(key);
    this.onSubject.next({ key: key, value: null });
  }

  start() {
    window.addEventListener("storage", this.storageEventListener.bind(this));
  }

  storageEventListener(event) {
    if (event.storageArea === localStorage) {
      let v;
      try {
        v = JSON.parse(event.newValue);
      } catch (e) {
        v = event.newValue;
      }
      this.onSubject.next({ key: event.key, value: v });
    }
  }

  stop() {
    window.removeEventListener("storage", this.storageEventListener.bind(this));
    this.onSubject.complete();
  }

  render() {
    return <div>StorageService</div>;
  }
}
