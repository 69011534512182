import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, matchPath } from "react-router-dom";
import Home from "./components/Home";
import CourseDetailsPage from "./components/CourseDetailsPage";
import Assignments from "./components/assignment/Assignments";
import Schools from "./components/Schools";
import Teachers from "./components/Teachers";
import ErrorPage from "./constants/Errorpage";
import Header from "./constants/Header";
import SideBar from "./constants/Sidebar";
import Login from "./auth/Login";
import MyAssignment from "./components/assignment/MyAssignment";
import MyAssignmentDetails from "./components/assignment/MyAssignmentDetails";
import Projects from "./components/Projects";
import CreateProject from "./components/CreateProject";
import MySubmissions from "./components/MySubmissions";
import CreateSchoolPage from "./components/CreateSchoolPage";
import CreateTeacher from "./components/CreateTeacher";
import SchoolDetailsPage from "./components/SchoolDetailsPage";
import TeacherProjectSubmissions from "./components/ProjectEvaluation";
import TeacherAssignmentSubmissions from "./components/assignment/AssignmentEvalution";
import AssignmentSubmissionCourses from "./components/assignment/AssignmentEvalutionCourses";
import AssignmentSubmissionDetails from "./components/assignment/AssignmentEvalutionDetails";
import CreateDivision from "./components/division/CreateDivision";
import DivisionDetails from "./components/division/DivisionDetails";
import CreateGrade from "./components/CreateGrade";
import NewAssignmentBuilder from "./components/assignment/NewAssignmentBuilder";
import GradeDetailsPage from "./components/GradeDetailsPage";
import ViewProjectDetails from "./components/ViewProjectDetails";
import Resources from "./components/Resources";
import ArchiveProjects from "./components/ArchiveProjects";
import { getFBToken, getToken, messaging } from "./firebase";
import { onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import ScratchProjectEditor from "./components/ScratchProjectEditor";
import HomePageTeacher from "./components/HomePageTeacher";
import ViewProjectNotification from "./components/ViewProjectNotification";
import ViewAssignmentNotification from "./components/ViewAssignmentNotification";
import HomePageStudent from "./components/HomePageStudent";
import HomePageAdmin from "./components/HomePageAdmin";

const App = () => {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [classesHover, setClassesHover] = useState(false);
  const [teacherSideHomeHover, setTeacherSideHomeHover] = useState(false);
  const [assignmentsHover, setAssignmentsHover] = useState(false);
  const [schoolsHover, setSchoolsHover] = useState(false);
  const [divisionHover, setDivisionHover] = useState(false);
  const [gradesHover, setGradesHover] = useState(false);
  const [teachersHover, setTeachersHover] = useState(false);
  const [myAssignmentHover, setMyAssignmentHover] = useState(false);
  const [dashboardHover, setDashboardHover] = useState(false);
  const [projectsHover, setStudentProjectsHover] = useState(false);
  const [mySubmissionsHover, setStudentMySubmissionsHover] = useState(false);
  const [myStudiesHover, setStudentMyStudiesHover] = useState(false);
  const [resourcesHover, setResourcesHover] = useState(false);
  const [
    teacherAssignmentSubmissionsHover,
    setTeacherAssignmentSubmissionsHover,
  ] = useState(false);
  const [teacherProjectSubmissionsHover, setTeacherProjectSubmissionsHover] =
    useState(false);

  const [tokenFound, setTokenFound] = useState(false);
  useEffect(() => {
    getFBToken(setTokenFound);
    onMessage(messaging, (payload) => {
      toast.success(`${payload.notification?.title}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    });
  }, []);

  // active links
  useEffect(() => {
    if (location.pathname === "/") {
      setResourcesHover(false);
      setDashboardHover(true);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setStudentMyStudiesHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/home") {
      setResourcesHover(false);
      setDashboardHover(true);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setStudentMyStudiesHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(true);
      setTeacherProjectSubmissionsHover(false);

      setIsSidebarOpen(true);
    } else if (matchPath({ path: "course" }, location.pathname)) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(true);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setStudentMyStudiesHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);

      setIsSidebarOpen(true);
    } else if (matchPath({ path: "classes-details/:id" }, location.pathname)) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(true);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/assignments") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(true);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (
      matchPath({ path: "assignments/details/:id" }, location.pathname)
    ) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(true);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/schools") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(true);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (matchPath({ path: "grade-details/:id" }, location.pathname)) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(true);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (matchPath({ path: "school-details/:id" }, location.pathname)) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(true);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (matchPath({ path: "edit/school/:id" }, location.pathname)) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(true);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (matchPath({ path: "edit/division/:id" }, location.pathname)) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(true);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/create/school") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(true);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/grades") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(true);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (matchPath({ path: "edit/division/:id" }, location.pathname)) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(true);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (
      matchPath(
        { path: "school-details/:schoolId/create/grade" },
        location.pathname
      )
    ) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(true);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/divisions") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setDivisionHover(true);
      setSchoolsHover(true);
      setGradesHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (matchPath({ path: "division-details/:id" }, location.pathname)) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setGradesHover(true);
      setDivisionHover(false);
      setSchoolsHover(true);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (
      matchPath(
        { path: "grade-details/:gradeId/create/division" },
        location.pathname
      )
    ) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setDivisionHover(false);
      setSchoolsHover(true);
      setGradesHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (matchPath({ path: "school-details/:id" }, location.pathname)) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(true);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/users") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(true);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/create/user") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(true);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (matchPath({ path: "edit/user/:id" }, location.pathname)) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(true);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/student/my-assignments") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(true);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/archived/projects") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(true);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/student/projects") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(true);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/student/my-submissions") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(true);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/student/my-studies") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(true);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/assignment/builder") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(true);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (
      matchPath({ path: "assignment/builder/:id" }, location.pathname)
    ) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(true);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/teacher/assignment/submissions") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(true);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (
      matchPath(
        { path: "teacher/assignment/submissions/:id" },
        location.pathname
      )
    ) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(true);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (
      matchPath(
        { path: "teacher/assignment/submissions/assignment/:id" },
        location.pathname
      )
    ) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(true);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(false);
    } else if (
      matchPath(
        {
          path: "teacher/assignment/submissions/course/:courseId/assignment/:assignmentId/student/:id",
        },
        location.pathname
      )
    ) {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(true);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(false);
    } else if (location.pathname === "/teacher/project/submissions") {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(true);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else if (location.pathname === "/resources") {
      setResourcesHover(true);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);

      setIsSidebarOpen(true);
    } else {
      setResourcesHover(false);
      setDashboardHover(false);
      setClassesHover(false);
      setAssignmentsHover(false);
      setSchoolsHover(false);
      setGradesHover(false);
      setDivisionHover(false);
      setTeachersHover(false);
      setMyAssignmentHover(false);
      setStudentProjectsHover(false);
      setStudentMySubmissionsHover(false);
      setTeacherAssignmentSubmissionsHover(false);
      setTeacherSideHomeHover(false);
      setTeacherProjectSubmissionsHover(false);
      setStudentMyStudiesHover(false);
    }

    if (location.pathname === "/login") {
      setIsSidebarOpen(false);
    }

    if (window.location.pathname.includes("/student/my-assignments-details/")) {
      setIsSidebarOpen(false);
    }

    if (window.location.pathname.includes("/student/create/project/")) {
      setIsSidebarOpen(false);
    }

    if (window.location.pathname.includes("/student/scratch/project/")) {
      setIsSidebarOpen(false);
    }

    if (window.location.pathname.includes("/view/project/")) {
      setIsSidebarOpen(false);
    }

    if (window.location.pathname.includes("/notification/view/assignment/")) {
      setIsSidebarOpen(false);
    }
  }, [location.pathname]);

  return (
    <div style={{ minHeight: "100vh" }}>
      <Header
        resourcesHover={resourcesHover}
        classesHover={classesHover}
        teacherSideHomeHover={teacherSideHomeHover}
        assignmentsHover={assignmentsHover}
        schoolsHover={schoolsHover}
        divisionHover={divisionHover}
        gradesHover={gradesHover}
        teachersHover={teachersHover}
        myAssignmentHover={myAssignmentHover}
        projectsHover={projectsHover}
        mySubmissionsHover={mySubmissionsHover}
        myStudiesHover={myStudiesHover}
        teacherAssignmentSubmissionsHover={teacherAssignmentSubmissionsHover}
        teacherProjectSubmissionsHover={teacherProjectSubmissionsHover}
      />

      <div className="flex overflow-hidden">
        {isSidebarOpen === true ? (
          <SideBar
            isSidebarOpen={isSidebarOpen}
            classesHover={classesHover}
            dashboardHover={dashboardHover}
            teacherSideHomeHover={teacherSideHomeHover}
            resourcesHover={resourcesHover}
            assignmentsHover={assignmentsHover}
            schoolsHover={schoolsHover}
            divisionHover={divisionHover}
            gradesHover={gradesHover}
            teachersHover={teachersHover}
            myAssignmentHover={myAssignmentHover}
            projectsHover={projectsHover}
            mySubmissionsHover={mySubmissionsHover}
            myStudiesHover={myStudiesHover}
            teacherAssignmentSubmissionsHover={
              teacherAssignmentSubmissionsHover
            }
            teacherProjectSubmissionsHover={teacherProjectSubmissionsHover}
          />
        ) : (
          ""
        )}

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/course" element={<Home />} />
          <Route path="/classes-details/:id" element={<CourseDetailsPage />} />
          <Route path="/" element={<HomePageAdmin />} />
          <Route path="/home" element={<HomePageTeacher />} />
          <Route path="/student/my-studies" element={<HomePageStudent />} />

          <Route path="/assignments" element={<Assignments />} />
          <Route path="/student/my-assignments" element={<MyAssignment />} />
          <Route
            path="/student/my-assignments-details/:id"
            element={<MyAssignmentDetails />}
          />

          <Route
            path="/assignment/builder"
            element={<NewAssignmentBuilder />}
          />
          <Route
            path="/assignment/builder/:id"
            element={<NewAssignmentBuilder />}
          />

          <Route
            path="/grade-details/:gradeId/create/division"
            element={<CreateDivision />}
          />
          <Route path="/edit/division/:id" element={<CreateDivision />} />
          <Route path="/division-details/:id" element={<DivisionDetails />} />

          <Route
            path="/school-details/:schoolId/create/grade"
            element={<CreateGrade />}
          />
          <Route path="/edit/grade/:id" element={<CreateGrade />} />
          <Route path="/grade-details/:id" element={<GradeDetailsPage />} />

          <Route path="/schools" element={<Schools />} />
          <Route path="/school-details/:id" element={<SchoolDetailsPage />} />
          <Route path="/create/school" element={<CreateSchoolPage />} />
          <Route path="/edit/school/:id" element={<CreateSchoolPage />} />

          <Route path="/users" element={<Teachers />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/create/user" element={<CreateTeacher />} />
          <Route path="/edit/user/:id" element={<CreateTeacher />} />
          <Route
            path="/teacher/project/submissions"
            element={<TeacherProjectSubmissions />}
          />
          <Route
            path="/teacher/assignment/submissions"
            element={<AssignmentSubmissionCourses />}
          />
          <Route
            path="/teacher/assignment/submissions/:id"
            element={<TeacherAssignmentSubmissions />}
          />
          <Route
            path="/teacher/assignment/submissions/course/:courseId/assignment/:assignmentId/student/:id"
            element={<AssignmentSubmissionDetails />}
          />
          <Route
            path="/teacher/assignment/submissions/assignment/:id"
            element={<AssignmentSubmissionDetails />}
          />
          <Route
            path="/student/scratch/project/:projectId"
            element={<ScratchProjectEditor />}
          />
          <Route path="/archived/projects" element={<ArchiveProjects />} />
          <Route path="/student/projects" element={<Projects />} />
          <Route
            path="/student/create/project/:id"
            element={<CreateProject />}
          />
          <Route
            path="/view/project/:divisionId/:id/:projectId"
            element={<ViewProjectDetails />}
          />
          <Route path="/student/my-submissions" element={<MySubmissions />} />

          <Route
            path="/notification/view/project/:id"
            element={<ViewProjectNotification />}
          />
          <Route
            path="/notification/view/assignment/:id"
            element={<ViewAssignmentNotification />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default App;
