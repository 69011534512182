// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import axios from "axios";
import { EXPRESS_API_BASE_URL } from "./constant";
import Cookies from "universal-cookie";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBM1PYcU0U7RIGRZIj3uNJpfQPYrwNYuXc",
  authDomain: "codesquad-edu-platform.firebaseapp.com",
  projectId: "codesquad-edu-platform",
  storageBucket: "codesquad-edu-platform.appspot.com",
  messagingSenderId: "950173973413",
  appId: "1:950173973413:web:44fc9dbe1dacd76f76464c",
  measurementId: "G-XKFG2ZN0JG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const cookies = new Cookies();

export const analytics = getAnalytics(app);
export const messaging = getMessaging();

export const getFBToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BBntbZpvr8d6XO64mdT-4YGWoNgTZbn4MRK9FfYt68Sx56gLFronBrA8_hn2JcPhKxQZVSzYHzp29j7Gyje4P4M",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        localStorage.setItem("FCM_TOKEN", currentToken);
        setTokenFound(true);
        if (cookies.get("token")) {
          axios
            .post(
              `${EXPRESS_API_BASE_URL}/users/register-fcm`,
              {
                fcmToken: localStorage.getItem("FCM_TOKEN"),
              },
              {
                headers: {
                  Authorization: "Bearer " + cookies.get("token"),
                },
              }
            )
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
export default app;
