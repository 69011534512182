import React, { useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import MainLogo from "../assets/MainLogo.png";
import ClassesIcon from "../assets/icons/ClassesIcon";
import ProjectsIcon from "../assets/icons/ProjectsIcon";
import SchoolsIcon from "../assets/icons/SchoolsIcon";
import TeachersIcons from "../assets/icons/TeachersIcons";
import StorageService from "./StorageService";
import Cookies from "universal-cookie";
import { BarChart, Person } from "@mui/icons-material";

const MobileSideBar = ({
  menu,
  setMenu,
  classesHover,
  teacherSideHomeHover,
  assignmentsHover,
  schoolsHover,
  divisionHover,
  gradesHover,
  teachersHover,
  myAssignmentHover,
  projectsHover,
  mySubmissionsHover,
  myStudiesHover,
  teacherAssignmentSubmissionsHover,
  teacherProjectSubmissionsHover,
  dashboardHover,
  resourcesHover,
  username = null,
}) => {
  const newStorageService = new StorageService();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [isStudentLogin, setIsStudentLogin] = useState(false);
  const [isAdminLogin, setIsAdminLogin] = useState(false);
  const [isTeacherLogin, setIsTeacherLogin] = useState(false);

  const getStudent = () => {
    if (cookies.get("isStudentLogin")) {
      setIsStudentLogin(true);
    } else {
      setIsStudentLogin(false);
    }

    if (cookies.get("isAdminLogin")) {
      setIsAdminLogin(true);
    } else {
      setIsAdminLogin(false);
    }

    if (cookies.get("isTeacherLogin")) {
      setIsTeacherLogin(true);
    } else {
      setIsTeacherLogin(false);
    }
  };

  useEffect(() => {
    getStudent();
  }, []);

  useEffect(() => {
    newStorageService.changes.subscribe((localStoreObject) => {
      getStudent();
    });
  }, []);

  return (
    <div className="shadow-2xl border-r">
      <Drawer anchor="left" open={menu} onClose={() => setMenu(false)}>
        <Box width="100%" role="presentation">
          <div className="flex flex-col items-start pl-5 pr-5 overflow-hidden w-full">
            <button
              // onClick={() => navigate("/")}
              className="w-full h-full my-10 scale-150 flex justify-center"
            >
              <img src={MainLogo} alt="logo" />
            </button>

            {/* <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${"white"}`}
            >
              <Person
                style={{
                  color: "#006B5C",
                }}
              />

              <button
                onClick={() => {
                  navigate("/");
                }}
                className={`${"text-black"} px-4 py-2 font-semibold `}
              >
                {`Username : ${username}`}
              </button>
            </div> */}

            {/* <div className="flex flex-row justify-center text-center w-100 items-center">
              {username && (
                <div className="text-lg mobile:hidden md:flex text-center">
                  Username : <span className="font-bold">{username}</span>
                </div>
              )}
            </div> */}
            <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                dashboardHover === true ? "primary" : "white"
              } ${isStudentLogin || isTeacherLogin === true ? "hidden" : ""}`}
            >
              <BarChart
                style={{
                  color: dashboardHover === true ? "white" : "#006B5C",
                }}
              />

              <button
                onClick={() => {
                  navigate("/");
                }}
                className={`${
                  dashboardHover === true ? "text-white" : "text-black"
                } px-4 py-2 font-semibold `}
              >
                Dashboard
              </button>
            </div>

            <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                teacherSideHomeHover === true ? "primary" : "white"
              } ${isStudentLogin || isAdminLogin === true ? "hidden" : ""}`}
            >
              <BarChart
                style={{
                  color: teacherSideHomeHover === true ? "white" : "#006B5C",
                }}
              />

              <button
                onClick={() => {
                  navigate("/home");
                }}
                className={`${
                  teacherSideHomeHover === true ? "text-white" : "text-black"
                } px-4 py-2 font-semibold `}
              >
                Dashboard
              </button>
            </div>

            <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                classesHover === true ? "primary" : "white"
              } ${isStudentLogin === true ? "hidden" : ""}`}
            >
              <ClassesIcon
                fillColor={classesHover === true ? "white" : "#006B5C"}
              />

              <button
                onClick={() => {
                  navigate("/course");
                }}
                className={`${
                  classesHover === true ? "text-white" : "text-black"
                } px-4 py-2 font-semibold `}
              >
                Courses
              </button>
            </div>

            <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                assignmentsHover === true ? "primary" : "white"
              } ${isStudentLogin === true ? "hidden" : ""}`}
            >
              <ProjectsIcon
                fillColor={assignmentsHover === true ? "white" : "#006B5C"}
              />

              <button
                onClick={() => navigate("/assignments")}
                className={`${
                  assignmentsHover === true ? "text-white" : "text-black"
                } px-4 py-2 font-semibold `}
              >
                Assignments
              </button>
            </div>

            <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                schoolsHover === true ? "primary" : "white"
              } ${isStudentLogin ? "hidden" : ""}`}
            >
              <SchoolsIcon
                fillColor={schoolsHover === true ? "white" : "#006B5C"}
              />

              <button
                onClick={() => navigate("/schools")}
                className={`${
                  schoolsHover === true ? "text-white" : "text-black"
                } px-4 py-2 font-semibold`}
              >
                Schools
              </button>
            </div>

            <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                gradesHover === true ? "primary" : "white"
              } ${"hidden"}`}
            >
              <SchoolsIcon
                fillColor={gradesHover === true ? "white" : "#006B5C"}
              />

              <button
                onClick={() => navigate("/grades")}
                className={`${
                  gradesHover === true ? "text-white" : "text-black"
                } px-4 py-2 font-semibold`}
              >
                Grades
              </button>
            </div>

            <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                teachersHover === true ? "primary" : "white"
              } ${
                isStudentLogin === true || isTeacherLogin === true
                  ? "hidden"
                  : ""
              }`}
            >
              <TeachersIcons
                fillColor={teachersHover === true ? "white" : "#006B5C"}
              />

              <button
                onClick={() => navigate("/users")}
                className={`${
                  teachersHover === true ? "text-white" : "text-black"
                } px-4 py-2 font-semibold`}
              >
                Users
              </button>
            </div>

            <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                myStudiesHover === true ? "primary" : "white"
              } ${isAdminLogin || isTeacherLogin ? "hidden" : ""}`}
            >
              <ProjectsIcon
                fillColor={myStudiesHover === true ? "white" : "#006B5C"}
              />

              <button
                onClick={() => navigate("/student/my-studies")}
                className={`${
                  myStudiesHover === true ? "text-white" : "text-black"
                } px-4 py-2 font-semibold `}
              >
                Course Updates
              </button>
            </div>

            <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                myAssignmentHover === true ? "primary" : "white"
              } ${isAdminLogin || isTeacherLogin ? "hidden" : ""}`}
            >
              <ProjectsIcon
                fillColor={myAssignmentHover === true ? "white" : "#006B5C"}
              />

              <button
                onClick={() => navigate("/student/my-assignments")}
                className={`${
                  myAssignmentHover === true ? "text-white" : "text-black"
                } px-4 py-2 font-semibold `}
              >
                Assignments
              </button>
            </div>

            <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                projectsHover === true ? "primary" : "white"
              } ${isAdminLogin || isTeacherLogin ? "hidden" : ""}`}
            >
              <ProjectsIcon
                fillColor={projectsHover === true ? "white" : "#006B5C"}
              />

              <button
                onClick={() => navigate("/student/projects")}
                className={`${
                  projectsHover === true ? "text-white" : "text-black"
                } px-4 py-2 font-semibold `}
              >
                Projects
              </button>
            </div>

            <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                mySubmissionsHover === true ? "primary" : "white"
              } ${isAdminLogin || isTeacherLogin ? "hidden" : ""}`}
            >
              <ProjectsIcon
                fillColor={mySubmissionsHover === true ? "white" : "#006B5C"}
              />

              <button
                onClick={() => navigate("/student/my-submissions")}
                className={`${
                  mySubmissionsHover === true ? "text-white" : "text-black"
                } px-4 py-2 font-semibold `}
              >
                Submissions
              </button>
            </div>

            <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                resourcesHover === true ? "primary" : "white"
              } ${isAdminLogin ? "" : "hidden"}`}
            >
              <ProjectsIcon
                fillColor={resourcesHover === true ? "white" : "#006B5C"}
              />

              <button
                onClick={() => navigate("/resources")}
                className={`${
                  resourcesHover === true ? "text-white" : "text-black"
                } px-4 py-2 font-semibold `}
              >
                Resources
              </button>
            </div>

            {/* <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                teacherAssignmentSubmissionsHover === true ? "primary" : "white"
              } ${isTeacherLogin || isAdminLogin ? "" : "hidden"}`}
            >
              <ProjectsIcon
                fillColor={
                  teacherAssignmentSubmissionsHover === true
                    ? "white"
                    : "#006B5C"
                }
              />

              <button
                onClick={() => navigate("/teacher/assignment/submissions")}
                className={`${
                  teacherAssignmentSubmissionsHover === true
                    ? "text-white"
                    : "text-black"
                } px-4 py-2 font-semibold `}
              >
                Assignment submissions
              </button>
            </div> */}

            {/* <div
              className={`flex items-center w-full rounded-2xl cursor-pointer px-4 my-2 pr-8 bg-${
                teacherProjectSubmissionsHover === true ? "primary" : "white"
              } ${isTeacherLogin ? "" : "hidden"}`}
            >
              <ProjectsIcon
                fillColor={
                  teacherProjectSubmissionsHover === true ? "white" : "#006B5C"
                }
              />

              <button
                onClick={() => navigate("/teacher/project/submissions")}
                className={`${
                  teacherProjectSubmissionsHover === true
                    ? "text-white"
                    : "text-black"
                } px-4 py-2 font-semibold `}
              >
                Project submissions
              </button>
            </div> */}
          </div>

          <div className="flex justify-center items-center">
            <p className="absolute bottom-5 text-xs font-bold">
              CodeSquad - All rights reserved
            </p>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default MobileSideBar;
