import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import plusIcon from "../assets/icons/plusIcon.png";
import crossIcon from "../assets/icons/crossIcon.png";
import textfieldCross from "../assets/icons/textfieldCross.png";
import FilledButton from "../customs/FilledButton";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import axios from "axios";
import { EXPRESS_API_BASE_URL } from "../constant";
import AssignmentCard from "../customs/AssignmentCard";
import Loader from "../customs/Loader";
import useWindowSize from "../constants/UseWindowSize";
import MainLogo from "../assets/MainLogo.png";

// modal style
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fafdfa",
  boxShadow: 24,
  p: 2,
};

const Home = () => {
  const cookies = new Cookies();
  const fileInputRef = useRef();
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [isDataAvailable, setisDataAvailable] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [classNameInput, setClassNameInput] = useState("");
  // const [classCodeInput, setClassCodeInput] = useState("");
  const [editId, setEditId] = useState("");
  const [classDataArr, setClassDataArr] = useState([]);
  const [base64Uri, setBase64Uri] = useState("");
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTeacherLogin, setIsTeacherLogin] = useState(false);
  let token = cookies.get("token");

  useEffect(() => {
    if (width <= 501) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  const checkUser = () => {
    if (cookies.get("isTeacherLogin")) {
      setIsTeacherLogin(true);
    } else {
      setIsTeacherLogin(false);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  // get course dynamic list
  const getCourse = async () => {
    if (token) {
      try {
        setLoading(true);
        const res = await axios.get(`${EXPRESS_API_BASE_URL}/course`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClassDataArr(res.data?.data);
        setLoading(false);
        // console.log("getCourseRes", res.data.data);
      } catch (error) {
        setLoading(false);
        console.log("getCourse error", error);
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  // set specific selected course data in modal
  const handleEditCourse = ({ id }) => {
    if (id) {
      const res = classDataArr?.find((val) => val?.id === parseInt(id));
      if (res) {
        setClassNameInput(res?.name);
        // setClassCodeInput(res?.CourseCode);
        setBase64Uri(res?.imageUrl);
      }
      setOpen(true);
      setEditId(id);
    }
  };

  // converting image to base64
  function getBase64(file, callback) {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(file);
    }
  }

  useEffect(() => {
    getBase64(selectedFile, function (base64Data) {
      setBase64Uri(base64Data);
    });
  }, [selectedFile]);

  // handle submit
  const handleAddCourse = async () => {
    if (classNameInput) {
      if (parseInt(editId)) {
        try {
          setLoading(true);
          const res = await axios.put(
            `${EXPRESS_API_BASE_URL}/course/${parseInt(editId)}`,
            {
              name: classNameInput,
              imageUrl: base64Uri,
              // courseCode: classCodeInput,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );

          // console.log("edit class res", res.data.data);
          toast.success("Course has been updated!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });

          navigate(`/course`);
          setSelectedFile("");
          setClassNameInput("");
          // setClassCodeInput();
          getCourse();
          setOpen(false);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log("edit course error", error);
        }
      } else {
        try {
          setLoading(true);
          let finalClassName = classNameInput.trim();
          let randomThreeDigitNo = Math.floor(
            Math.random() * (999 - 100 + 1) + 100
          );

          let APIfinalClassName =
            finalClassName.toLowerCase() + "_" + randomThreeDigitNo;

          const res = await axios.post(
            `${EXPRESS_API_BASE_URL}/course/`,
            {
              name: classNameInput,
              imageUrl: base64Uri,
              courseCode: APIfinalClassName?.replace(/ /g, ""),
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );

          // console.log("create class res", res.data.data);
          toast.success("Course created successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });

          navigate(`/course`);
          setSelectedFile("");
          setClassNameInput("");
          // setClassCodeInput();
          getCourse();
          setOpen(false);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log("create class error", error);
        }
      }
    } else {
      setLoading(false);
      toast.warning("Course name required!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    if (classDataArr?.length === 0) {
      setisDataAvailable(true);
    } else {
      setisDataAvailable(false);
    }
  }, [classDataArr]);

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="flex w-4/5 mobile:w-full ipad:w-full position-class pb-10">
          <div className="flex flex-col w-full">
            <div className="bg-secondary pt-5 px-5 pb-2 flex justify-between items-center mb-5 stickyNavbar z-40">
              <p className="text-2xl font-semibold">Courses</p>

              <div className="flex items-center gap-5">
                {isTeacherLogin ? (
                  <></>
                ) : (
                  <FilledButton
                    onClickFilled={() => setOpen(true)}
                    filledBtnIcon={plusIcon}
                    filledBtnText="Create"
                    loading={loading}
                  />
                )}
              </div>
            </div>

            {isDataAvailable === true ? (
              <p
                style={{
                  position: "absolute",
                  left: width < 500 ? "35%" : "40%",
                }}
                className="font-semibold text-xl pt-60"
              >
                No courses found!
              </p>
            ) : (
              <div className="flex flex-wrap my-2 gap-5 mobile:justify-center px-5 mobile:px-0 w-full">
                {classDataArr?.map((val, index) => {
                  return (
                    <div key={index}>
                      {val?.name ? (
                        <AssignmentCard
                          imageClassname="course-card-image-width"
                          courseImg={val?.imageUrl ? val?.imageUrl : MainLogo}
                          Title={val?.name}
                          // unFilledBtnText="Details"
                          onClickDiv={() =>
                            navigate(`/classes-details/${parseInt(val?.id)}`, {
                              state: { classDataArr },
                            })
                          }
                          filledBtnText={isTeacherLogin ? "" : "Edit"}
                          onClickFilled={() =>
                            handleEditCourse({ id: val?.id })
                          }
                          teacherCourseCardHere={isTeacherLogin ? true : false}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="">
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="w-680 h-480 rounded-xl create-class-modal">
            <div className="flex justify-between">
              <p className="text-primary text-3xl ipad:text-2xl mobile:text-2xl">
                {editId ? "Edit Course" : "Create Course"}
              </p>

              <button onClick={() => setOpen(false)} className="p-2">
                <img src={crossIcon} alt="cross" />
              </button>
            </div>

            <div className="mt-8">
              <TextField
                style={{ width: isMobile ? "100%" : "80%" }}
                id="outlined-multiline-flexible"
                label="Course Name *"
                multiline
                InputProps={{
                  endAdornment:
                    classNameInput && classNameInput.length >= 1 ? (
                      <button onClick={() => setClassNameInput("")}>
                        <img src={textfieldCross} alt="cross" />
                      </button>
                    ) : null,
                }}
                value={classNameInput}
                onChange={(e) => setClassNameInput(e.target.value)}
              />
            </div>

            <div
              className="my-4 flex items-center flex-wrap gap-2 border border-gray-300 px-2 py-3 rounded-sm"
              style={{ width: isMobile ? "100%" : "80%" }}
            >
              <p className="font-semibold text-lg mobile:text-xs">
                Upload class graphic :{" "}
              </p>

              <div>
                <input
                  type="file"
                  className="hidden"
                  ref={fileInputRef}
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
                <FilledButton
                  onClickFilled={() => fileInputRef.current.click()}
                  filledBtnText="Upload Image"
                  loading={loading}
                />
              </div>
            </div>

            {/* <div className="">
              <TextField
                style={{ width: isMobile ? "100%" : "80%" }}
                id="outlined-multiline-flexible"
                label="Course Code *"
                multiline
                InputProps={{
                  endAdornment:
                    classCodeInput && classCodeInput.length >= 1 ? (
                      <button onClick={() => setClassCodeInput("")}>
                        <img src={textfieldCross} alt="cross" />
                      </button>
                    ) : null,
                }}
                value={classCodeInput}
                onChange={(e) => setClassCodeInput(e.target.value)}
              />
            </div> */}

            <div className="absolute bottom-3 right-3">
              <FilledButton
                onClickFilled={handleAddCourse}
                filledBtnText={editId ? "Update Course" : "Create Course"}
                loading={loading}
              />
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Home;
