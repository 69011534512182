import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { EXPRESS_API_BASE_URL } from "../constant";
import FilledButton from "../customs/FilledButton";
import Loader from "../customs/Loader";
import SchoolCard from "../customs/SchoolCard";
import useWindowSize from "../constants/UseWindowSize";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { toast } from "react-toastify";
import UnFilledButton from "../customs/UnFilledButton";

// select multiple style
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

// modal style
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fafdfa",
  boxShadow: 24,
  p: 2,
};

const GradeDetailsPage = () => {
  const { id } = useParams();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [isDataAvailable, setisDataAvailable] = useState(false);
  const [isTeacherLogin, setIsTeacherLogin] = useState(false);
  const [cohorts, setCohorts] = useState([]);
  const [open, setOpen] = useState(false);
  const [coursesArr, setCoursesArr] = useState([]); // courses data for dropdown
  const [selectedCourses, setSelectedCourses] = useState([]); // selected values from dropdown
  const [gradeUrl, setGradeUrl] = useState("");
  let token = cookies.get("token");

  const checkUser = () => {
    if (cookies.get("isTeacherLogin")) {
      setIsTeacherLogin(true);
    } else {
      setIsTeacherLogin(false);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  // get Cohorts dynamic list
  const getCohorts = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${EXPRESS_API_BASE_URL}/grade/${parseInt(id)}/cohorts`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.data.data) {
        setCohorts(res.data.data);
        setGradeUrl(`/school-details/${res.data.data[0].grade.school.id}`);
        // console.log("getCohorts res", res.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("getCohorts error", error);
    }
  };

  useEffect(() => {
    getCohorts();
  }, [id]);

  useEffect(() => {
    if (cohorts?.length === 0) {
      setisDataAvailable(true);
    } else {
      setisDataAvailable(false);
    }
  }, [cohorts]);

  // get courses dynamic list
  const getCourses = async () => {
    let token = cookies.get("token");
    try {
      const res = await axios.get(`${EXPRESS_API_BASE_URL}/course`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log("getCourseRes", res.data.data);
      setCoursesArr(res.data.data);
    } catch (error) {
      console.log("getCourse error", error);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  // handle onchange on select courses modal dropdown
  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCourses(typeof value === "string" ? value.split(",") : value);
  };

  // adding assignment to the course
  const handleAddCourses = async () => {
    if (selectedCourses.length === 0) {
      toast.warn("Select atleast one course!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      try {
        setLoading(true);
        await axios.post(
          `${EXPRESS_API_BASE_URL}/grade/${parseInt(id)}/courses`,
          { courseIds: selectedCourses },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        // console.log("add courses res", res.data.data);
        toast.success("Courses added!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        setSelectedCourses([]);
        setOpen(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("add courses error", error);
      }
    }
  };

  // displaying names of selcted courses as Chip
  const handleSelectChip = (selected) => {
    let newArr = [];
    if (selected) {
      let matchedData = coursesArr?.find((element) => element?.id === selected);
      newArr.push(matchedData);
    }
    return newArr?.map((value) => <Chip key={value.id} label={value.name} />);
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="w-4/5 mobile:w-full ipad:w-full position-class">
          <div className="bg-secondary pt-5 pb-2 px-5 flex justify-between items-center mobile:flex-col mb-10 stickyNavbar z-40">
            <div className="flex flex-wrap items-center gap-1 mobile:mb-3">
              <p
                onClick={() => {
                  navigate("/schools");
                }}
                className="cursor-pointer text-primary text-xl font-semibold mobile:text-sm mobile:font-bold"
              >
                {cohorts && cohorts.length > 0
                  ? cohorts[0]?.grade?.school?.name + " / "
                  : "Schools / "}
              </p>
              <Link to={gradeUrl}>
                <p className="cursor-pointer text-primary  text-xl font-semibold mobile:text-sm mobile:font-bold">
                  {cohorts && cohorts.length > 0
                    ? cohorts[0]?.grade?.name + " / "
                    : "Grades / "}
                </p>
              </Link>
              <p className="text-black text-xl font-semibold mobile:text-sm mobile:font-bold">
                {"Divisions "}
              </p>
            </div>

            {isTeacherLogin === true ? (
              <></>
            ) : (
              <div className="flex gap-2 justify-end items-center px-4 py-1 mobile:justify-start">
                <FilledButton
                  onClickFilled={() =>
                    navigate(`/grade-details/${parseInt(id)}/create/division`)
                  }
                  filledBtnText="Add Division"
                  loading={loading}
                />

                {/* <UnFilledButton
                  onClickUnFilled={() => {
                    setOpen(true);
                  }}
                  unFilledBtnText="Map courses"
                  loading={loading}
                /> */}
              </div>
            )}
          </div>

          {isDataAvailable === true ? (
            <p
              style={{
                position: "absolute",
                left: width < 500 ? "35%" : "40%",
              }}
              className="font-semibold text-xl pt-60"
            >
              No data found!
            </p>
          ) : (
            <div className="px-5">
              <div className="flex flex-wrap gap-5 mt-5 mb-2 mobile:w-full mobile:mx-auto mobile:justify-center">
                {cohorts?.map((val, index) => {
                  return (
                    <div key={index}>
                      {val.name ? (
                        <SchoolCard
                          onClickDiv={() => {
                            navigate(`/division-details/${parseInt(val?.id)}`);
                          }}
                          name={val?.name}
                          // unFilledBtnText="Details"
                          // onClickUnFilled={() =>
                          //   navigate(`/division-details/${parseInt(val?.id)}`)
                          // }
                          filledBtnText="Edit"
                          onClickFilled={() =>
                            navigate(`/edit/division/${val?.id}`)
                          }
                          noOfChildsText="Number of Students"
                          noOfChilds={val?.students?.length}
                          loading={loading}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div className="">
            <Modal
              open={open}
              onClose={() => {
                setOpen(false);
                setSelectedCourses([]);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="w-96 rounded-xl create-class-modal">
                <div className="my-5">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-chip-label">
                      Select courses
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={selectedCourses}
                      onChange={handleChangeSelect}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Select courses"
                        />
                      }
                      renderValue={(selected) => (
                        // <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        //   {selected.map((value) => (
                        //     <Chip key={value} label={value} />
                        //   ))}
                        // </Box>
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={handleSelectChip(value)} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {coursesArr?.map((val, index) =>
                        val?.name ? (
                          <MenuItem key={index} value={val?.id}>
                            {val?.name}
                          </MenuItem>
                        ) : (
                          <></>
                        )
                      )}
                    </Select>
                  </FormControl>

                  <div className="mt-5 mb-2 w-full flex justify-center items-center">
                    <button
                      onClick={handleAddCourses}
                      className={`border border-primary bg-primary text-white text-center rounded-xl py-2 w-full`}
                    >
                      Map Courses
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default GradeDetailsPage;
