import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ClassesIcon from "../assets/icons/ClassesIcon";
import ProjectsIcon from "../assets/icons/ProjectsIcon";
import SchoolsIcon from "../assets/icons/SchoolsIcon";
import TeachersIcons from "../assets/icons/TeachersIcons";
import StorageService from "./StorageService";
import Cookies from "universal-cookie";
import {
  Article,
  BarChart,
  Business,
  ExpandLess,
  ExpandMore,
  StarBorder,
} from "@mui/icons-material";
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import { EXPRESS_API_BASE_URL } from "../constant";

const DesktopSideBar = ({
  isSidebarOpen,
  dashboardHover,
  teacherSideHomeHover,
  classesHover,
  resourcesHover,
  assignmentsHover,
  schoolsHover,
  divisionHover,
  gradesHover,
  teachersHover,
  myAssignmentHover,
  projectsHover,
  mySubmissionsHover,
  myStudiesHover,
  teacherAssignmentSubmissionsHover,
  teacherProjectSubmissionsHover,
}) => {
  const newStorageService = new StorageService();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isStudentLogin, setIsStudentLogin] = useState(false);
  const [isAdminLogin, setIsAdminLogin] = useState(false);
  const [isTeacherLogin, setIsTeacherLogin] = useState(false);
  const [expandSchoolTab, setExpandSchoolTab] = useState(false);
  const [expandGradeTab, setExpandGradeTab] = useState(false);
  const [expandDivisionTab, setExpandDivisionTab] = useState(false);
  const [selectedSchoolIndex, setSelectedSchoolIndex] = useState(null);
  const [selectedDivisionIndex, setSelectedDivisionIndex] = useState(null);
  const [schoolsRes, setSchoolsRes] = useState([]);
  let token = cookies.get("token");

  useEffect(() => {
    if (expandSchoolTab === false) {
      setExpandGradeTab(false);
      setExpandDivisionTab(false);
      setSelectedSchoolIndex(null);
      setSelectedDivisionIndex(null);
    }
  }, [expandSchoolTab]);

  useEffect(() => {
    if (expandGradeTab === false) {
      setExpandDivisionTab(false);
      setSelectedDivisionIndex(null);
    }
  }, [expandGradeTab]);

  const getStudent = () => {
    if (cookies.get("isStudentLogin")) {
      setIsStudentLogin(true);
    } else {
      setIsStudentLogin(false);
    }

    if (cookies.get("isAdminLogin")) {
      setIsAdminLogin(true);
    } else {
      setIsAdminLogin(false);
    }

    if (cookies.get("isTeacherLogin")) {
      setIsTeacherLogin(true);
    } else {
      setIsTeacherLogin(false);
    }
  };

  useEffect(() => {
    getStudent();
  }, []);

  useEffect(() => {
    newStorageService.changes.subscribe((localStoreObject) => {
      getStudent();
    });
  }, []);

  // get schools dynamic list
  const getSchools = async () => {
    if (isAdminLogin === true || isTeacherLogin === true) {
      try {
        setLoading(true);
        const res = await axios.get(`${EXPRESS_API_BASE_URL}/school`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSchoolsRes(res.data.data);
        setLoading(false);
        // console.log("getSchoolsRes", res.data.data);
      } catch (error) {
        setLoading(false);
        console.log("getSchools error", error);
      }
    }
  };

  useEffect(() => {
    getSchools();
  }, [isAdminLogin, isTeacherLogin]);

  return (
    <>
      {isSidebarOpen === true ? (
        <div
          style={{ minHeight: "90vh", top: "10%" }}
          className="shadow-2xl border-r w-1/5 h-1/2 sidebar-main overflow-x-hidden fixed bg-secondary"
        >
          <div className=" flex flex-col justify-center items-start p-4 w-full">
            <div className="flex flex-col justify-between items-start w-full pb-5 my-2">
              <div
                // onMouseEnter={() => setClassesHover(true)}
                // onMouseLeave={() => setClassesHover(false)}
                className={`flex items-center w-full rounded-2xl cursor-pointer pl-3 pr-1 my-2 bg-${
                  dashboardHover === true ? "primary" : "white"
                } ${isStudentLogin || isTeacherLogin ? "hidden" : ""}`}
              >
                <BarChart
                  style={{
                    color: dashboardHover === true ? "white" : "#006B5C",
                  }}
                />

                <Link
                  to="/"
                  className={`p-3 font-semibold w-full laptop:text-sm text-${
                    dashboardHover === true ? "white" : "black"
                  }`}
                >
                  Dashboard
                </Link>
              </div>

              <div
                // onMouseEnter={() => setClassesHover(true)}
                // onMouseLeave={() => setClassesHover(false)}
                className={`flex items-center w-full rounded-2xl cursor-pointer pl-3 pr-1 my-2 bg-${
                  teacherSideHomeHover === true ? "primary" : "white"
                } ${isStudentLogin || isAdminLogin ? "hidden" : ""}`}
              >
                <BarChart
                  style={{
                    color: teacherSideHomeHover === true ? "white" : "#006B5C",
                  }}
                />

                <Link
                  to="/home"
                  className={`p-3 font-semibold w-full laptop:text-sm text-${
                    teacherSideHomeHover === true ? "white" : "black"
                  }`}
                >
                  Dashboard
                </Link>
              </div>

              <div
                // onMouseEnter={() => setClassesHover(true)}
                // onMouseLeave={() => setClassesHover(false)}
                className={`flex items-center w-full rounded-2xl cursor-pointer pl-3 pr-1 my-2 bg-${
                  classesHover === true ? "primary" : "white"
                } ${isStudentLogin ? "hidden" : ""}`}
              >
                <ClassesIcon
                  fillColor={classesHover === true ? "white" : "#006B5C"}
                />

                <Link
                  to="/course"
                  className={`p-3 font-semibold w-full laptop:text-sm text-${
                    classesHover === true ? "white" : "black"
                  }`}
                >
                  Courses
                </Link>
              </div>

              <div
                // onMouseEnter={() => setProjectsHover(true)}
                // onMouseLeave={() => setProjectsHover(false)}
                className={`flex items-center w-full rounded-2xl cursor-pointer pl-3 pr-1 my-2 bg-${
                  assignmentsHover === true ? "primary" : "white"
                } ${isStudentLogin ? "hidden" : ""}`}
              >
                <ProjectsIcon
                  fillColor={assignmentsHover === true ? "white" : "#006B5C"}
                />

                <Link
                  to="/assignments"
                  className={`p-3 font-semibold w-full laptop:text-sm text-${
                    assignmentsHover === true ? "white" : "black"
                  }`}
                >
                  Assignments
                </Link>
              </div>

              <div
                // onMouseEnter={() => setSchoolsHover(true)}
                // onMouseLeave={() => setSchoolsHover(false)}
                className={`flex justify-between items-center w-full rounded-2xl cursor-pointer px-3 my-2 bg-${
                  schoolsHover === true ? "primary" : "white"
                } ${isStudentLogin ? "hidden" : ""}`}
                onClick={() => {
                  setExpandSchoolTab(!expandSchoolTab);
                }}
              >
                <div className="flex items-center">
                  <SchoolsIcon
                    fillColor={schoolsHover === true ? "white" : "#006B5C"}
                  />

                  {/* <p
                    onClick={() => {
                      setExpandSchoolTab(!expandSchoolTab);
                    }}
                    className={`p-3 font-semibold w-full laptop:text-sm text-${
                      schoolsHover === true ? "white" : "black"
                    }`}
                  >
                    Schools
                  </p> */}

                  <Link
                    to="/schools"
                    className={`p-3 font-semibold w-full laptop:text-sm text-${
                      schoolsHover === true ? "white" : "black"
                    } hover:underline`}
                  >
                    Schools
                  </Link>
                </div>

                <div
                  onClick={() => {
                    setExpandSchoolTab(!expandSchoolTab);
                  }}
                  className={`text-${
                    schoolsHover === true ? "white" : "black"
                  } hover:bg-green-600 rounded-full`}
                >
                  {expandSchoolTab ? <ExpandLess /> : <ExpandMore />}
                </div>
              </div>

              <Collapse
                in={expandSchoolTab}
                timeout="auto"
                unmountOnExit
                style={{ width: "100%" }}
              >
                {schoolsRes?.map((val, index) => {
                  return (
                    <>
                      <List
                        component="div"
                        disablePadding
                        style={{ width: "100%" }}
                        onClick={() => {
                          setExpandGradeTab(!expandGradeTab);
                          setSelectedSchoolIndex(
                            selectedSchoolIndex == index ? -1 : index
                          );
                        }}
                      >
                        <ListItemButton sx={{ pl: 2 }}>
                          <ListItemIcon>
                            <Business />
                          </ListItemIcon>

                          <ListItemText
                            className="hover:underline"
                            primary={val?.name}
                            onClick={() => {
                              navigate(`/school-details/${val.id}`);
                            }}
                          />

                          <div
                            onClick={() => {
                              // navigate(`/school-details/${val.id}`);
                              setExpandGradeTab(!expandGradeTab);
                              setSelectedSchoolIndex(
                                selectedSchoolIndex == index ? -1 : index
                              );
                            }}
                            className="text-black hover:bg-gray-300 rounded-full"
                          >
                            {expandGradeTab &&
                            selectedSchoolIndex == index &&
                            selectedSchoolIndex !== -1 ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </div>
                        </ListItemButton>
                      </List>

                      {schoolsRes[parseInt(selectedSchoolIndex)]?.grades?.map(
                        (nVal, nIndex) => {
                          return (
                            <>
                              <Collapse
                                in={
                                  // expandGradeTab
                                  selectedSchoolIndex == index ? true : false
                                }
                                timeout="auto"
                                unmountOnExit
                                style={{ width: "100%" }}
                              >
                                <List
                                  component="div"
                                  disablePadding
                                  style={{ width: "100%" }}
                                >
                                  <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                      <Business />
                                    </ListItemIcon>

                                    <ListItemText
                                      className="hover:underline"
                                      primary={nVal?.name}
                                      onClick={() => {
                                        navigate(`/grade-details/${nVal.id}`);
                                        setExpandDivisionTab(
                                          !expandDivisionTab
                                        );
                                        setSelectedDivisionIndex(
                                          selectedDivisionIndex == nIndex
                                            ? -1
                                            : nIndex
                                        );
                                      }}
                                    />

                                    <div
                                      onClick={() => {
                                        // navigate(`/grade-details/${nVal.id}`);
                                        setExpandDivisionTab(
                                          !expandDivisionTab
                                        );
                                        setSelectedDivisionIndex(
                                          selectedDivisionIndex == nIndex
                                            ? -1
                                            : nIndex
                                        );
                                      }}
                                      className="text-black hover:bg-gray-300 rounded-full"
                                    >
                                      {expandDivisionTab &&
                                      selectedDivisionIndex == nIndex &&
                                      selectedDivisionIndex !== -1 ? (
                                        <ExpandLess />
                                      ) : (
                                        <ExpandMore />
                                      )}
                                    </div>
                                  </ListItemButton>

                                  <div style={{ width: "90%", float: "right" }}>
                                    <Divider />
                                  </div>
                                </List>
                              </Collapse>

                              <Collapse
                                in={
                                  // expandDivisionTab
                                  selectedSchoolIndex == index &&
                                  selectedDivisionIndex == nIndex
                                    ? true
                                    : false
                                }
                                timeout="auto"
                                unmountOnExit
                                style={{ width: "100%" }}
                              >
                                {schoolsRes[
                                  parseInt(selectedSchoolIndex)
                                ]?.grades[
                                  parseInt(selectedDivisionIndex)
                                ]?.cohorts?.map((nnVal, nnIndex) => {
                                  return (
                                    <List
                                      component="div"
                                      disablePadding
                                      style={{ width: "100%" }}
                                    >
                                      <ListItemButton sx={{ pl: 7 }}>
                                        <ListItemIcon>
                                          <Business />
                                        </ListItemIcon>

                                        <ListItemText
                                          className="hover:underline"
                                          primary={nnVal?.name}
                                          onClick={() => {
                                            navigate(
                                              `/division-details/${nnVal.id}`
                                            );
                                          }}
                                        />
                                      </ListItemButton>

                                      <div
                                        style={{ width: "84%", float: "right" }}
                                      >
                                        <Divider />
                                      </div>
                                    </List>
                                  );
                                })}
                              </Collapse>
                            </>
                          );
                        }
                      )}

                      <Divider />
                    </>
                  );
                })}
              </Collapse>

              <div
                // onMouseEnter={() => setDivisionsHover(true)}
                // onMouseLeave={() => setDivisionsHover(false)}
                className={`flex items-center w-full rounded-2xl cursor-pointer pl-3 pr-1 my-2 bg-${
                  gradesHover === true ? "primary" : "white"
                } ${"hidden"}`}
              >
                <SchoolsIcon
                  fillColor={gradesHover === true ? "white" : "#006B5C"}
                />

                <Link
                  to="/grades"
                  className={`p-3 font-semibold w-full laptop:text-sm text-${
                    gradesHover === true ? "white" : "black"
                  }`}
                >
                  Grades
                </Link>
              </div>

              <div
                // onMouseEnter={() => setTeachersHover(true)}
                // onMouseLeave={() => setTeachersHover(false)}
                className={`flex items-center w-full rounded-2xl cursor-pointer pl-3 pr-1 my-2 bg-${
                  teachersHover === true ? "primary" : "white"
                } ${isStudentLogin || isTeacherLogin === true ? "hidden" : ""}`}
              >
                <TeachersIcons
                  fillColor={teachersHover === true ? "white" : "#006B5C"}
                />

                <Link
                  to="/users"
                  className={`p-3 font-semibold w-full laptop:text-sm text-${
                    teachersHover === true ? "white" : "black"
                  }`}
                >
                  Users
                </Link>
              </div>

              <div
                // onMouseEnter={() => setTeachersHover(true)}
                // onMouseLeave={() => setTeachersHover(false)}
                className={`flex items-center w-full rounded-2xl cursor-pointer pl-3 pr-1 my-2 bg-${
                  resourcesHover === true ? "primary" : "white"
                } ${isStudentLogin || isTeacherLogin === true ? "hidden" : ""}`}
              >
                <Article
                  style={{
                    color: resourcesHover === true ? "white" : "#006B5C",
                  }}
                  fillColor={resourcesHover === true ? "white" : "#006B5C"}
                />

                <Link
                  to="/resources"
                  className={`p-3 font-semibold w-full laptop:text-sm text-${
                    resourcesHover === true ? "white" : "black"
                  }`}
                >
                  Resources
                </Link>
              </div>

              <div
                className={`flex items-center w-full rounded-2xl cursor-pointer pl-3 pr-1 my-2 bg-${
                  myStudiesHover === true ? "primary" : "white"
                } ${isAdminLogin || isTeacherLogin ? "hidden" : ""}`}
              >
                <ProjectsIcon
                  fillColor={myStudiesHover === true ? "white" : "#006B5C"}
                />

                <Link
                  to="/student/my-studies"
                  className={`p-3 font-semibold w-full laptop:text-sm text-${
                    myStudiesHover === true ? "white" : "black"
                  }`}
                >
                  Course Updates
                </Link>
              </div>

              <div
                className={`flex items-center w-full rounded-2xl cursor-pointer pl-3 pr-1 my-2 bg-${
                  myAssignmentHover === true ? "primary" : "white"
                } ${isAdminLogin || isTeacherLogin ? "hidden" : ""}`}
              >
                <ProjectsIcon
                  fillColor={myAssignmentHover === true ? "white" : "#006B5C"}
                />

                <Link
                  to="/student/my-assignments"
                  className={`p-3 font-semibold w-full laptop:text-sm text-${
                    myAssignmentHover === true ? "white" : "black"
                  }`}
                >
                  Assignments
                </Link>
              </div>

              <div
                className={`flex items-center w-full rounded-2xl cursor-pointer pl-3 pr-1 my-2 bg-${
                  projectsHover === true ? "primary" : "white"
                } ${isAdminLogin || isTeacherLogin ? "hidden" : ""}`}
              >
                <ProjectsIcon
                  fillColor={projectsHover === true ? "white" : "#006B5C"}
                />

                <Link
                  to="/student/projects"
                  className={`p-3 font-semibold w-full laptop:text-sm text-${
                    projectsHover === true ? "white" : "black"
                  }`}
                >
                  Projects
                </Link>
              </div>

              <div
                className={`flex items-center w-full rounded-2xl cursor-pointer pl-3 pr-1 my-2 bg-${
                  mySubmissionsHover === true ? "primary" : "white"
                } ${isAdminLogin || isTeacherLogin ? "hidden" : ""}`}
              >
                <ProjectsIcon
                  fillColor={mySubmissionsHover === true ? "white" : "#006B5C"}
                />

                <Link
                  to="/student/my-submissions"
                  className={`p-3 font-semibold w-full laptop:text-sm text-${
                    mySubmissionsHover === true ? "white" : "black"
                  }`}
                >
                  Submissions
                </Link>
              </div>

              {/* <div
                className={`flex items-center w-full rounded-2xl cursor-pointer pl-3 pr-1 my-2 bg-${
                  teacherAssignmentSubmissionsHover === true
                    ? "primary"
                    : "white"
                } ${isTeacherLogin || isAdminLogin ? "" : "hidden"}`}
              >
                <ProjectsIcon
                  fillColor={
                    teacherAssignmentSubmissionsHover === true
                      ? "white"
                      : "#006B5C"
                  }
                />

                <Link
                  to="/teacher/assignment/submissions"
                  className={`p-3 font-semibold w-full laptop:text-sm text-${
                    teacherAssignmentSubmissionsHover === true
                      ? "white"
                      : "black"
                  }`}
                >
                  Assignment submissions
                </Link>
              </div> */}

              {/* <div
                className={`flex items-center w-full rounded-2xl cursor-pointer pl-3 pr-1 my-2 bg-${
                  teacherProjectSubmissionsHover === true ? "primary" : "white"
                } ${isTeacherLogin ? "" : "hidden"}`}
              >
                <ProjectsIcon
                  fillColor={
                    teacherProjectSubmissionsHover === true
                      ? "white"
                      : "#006B5C"
                  }
                />

                <Link
                  to="/teacher/project/submissions"
                  className={`p-3 font-semibold w-full laptop:text-sm text-${
                    teacherProjectSubmissionsHover === true ? "white" : "black"
                  }`}
                >
                  Project submissions
                </Link>
              </div> */}
            </div>
          </div>

          {/* <div className="flex justify-center items-end h-auto">
            <p className=" text-sm font-bold laptop:text-xs">
              CodeSquad - All rights reserved
            </p>
          </div> */}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default DesktopSideBar;
