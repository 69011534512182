import React from "react";

const DeleteFilledButton = (props) => {
  return (
    <button
      onClick={props?.onClickDeleteFilled}
      disabled={props?.loading === true ? true : false}
      className={`border border-red-600 bg-red-500 text-white rounded-3xl py-2 px-5 
        flex items-center gap-3 mobile:gap-1 text-sm ${
          props?.timepa === true ? "leading-customLH" : "leading-none"
        } hover:bg-red-600`}
    >
      {props?.deleteFilledBtnIcon ? (
        <img src={props?.deleteFilledBtnIcon} alt="icon" />
      ) : null}

      {props?.loading === true ? "Loading..." : props?.deleteFilledBtnText}
    </button>
  );
};

export default DeleteFilledButton;
