import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  outlinedInputClasses,
  Select,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import FilledButton from "../customs/FilledButton";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../customs/Loader";
import axios from "axios";
import { EXPRESS_API_BASE_URL } from "../constant";
import { usePrompt } from "../constants/UsePrompt";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

// changing border colors for dropdown
const StyledForm = styled("form")(`
  display: flex;
  flex-wrap: wrap;
`);
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: 10,
  width: "100%",
}));
const StyledSelect = styled(Select)(`
  & .${outlinedInputClasses.notchedOutline} {
    border-color: "#006b5c";
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: "#006b5c";
  }
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: "#006b5c";
  }
`);

const CreateTeacher = () => {
  const { id } = useParams();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  // const [schoolId, setSchoolId] = useState("");
  // const [schools, setSchools] = useState([]);
  // const [gradeId, setGradeId] = useState("");
  // const [grades, setGrades] = useState([]);
  const [divisionId, setDivisionId] = useState("");
  const [divisions, setDivisions] = useState([]);
  // const [selectedRole, setSelectedRole] = useState("");
  const [teacherDetails, setTeacherDetails] = useState(null);
  const [navBlocker, setNavBlocker] = useState(false);
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const inputType = visible ? "text" : "password";
  let token = cookies.get("token");

  // generate random password using firstname
  const generatePassword = (firstName) => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    let newPassword = firstName + "_" + retVal;
    return newPassword;
  };

  // handle unsaved prompt
  useEffect(() => {
    if (
      firstName.length !== 0 ||
      lastName.length !== 0 ||
      emailId.length !== 0 ||
      divisionId.length !== 0
      // schoolId.length !== 0
    ) {
      setNavBlocker(true);
    } else {
      setNavBlocker(false);
    }
  }, [firstName, lastName, emailId, divisionId]);
  // }, [firstName, lastName, emailId, schoolId]);
  usePrompt("Unsaved changes, are you sure you want to exit?", navBlocker);

  // get schools dynamic list
  const getSchools = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${EXPRESS_API_BASE_URL}/cohort`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // setSchools(res.data.data);
      setDivisions(res.data.data);
      setLoading(false);
      // console.log("getSchoolsRes", res.data.data);
    } catch (error) {
      setLoading(false);
      console.log("getSchools error", error);
    }
  };

  useEffect(() => {
    getSchools();
  }, []);

  // // get Grades dynamic list
  // const getGrades = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await axios.get(`${EXPRESS_API_BASE_URL}/cohort`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  //     setGrades(res.data.data);
  //     setLoading(false);
  //     // console.log("getGradesRes", res.data.data);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log("getGrades error", error);
  //   }
  // };

  // useEffect(() => {
  //   getGrades();
  // }, [schoolId]);

  // // get Divisions dynamic list
  // const getDivisions = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await axios.get(`${EXPRESS_API_BASE_URL}/cohort`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  //     setDivisions(res.data.data);
  //     setLoading(false);
  //     // console.log("getDivisionsRes", res.data.data);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log("getDivisions error", error);
  //   }
  // };

  // useEffect(() => {
  //   getDivisions();
  // }, [gradeId]);

  // get details
  const getDetails = async () => {
    if (id) {
      try {
        setLoading(true);
        const res = await axios.get(
          `${EXPRESS_API_BASE_URL}/users/${parseInt(id)}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // console.log("get user details res", res.data.data);
        setTeacherDetails(res.data.data);
        setFirstName(res.data.data?.firstName);
        setLastName(res.data.data?.lastName);
        setEmailId(res.data.data?.email);
        setContactNumber(res.data.data?.contactNumber);
        // setSchoolId(res.data.data?.CohortId);
        setDivisionId(res.data.data?.CohortId);
        // setSelectedRole(res.data.data?.roleId);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("get user details error", error);
      }
    }
  };

  useEffect(() => {
    getDetails();
  }, [id]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // add user api integration
  const onSaveTeacher = async (e) => {
    e.preventDefault();

    let finalFirstName = firstName.trim();
    let finalLastName = lastName.trim();
    let randomThreeDigitNo = Math.floor(Math.random() * (999 - 100 + 1) + 100);

    let userName =
      finalFirstName.toLowerCase() +
      "_" +
      finalLastName.toLowerCase() +
      randomThreeDigitNo;
    let genPwd = generatePassword(firstName);

    if (firstName?.length === 0) {
      toast.warn("First name required!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else if (divisionId?.length === 0) {
      toast.warn("Please select a division!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      if (id) {
        try {
          setLoading(true);
          if (password !== "" && password.length < 6) {
            toast.warn("Password required!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            return;
          }
          let paramsObj = {
            firstName: firstName,
            lastName: lastName,
            cohortId: divisionId,
            username: teacherDetails?.userName,
          };

          if (password !== "") {
            paramsObj["password"] = password?.replace(/ /g, "");
          }

          if (emailId && emailId.length > 0) {
            if (validateEmail(emailId)) {
              paramsObj["email"] = emailId;
            }
          }

          if (contactNumber && contactNumber.length > 0) {
            paramsObj["contactNumber"] = contactNumber;
          }

          const res = await axios.put(
            `${EXPRESS_API_BASE_URL}/users/${id}/`,
            paramsObj,
            { headers: { Authorization: `Bearer ${token}` } }
          );

          // console.log("edit user res", res.data.data);
          setNavBlocker(false);
          setLoading(false);
          navigate("/users");
          toast.success(
            `${
              state && state.addTeacher ? "Teacher" : "Student"
            } has been updated!`,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            }
          );
        } catch (error) {
          setLoading(false);
          console.log("edit user error", error);
        }
      } else {
        try {
          setLoading(true);
          if (password === "" || (password !== "" && password.length < 6)) {
            toast.warn("Password required!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            return;
          }

          if (state && state.addTeacher) {
            const res = await axios.post(
              `${EXPRESS_API_BASE_URL}/users/create-teacher`,
              {
                firstName: firstName,
                lastName: lastName,
                email: emailId,
                contactNumber: contactNumber,
                username: userName?.replace(/ /g, ""),
                password: password?.replace(/ /g, ""),
                cohortId: divisionId,
                roleId: 2, // teacher
              },
              { headers: { Authorization: `Bearer ${token}` } }
            );

            toast.success(`Teacher added successfully!`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          } else {
            const res = await axios.post(
              `${EXPRESS_API_BASE_URL}/users/create-user`,
              {
                firstName: firstName,
                lastName: lastName,
                email: emailId,
                contactNumber: contactNumber,
                username: userName?.replace(/ /g, ""),
                password: password?.replace(/ /g, ""),
                cohortId: divisionId,
                roleId: 3, // student
              },
              { headers: { Authorization: `Bearer ${token}` } }
            );

            toast.success(`Student added successfully!`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          }

          // console.log("create user res", res.data.data);
          setNavBlocker(false);
          setLoading(false);
          navigate("/users");
        } catch (error) {
          setLoading(false);
          console.log("create user error", error);
        }
      }
    }
  };

  const saveGeneratedPass = () => {
    // console.log("saveGeneratedPass");
    let newPass = generatePassword(firstName);
    setPassword(newPass);
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="w-4/5 mobile:w-full ipad:w-full position-class pb-10">
          <div className="bg-secondary flex justify-between items-center p-5 stickyNavbar z-40">
            <p className="text-2xl font-semibold">
              {id
                ? state && state.addTeacher
                  ? "Edit Teacher"
                  : "Edit Student"
                : state && state.addTeacher
                ? "Add Teacher"
                : "Add Student"}
            </p>

            <FilledButton
              onClickFilled={(e) => onSaveTeacher(e)}
              filledBtnText="Save"
              loading={loading}
            />
          </div>

          <div className="w-3/4 ipad:w-full mobile:w-full my-5 ipad:mx-auto mobile:mx-auto">
            <div className="px-5">
              <div className="border border-primary rounded-md p-2 mt-6 relative">
                <div className="mt-1 w-full">
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-multiline-flexible"
                    label="First name *"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>

                <div className="mt-3 w-full">
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-multiline-flexible"
                    label="Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>

                <div className="mt-3 w-full">
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-multiline-flexible"
                    label="Email Id"
                    type="email"
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                  />
                </div>

                <div className="mt-3 w-full">
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-multiline-flexible"
                    label="Contact number"
                    type="number"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                  />
                </div>

                {/* <div className="mt-1">
                  <StyledForm autoComplete="off">
                    <StyledFormControl variant="outlined">
                      <InputLabel
                        id="outlined-age-simple-label"
                        sx={{
                          color: "#006b5c",
                        }}
                      >
                        Select School *
                      </InputLabel>
                      <StyledSelect
                        sx={{
                          color: "#006b5c",
                        }}
                        variant="outlined"
                        label="Select School *"
                        value={schoolId}
                        onChange={(e) => setSchoolId(e.target.value)}
                      >
                        {schools.map((option, index) => (
                          <MenuItem key={index} value={option?.id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </StyledFormControl>
                  </StyledForm>
                </div>

                <div className="mt-1">
                  <StyledForm autoComplete="off">
                    <StyledFormControl variant="outlined">
                      <InputLabel
                        id="outlined-age-simple-label"
                        sx={{
                          color: "#006b5c",
                        }}
                      >
                        Select Grade *
                      </InputLabel>
                      <StyledSelect
                        sx={{
                          color: "#006b5c",
                        }}
                        variant="outlined"
                        label="Select Grade *"
                        value={gradeId}
                        onChange={(e) => setGradeId(e.target.value)}
                      >
                        {grades.map((option, index) => (
                          <MenuItem key={index} value={option?.id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </StyledFormControl>
                  </StyledForm>
                </div> */}

                <div className="mt-1">
                  <StyledForm autoComplete="off">
                    <StyledFormControl variant="outlined">
                      <InputLabel
                        id="outlined-age-simple-label"
                        sx={{
                          color: "#006b5c",
                        }}
                      >
                        Select Division *
                      </InputLabel>
                      <StyledSelect
                        sx={{
                          color: "#006b5c",
                        }}
                        variant="outlined"
                        label="Select Division *"
                        value={divisionId}
                        onChange={(e) => setDivisionId(e.target.value)}
                      >
                        {divisions?.map((option, index) => (
                          <MenuItem key={index} value={option?.id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </StyledFormControl>
                  </StyledForm>
                </div>

                {id ? (
                  <></>
                ) : (
                  <div className="mt-3 w-full flex items-center gap-2">
                    <TextField
                      style={{ width: "90%" }}
                      id="outlined-multiline-flexible"
                      label={`${id ? "Password" : "Password*"}`}
                      type={inputType}
                      value={password}
                      InputProps={{
                        endAdornment:
                          password && password.length >= 1 ? (
                            <button
                              onClick={() =>
                                setVisible((visibilty) => !visibilty)
                              }
                            >
                              {visible ? (
                                <AiOutlineEyeInvisible className="text-2xl opacity-80" />
                              ) : (
                                <AiOutlineEye className="text-2xl opacity-80" />
                              )}
                            </button>
                          ) : null,
                      }}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <div>
                      <FilledButton
                        onClickFilled={saveGeneratedPass}
                        filledBtnText="Generate"
                        loading={false}
                      />
                    </div>
                  </div>
                )}

                {/* {id ? (
                  <div className="mt-1">
                    <StyledForm autoComplete="off">
                      <StyledFormControl variant="outlined">
                        <InputLabel
                          id="outlined-age-simple-label"
                          sx={{
                            color: "#006b5c",
                          }}
                        >
                          Select role
                        </InputLabel>
                        <StyledSelect
                          sx={{
                            color: "#006b5c",
                          }}
                          variant="outlined"
                          label="Select role"
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e.target.value)}
                        >
                          <MenuItem value={1}>ADMIN</MenuItem>
                          <MenuItem value={2}>TEACHER</MenuItem>
                          <MenuItem value={3}>STUDENT</MenuItem>
                        </StyledSelect>
                      </StyledFormControl>
                    </StyledForm>
                  </div>
                ) : (
                  <></>
                )} */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateTeacher;
