import React from "react";
// import { RiDeleteBin6Line } from "react-icons/ri";
import UnFilledButton from "./UnFilledButton";
import useWindowSize from "../constants/UseWindowSize";

const TeacherCard = ({
  firstName,
  lastName,
  username,
  email,
  password,
  isDelete,
  handleRemove,
  unFilledBtnText,
  onClickUnFilled,
  loading,
}) => {
  const { width, height } = useWindowSize();

  return (
    <div className="relative">
      {/* {isDelete === true ? (
        <button
          onClick={handleRemove}
          className="absolute -right-3 -top-3 border rounded-full p-1 bg-red-500 z-30"
        >
          <RiDeleteBin6Line className="text-white text-lg" />
        </button>
      ) : (
        <></>
      )} */}

      <div
        style={{ width: width < 500 ? "90%" : "450px" }}
        className="border rounded-md shadow-md px-3 h-64 bg-secondary overflow-auto mobile:mx-auto"
      >
        <div className="w-full">
          <div className="absolute top-2">
            {firstName ? (
              <div className="w-full">
                <p className="font-semibold text-lg pb-2 text-gray-800">
                  First name:{" "}
                  <span className="font-normal text-gray-600">{firstName}</span>
                </p>
              </div>
            ) : (
              <></>
            )}

            {lastName ? (
              <div className="mt-2 w-full">
                <p className="font-semibold text-lg pb-2 text-gray-800">
                  Last name:{" "}
                  <span className="font-normal text-gray-600">{lastName}</span>
                </p>
              </div>
            ) : (
              <></>
            )}

            {email ? (
              <div className="mt-2 w-full">
                <p className="font-semibold text-lg pb-2 text-gray-800">
                  Email:{" "}
                  <span className="font-normal text-gray-600">{email}</span>
                </p>
              </div>
            ) : (
              <></>
            )}

            {username ? (
              <div className="mt-2 w-full">
                <p className="font-semibold text-lg pb-2 text-gray-800">
                  User name:{" "}
                  <span className="font-normal text-gray-600">{username}</span>
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>

          {unFilledBtnText ? (
            <div className="absolute bottom-2 right-2 pt-2 mobile:right-6">
              <UnFilledButton
                unFilledBtnText={unFilledBtnText}
                onClickUnFilled={onClickUnFilled}
                loading={loading}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TeacherCard;
