import React, { useState, useEffect } from "react";
import { EXPRESS_API_BASE_URL } from "../constant";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import useWindowSize from "../constants/UseWindowSize";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Form, Formik } from "formik";
import { HiCheckCircle, HiXCircle } from "react-icons/hi";
import { BiCollapse, BiExpand } from "react-icons/bi";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import FilledButton from "../customs/FilledButton";
import MainLogo from "../assets/MainLogo.png";
import Loader from "../customs/Loader";
import AceEditor from "react-ace";
import { Controlled as ControlledEditor } from "react-codemirror2";
import CodeMirror from "@uiw/react-codemirror";
import { html } from "@codemirror/lang-html";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/keymap/sublime";
import "codemirror/addon/hint/anyword-hint";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/xml-hint";
import "codemirror/addon/hint/html-hint";
import "codemirror/addon/hint/css-hint";
import "codemirror/addon/hint/javascript-hint";
import "codemirror/addon/hint/sql-hint";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/edit/closetag";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/edit/matchtags";
import "codemirror/addon/edit/trailingspace";
import "codemirror/addon/edit/continuelist";
import "codemirror/addon/display/autorefresh";
import "codemirror/addon/selection/active-line";

const ViewAssignmentNotification = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const cookies = new Cookies();
  const { width, height } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [loadingForCEditor, setLoadingForCEditor] = useState(false);
  const [isTeacherLogin, setIsTeacherLogin] = useState(false);
  const [isAdminLogin, setIsAdminLogin] = useState(false);
  const [courseId, setCourseId] = useState(0);
  const [totalMarks, setTotalMarks] = useState(0);
  const [totalMarksGot, setTotalMarksGot] = useState(0);
  const [srcDoc, setSrcDoc] = useState("");
  const [openHtml, setOpenHtml] = useState(true);
  const [openCss, setOpenCss] = useState(true);
  const [openJS, setOpenJS] = useState(true);
  const [code, setCode] = useState("");
  const [output, setOutput] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [HTML, setHTML] = useState("");
  const [CSS, setCSS] = useState("");
  const [JS, setJS] = useState("");
  const [assignmentDetailsData, setAssignmentDetailsData] = useState(null);
  const [afterAnsAttemptArr, setAfterAnsAttemptArr] = useState([]);
  const [isIpadMobile, setIsIpadMobile] = useState(false);
  const [isAssignmentSubmitted, setIsAssignmentSubmitted] = useState(false);
  const [isNewAssignmentSubmitted, setIsNewAssignmentSubmitted] =
    useState(false);
  let token = cookies.get("token");

  useEffect(() => {
    if (width <= 1001) {
      setIsIpadMobile(true);
    } else {
      setIsIpadMobile(false);
    }
  }, [width]);

  // checking is User role
  const checkUserRole = () => {
    if (cookies.get("isTeacherLogin")) {
      setIsTeacherLogin(true);
      setIsAdminLogin(false);
    } else if (cookies.get("isAdminLogin")) {
      setIsTeacherLogin(false);
      setIsAdminLogin(true);
    }
  };

  useEffect(() => {
    checkUserRole();
  }, []);

  useEffect(() => {
    if (state === "ASSIGNMENT_EVALUATED") {
      setIsAssignmentSubmitted(true);
    } else {
      setIsAssignmentSubmitted(false);
    }
  }, [state]);

  // storing course Id in a state
  const getCourseIdByAPIcall = async () => {
    if (id) {
      try {
        let res = await axios.get(
          `${EXPRESS_API_BASE_URL}/assignment/${parseInt(id)}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (res) {
          // console.log("getCourseIdByAPIcallres", res);
          if (res.data?.data?.Coursees !== []) {
            setCourseId(parseInt(res.data?.data?.Coursees[0]?.CourseId));
          }
        }
      } catch (error) {
        console.log("set Course id API error", error);
      }
    }
  };

  useEffect(() => {
    getCourseIdByAPIcall();
  }, [id]);

  // run playground code on button click
  const handleRunHtmlCode = (e, HTML, CSS, JS) => {
    e.preventDefault();
    setTimeout(() => {
      setSrcDoc(`
        <html>
          <body>${HTML}</body>
          <style>${CSS}</style>
          <script>${JS}</script>
        </html>
      `);
    }, 250);
  };

  // run playground code auto-run
  useEffect(() => {
    if (isNewAssignmentSubmitted === true) {
      const timeout = setTimeout(() => {
        setSrcDoc(`
        <html>
          <body>${HTML}</body>
          <style>${CSS}</style>
          <script>${JS}</script>
        </html>
      `);
      }, 250);

      return () => clearTimeout(timeout);
    }
  }, [isNewAssignmentSubmitted, HTML, CSS, JS]);

  // to run java python code
  const onRunClick = (selectedLang, e, val) => {
    e.preventDefault();

    let data = {
      source_code: val ? val : code,
      language_id: selectedLang,
      number_of_runs: "1",
      stdin: "Judge0",
      expected_output: null,
      cpu_time_limit: "2",
      cpu_extra_time: "0.5",
      wall_time_limit: "5",
      memory_limit: "128000",
      stack_limit: "64000",
      max_processes_and_or_threads: "60",
      enable_per_process_and_thread_time_limit: false,
      enable_per_process_and_thread_memory_limit: false,
      max_file_size: "1024",
    };

    setLoadingForCEditor(true);
    fetch("https://judge0-ce.p.rapidapi.com/submissions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-RapidAPI-Key": "c37ff659c5msh66e338c0edab1dep141853jsn712c09bb286e",
        "X-RapidAPI-Host": "judge0-ce.p.rapidapi.com",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (resToken) => {
        // console.log(resToken);
        return new Promise((res, rej) => {
          setTimeout(async () => {
            const r = await fetch(
              "https://judge0-ce.p.rapidapi.com/submissions/" + resToken.token,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  "X-RapidAPI-Key":
                    "c37ff659c5msh66e338c0edab1dep141853jsn712c09bb286e",
                  "X-RapidAPI-Host": "judge0-ce.p.rapidapi.com",
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                },
              }
            );
            const response = await r.json();
            res(response);
          }, 5000);
        });
      })
      .then((res) => {
        if (res) {
          if (res.stdout === null) {
            setShowErrorMsg(true);

            if (res.stderr) {
              setErrorMsg(res.stderr);
            } else {
              setErrorMsg("Try later...");
            }
          } else {
            setShowErrorMsg(false);
            setOutput(res.stdout);
          }
        }

        // console.log("output res", res);
        setLoadingForCEditor(false);
      });
  };

  // screenshot of the whole page saved as pdf
  const getPdf = () => {
    html2canvas(document.querySelector("#root"), {
      allowTaint: false,
      useCORS: true,
      logging: true,
      windowWidth: "1280px",
      scrollY: -window.scrollY,
      onclone: (document) => {
        document.getElementById("print").style.visibility = "hidden";
      },
    })
      .then((canvas) => {
        setLoading(true);
        let wid;
        let hgt;
        const img = canvas.toDataURL(
          "image/png",
          (wid = canvas.width),
          (hgt = canvas.height)
        );
        const hratio = hgt / wid;
        const doc = new jsPDF("p", "mm", "a4");
        const width = doc.internal.pageSize.width;
        const height = width * hratio;

        doc.addImage(img, "PNG", 0, 0, width, height);
        doc.save(`AssignmentDetails${new Date().toISOString()}.pdf`);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("getpdf error", err);
      });
  };

  const onDownload = () => {
    setTimeout(() => {
      getPdf();
    }, 100);
  };

  // get assignment details to Prefill
  const getAssignmentDetailsData = async () => {
    try {
      setLoading(true);
      let newType = "NEW";
      let mainRes;

      if (courseId) {
        let newres1 = await axios.get(
          `${EXPRESS_API_BASE_URL}/course/${courseId}/assignment/${parseInt(
            id
          )}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        let submissionStatus = newres1?.data.data[0]?.submission[0]
          ?.submissionType
          ? newres1?.data.data[0]?.submission[0]?.submissionType
          : "NEW";

        // after submission
        if (
          submissionStatus === "DRAFT" ||
          submissionStatus === "SUBMITTED" ||
          submissionStatus === "REVIEWED"
        ) {
          // console.log("after submission", newres1.data?.data);
          setHTML(newres1?.data.data[0]?.submission[0]?.additionalData?.html);
          setCSS(newres1?.data.data[0]?.submission[0]?.additionalData?.css);
          setJS(newres1?.data.data[0]?.submission[0]?.additionalData?.js);
          setAssignmentDetailsData(newres1?.data.data[0]?.assignment);

          if (newres1?.data.data[0]?.submission?.length > 0) {
            newType = newres1?.data.data[0]?.submission[0].submissionType;

            if (newType !== "NEW") {
              mainRes = newres1?.data.data[0]?.submission;
            }

            // if (newType === "SUBMITTED" || newType === "REVIEWED") {
            //   setIsAssignmentSubmitted(true);
            // } else {
            //   setIsAssignmentSubmitted(false);
            // }

            if (
              newType === "SUBMITTED" ||
              newType === "REVIEWED" ||
              newType === "DRAFT"
            ) {
              setIsNewAssignmentSubmitted(true);
            } else {
              setIsNewAssignmentSubmitted(false);
            }
          }
        }
      } else {
        // before submission
        let newres2 = await axios.get(
          `${EXPRESS_API_BASE_URL}/assignment/${parseInt(id)}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (newres2) {
          // console.log("before submission", newres2.data?.data);
          setAssignmentDetailsData(newres2?.data?.data);
          if (newres2?.data.data?.Question?.length > 0) {
            mainRes = newres2?.data?.data?.Question;
          }
        }
      }

      if (mainRes) {
        let newArr = [];
        for (let index = 0; index < mainRes.length; index++) {
          const element = mainRes[index];

          newArr.push({
            answerFormat:
              newType === "NEW"
                ? element?.answerFormat
                : element?.question?.answerFormat,
            assignmentId:
              newType === "NEW"
                ? element?.assignmentId
                : element?.question?.assignmentId,
            maxScore:
              newType === "NEW"
                ? element?.maxScore
                : element?.question?.maxScore,
            text: newType === "NEW" ? element?.text : element?.question?.text,
            type: newType === "NEW" ? element?.type : element?.question?.type,
            correctOption:
              newType === "NEW"
                ? element?.correctOption
                : element?.question?.correctOption,
            options:
              newType === "NEW" ? element?.options : element?.question?.options,
            optionTypes:
              newType === "NEW"
                ? element?.optionTypes
                : element?.question?.optionTypes,
            comments: newType === "NEW" ? "" : element?.comments,
            score: newType === "NEW" ? "" : element?.score,
            submissionType: newType === "NEW" ? "" : element?.submissionType,
            question: {
              queId: newType === "NEW" ? element?.id : element?.question?.id,
              ans: {
                checkedAns: newType === "NEW" ? [] : element?.answer,
                pickedAns: newType === "NEW" ? "" : element?.answer,
                textAns: newType === "NEW" ? "" : element?.answer,
                codeAns: newType === "NEW" ? "" : element?.answer,
                htmlAns: newType === "NEW" ? "" : element?.answer,
              },
              additionalData: {
                html: newType === "NEW" ? "" : element?.additionalData?.html,
                css: newType === "NEW" ? "" : element?.additionalData?.css,
                js: newType === "NEW" ? "" : element?.additionalData?.js,
              },
            },
          });
        }

        setAfterAnsAttemptArr(newArr);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("get my AssignmentDetails error", error);
    }
  };

  useEffect(() => {
    getAssignmentDetailsData();
  }, [courseId]);

  // calculation of, total of scored marks and total out of marks
  useEffect(() => {
    // console.log("afterAnsAttemptArr", afterAnsAttemptArr);

    if (afterAnsAttemptArr) {
      if (
        afterAnsAttemptArr[0]?.submissionType === "REVIEWED" &&
        (afterAnsAttemptArr[0]?.score !== "" ||
          afterAnsAttemptArr[0]?.score !== 0)
      ) {
        // total of scored marks
        let newArr = afterAnsAttemptArr.map((s) => {
          return parseInt(s?.score);
        });
        const sum = newArr.reduce((v, a) => v + a, 0);
        setTotalMarksGot(sum);

        // total out of marks
        let newTMArr = afterAnsAttemptArr.map((s) => {
          return parseInt(s?.maxScore);
        });
        const TM = newTMArr.reduce((v, a) => v + a, 0);
        setTotalMarks(TM);
      }
    }
  }, [afterAnsAttemptArr]);

  // check is base64 value is valid or not
  function validBase64Image(base64Image) {
    // console.log("validBase64Image base64Image", base64Image);
    const regex =
      /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/;
    return base64Image && regex.test(base64Image);
  }

  // console.log("afterAnsAttemptArr", afterAnsAttemptArr);

  return (
    <div className="w-full">
      {loading === true ? (
        <Loader />
      ) : (
        <Formik
          initialValues={{
            mainAns: afterAnsAttemptArr,
          }}
          enableReinitialize
          onSubmit={async (values) => {}}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="w-full mx-auto flex justify-center items-center pb-10 preventPageCopyPaste">
                <div className={`w-11/12`}>
                  <div className="bg-secondary pt-32 laptop:pt-28 ipad:pt-36 mobile:pt-28 w-full">
                    <div className="w-full flex mobile:flex-col">
                      {isTeacherLogin || isAdminLogin || !courseId ? (
                        <></>
                      ) : (
                        <div
                          className="w-full flex justify-end items-center gap-5 mobile:justify-center"
                          style={{
                            position: "relative",
                            left: width < 500 ? "62%" : "38%",
                            width: width < 500 ? "40%" : "60%",
                            top: 0,
                          }}
                        >
                          {isAssignmentSubmitted === true ? (
                            <FilledButton
                              onClickFilled={onDownload}
                              filledBtnText="Save as pdf"
                              loading={loading}
                              id="print"
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </div>

                    <p className="w-full text-3xl font-bold text-center mobile:text-2xl break-words mobile:mt-10">
                      {assignmentDetailsData?.title}
                    </p>

                    <div className="w-1/2 ipad:w-full mobile:w-full mx-auto">
                      <div className="w-full h-full flex justify-center items-center my-5">
                        <img
                          src={
                            assignmentDetailsData &&
                            assignmentDetailsData?.featureImage
                              ? assignmentDetailsData?.featureImage
                              : MainLogo
                          }
                          alt="assignmentImg"
                          style={{ width: isIpadMobile ? "90%" : "70%" }}
                        />
                      </div>
                    </div>
                  </div>

                  {isAssignmentSubmitted === true &&
                  totalMarksGot &&
                  totalMarksGot !== 0 ? (
                    <div className="w-4/5 laptop:w-full ipad:w-full mobile:w-full mx-auto my-10 relative">
                      <p className="text-2xl font-semibold absolute right-0">
                        Marks : {totalMarksGot} / {totalMarks}
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}

                  {assignmentDetailsData ? (
                    <div className="flex justify-center items-center mt-5">
                      <div className="mt-3 w-4/5 laptop:w-full ipad:w-full mobile:w-full">
                        <p className="font-semibold my-2 flex justify-start">
                          Assignment description:
                        </p>
                        <div
                          className="w-full my-4 text-lg"
                          dangerouslySetInnerHTML={{
                            __html: assignmentDetailsData?.description,
                          }}
                        ></div>

                        <p className="text-3xl font-semibold mt-3 mb-2 mobile:text-2xl flex justify-start">
                          Questions:
                        </p>
                      </div>
                    </div>
                  ) : (
                    <p
                      style={{
                        position: "absolute",
                        left: width < 500 ? "35%" : "42%",
                      }}
                      className="font-semibold text-xl py-10"
                    >
                      No data found!
                    </p>
                  )}

                  <div className="flex justify-center items-center">
                    <div className="w-full">
                      {values?.mainAns?.map((val, mainindex) => {
                        return (
                          <div className="" key={mainindex}>
                            <div className="w-full flex justify-center items-center">
                              <div className="w-4/5 laptop:w-full ipad:w-full mobile:w-full ">
                                {val?.type === "MCQ" ? (
                                  <div className="mt-4">
                                    {/* <p className="text-2xl font-semibold my-2 mobile:text-lg">
                                      {`Q.${mainindex + 1} ${val?.text}`}
                                    </p> */}

                                    <div className="w-full my-3 flex items-center ">
                                      <span className="text-2xl font-semibold mr-2 mobile:text-lg">{`Q.${
                                        mainindex + 1
                                      }`}</span>

                                      <div
                                        className="w-full text-2xl font-semibold mobile:text-lg"
                                        dangerouslySetInnerHTML={{
                                          __html: val?.text,
                                        }}
                                      ></div>
                                    </div>

                                    {isAssignmentSubmitted === true ? (
                                      <div className="flex justify-between items-center w-full mb-2">
                                        <p className="mt-3 text-xl font-semibold mobile:text-base">
                                          Options:
                                        </p>

                                        <p className="mt-3 text-xl font-semibold mobile:text-base px-1 rounded-sm bg-green-200 ">
                                          Marks: {val?.score}/{val?.maxScore}
                                        </p>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {isAdminLogin || isTeacherLogin ? (
                                      <div>
                                        <p className="w-full mt-3 text-xl font-semibold mobile:text-base">
                                          Correct Option:
                                        </p>
                                        <div className="border rounded-lg p-1 my-1">
                                          {validBase64Image(
                                            val?.correctOption
                                          ) ||
                                          val?.correctOption?.includes(
                                            "code-squad-scratch.s3.ap-south-1.amazonaws.com"
                                          ) ? (
                                            <img
                                              className="border"
                                              src={
                                                validBase64Image(
                                                  val?.correctOption
                                                )
                                                  ? val?.correctOption.includes(
                                                      "data:image/png;base64,"
                                                    )
                                                    ? val?.correctOption
                                                    : `data:image/png;base64,${val?.correctOption}`
                                                  : val?.correctOption
                                              }
                                              alt={"uploaded option"}
                                              height="200px"
                                              width={"200px"}
                                            />
                                          ) : (
                                            <p className="w-full text-xl mobile:text-base">
                                              {val?.correctOption}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    <div className="border rounded-lg p-1 mb-4">
                                      <RadioGroup
                                        value={val?.question?.ans?.pickedAns}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `mainAns[${mainindex}].question.ans.pickedAns`,
                                            e.target.value
                                          );
                                        }}
                                        name="picked"
                                      >
                                        {val?.options?.map((v, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="flex items-center"
                                            >
                                              <FormControlLabel
                                                control={
                                                  <Radio
                                                    disabled={
                                                      isAssignmentSubmitted ===
                                                        true ||
                                                      isAdminLogin ||
                                                      isTeacherLogin
                                                        ? true
                                                        : false
                                                    }
                                                    sx={{
                                                      "&, &.Mui-checked": {
                                                        color: "#006b5c",
                                                      },
                                                      color: "#006b5c",
                                                    }}
                                                    value={v}
                                                  />
                                                }
                                                label={
                                                  val?.optionTypes[index] ===
                                                  "text" ? (
                                                    v
                                                  ) : (
                                                    <img
                                                      className="border mt-2"
                                                      src={v}
                                                      alt={"uploaded option"}
                                                      height="200px"
                                                      width={"200px"}
                                                    />
                                                  )
                                                }
                                              />

                                              {isAssignmentSubmitted ===
                                              true ? (
                                                <div>
                                                  {v ==
                                                  val?.question?.ans
                                                    ?.pickedAns ? (
                                                    val?.question?.ans
                                                      ?.pickedAns ==
                                                    val?.correctOption ? (
                                                      <HiCheckCircle className="text-2xl opacity-80 text-green-600" />
                                                    ) : (
                                                      <HiXCircle className="text-2xl opacity-80 text-red-600" />
                                                    )
                                                  ) : (
                                                    ""
                                                  )}

                                                  {val?.question?.ans
                                                    ?.pickedAns !=
                                                  val?.correctOption ? (
                                                    v == val?.correctOption ? (
                                                      <HiCheckCircle className="text-2xl opacity-80 text-green-600" />
                                                    ) : (
                                                      ""
                                                    )
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          );
                                        })}
                                      </RadioGroup>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>

                            <div className="w-full flex justify-center items-center">
                              <div className="w-4/5 laptop:w-full ipad:w-full mobile:w-full">
                                {val?.type === "CHECKBOX" ? (
                                  <div>
                                    <div className="w-full my-3 flex items-center">
                                      <span className="text-2xl font-semibold mr-2 mobile:text-lg">{`Q.${
                                        mainindex + 1
                                      }`}</span>

                                      <div
                                        className="w-full text-2xl font-semibold mobile:text-lg"
                                        dangerouslySetInnerHTML={{
                                          __html: val?.text,
                                        }}
                                      ></div>
                                    </div>

                                    {isAssignmentSubmitted === true ? (
                                      <div className="flex justify-between items-center w-full mb-2">
                                        <p className="mt-3 text-xl font-semibold mobile:text-base">
                                          Options:
                                        </p>

                                        <p className="mt-3 text-xl font-semibold mobile:text-base px-1 rounded-sm bg-green-200 ">
                                          Marks: {val?.score}/{val?.maxScore}
                                        </p>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {isAdminLogin || isTeacherLogin ? (
                                      <div>
                                        <p className="w-full mt-3 text-xl font-semibold mobile:text-base">
                                          Correct Options:
                                        </p>
                                        <div className="border rounded-lg p-1 my-1">
                                          {val?.correctOption &&
                                            (typeof val?.correctOption ===
                                            "string"
                                              ? val?.correctOption?.split(",")
                                              : val?.correctOption
                                            )?.map((s, idn) => {
                                              return (
                                                <div
                                                  key={idn}
                                                  className="my-4 flex items-center gap-2 overflow-auto"
                                                >
                                                  <p>{idn + 1}.</p>
                                                  <div className="flex flex-col">
                                                    {validBase64Image(s) ||
                                                    s?.includes(
                                                      "code-squad-scratch.s3.ap-south-1.amazonaws.com"
                                                    ) ? (
                                                      <img
                                                        className="border"
                                                        src={
                                                          validBase64Image(s)
                                                            ? s.includes(
                                                                "data:image/png;base64,"
                                                              )
                                                              ? s
                                                              : `data:image/png;base64,${s}`
                                                            : s
                                                        }
                                                        alt={"uploaded option"}
                                                        height="200px"
                                                        width={"200px"}
                                                      />
                                                    ) : (
                                                      <p className="w-full text-xl mobile:text-base">
                                                        {s}
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {/* <p className="w-full mt-4 mb-2 text-xl font-semibold mobile:text-base">
                                      Options:
                                    </p> */}
                                    <div className="border rounded-lg p-1 mb-4">
                                      <FormGroup>
                                        {val?.options?.map((v, i) => {
                                          return (
                                            <div
                                              key={i}
                                              className="flex items-center"
                                            >
                                              <FormControlLabel
                                                label={
                                                  val?.optionTypes[i] ===
                                                  "text" ? (
                                                    v
                                                  ) : (
                                                    <img
                                                      className="border mt-2"
                                                      src={v}
                                                      alt={"uploaded option"}
                                                      height="200px"
                                                      width={"200px"}
                                                    />
                                                  )
                                                }
                                                control={
                                                  <Checkbox
                                                    disabled={
                                                      isAssignmentSubmitted ===
                                                        true ||
                                                      isAdminLogin ||
                                                      isTeacherLogin
                                                        ? true
                                                        : false
                                                    }
                                                    sx={{
                                                      color: "#006b5c",
                                                      "&.Mui-checked": {
                                                        color: "#006b5c",
                                                      },
                                                    }}
                                                    checked={val?.question?.ans?.checkedAns.includes(
                                                      v
                                                    )}
                                                    name="checked"
                                                    value={v}
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `mainAns[${mainindex}].question.ans.checkedAns[${i}]`,
                                                        e.target.checked ===
                                                          true
                                                          ? v
                                                          : false
                                                      );
                                                    }}
                                                  />
                                                }
                                              />

                                              {isAssignmentSubmitted ===
                                              true ? (
                                                <div>
                                                  {v ==
                                                  val?.question?.ans
                                                    ?.checkedAns ? (
                                                    val?.question?.ans
                                                      ?.checkedAns ==
                                                    val?.correctOption ? (
                                                      <HiCheckCircle className="text-2xl opacity-80 text-green-600" />
                                                    ) : (
                                                      <HiXCircle className="text-2xl opacity-80 text-red-600" />
                                                    )
                                                  ) : (
                                                    ""
                                                  )}

                                                  {val?.question?.ans
                                                    ?.checkedAns !=
                                                  val?.correctOption ? (
                                                    v == val?.correctOption ? (
                                                      <HiCheckCircle className="text-2xl opacity-80 text-green-600" />
                                                    ) : (
                                                      ""
                                                    )
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          );
                                        })}
                                      </FormGroup>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>

                            <div className="w-full flex justify-center items-center ">
                              <div className="w-4/5 laptop:w-full ipad:w-full mobile:w-full">
                                {val?.type === "TEXT" ? (
                                  <div>
                                    <div className="w-full my-3 flex items-center">
                                      <span className="text-2xl font-semibold mr-2 mobile:text-lg">{`Q.${
                                        mainindex + 1
                                      }`}</span>

                                      <div
                                        className="w-full text-2xl font-semibold mobile:text-lg"
                                        dangerouslySetInnerHTML={{
                                          __html: val?.text,
                                        }}
                                      ></div>
                                    </div>

                                    {isAssignmentSubmitted === true &&
                                    val?.score ? (
                                      <div className="teachers-remarks">
                                        <div className="text-lg">
                                          Teacher's Remarks
                                        </div>
                                        <div className="my-4">
                                          <TextField
                                            disabled={true}
                                            style={{
                                              width: "100%",
                                            }}
                                            id="outlined-multiline-flexible"
                                            label="Comment"
                                            value={val?.comments}
                                          />
                                        </div>

                                        <div className="">
                                          <TextField
                                            disabled={true}
                                            style={{
                                              width: "100%",
                                            }}
                                            id="outlined-multiline-flexible"
                                            label={`Marks out of ${val?.maxScore}`}
                                            type="number"
                                            value={val?.score}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    <div className="mt-4">
                                      <TextField
                                        disabled={
                                          isAssignmentSubmitted === true ||
                                          isAdminLogin ||
                                          isTeacherLogin
                                            ? true
                                            : false
                                        }
                                        style={{
                                          width: "100%",
                                        }}
                                        id="outlined-multiline-flexible"
                                        label="Answer"
                                        multiline
                                        rows={4}
                                        value={
                                          isAssignmentSubmitted
                                            ? val?.question?.ans?.textAns
                                            : ""
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `mainAns[${mainindex}].question.ans.textAns`,
                                            e.target.value
                                          );
                                        }}
                                        onCut={(e) => {
                                          e.preventDefault();
                                        }}
                                        onCopy={(e) => {
                                          e.preventDefault();
                                        }}
                                        onPaste={(e) => {
                                          e.preventDefault();
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>

                            <div>
                              {val?.answerFormat !== "HTML" &&
                              val?.type === "CODE" &&
                              val?.answerFormat !== "SCRATCH" ? (
                                <div className="w-full">
                                  <div className="flex justify-center items-center">
                                    <div className="w-4/5 laptop:w-full ipad:w-full mobile:w-full">
                                      <div className="w-full my-3 flex items-center">
                                        <span className="text-2xl font-semibold mr-2 mobile:text-lg">{`Q.${
                                          mainindex + 1
                                        }`}</span>

                                        <div
                                          className="w-full text-2xl font-semibold mobile:text-lg"
                                          dangerouslySetInnerHTML={{
                                            __html: val?.text,
                                          }}
                                        ></div>
                                      </div>

                                      {isAssignmentSubmitted === true &&
                                      val?.score ? (
                                        <div className="teachers-remarks">
                                          <div className="text-lg">
                                            Teacher's Remarks
                                          </div>
                                          <div className="my-4">
                                            <TextField
                                              disabled={true}
                                              style={{
                                                width: "100%",
                                              }}
                                              id="outlined-multiline-flexible"
                                              label="Comment"
                                              value={val?.comments}
                                            />
                                          </div>

                                          <div className="">
                                            <TextField
                                              disabled={true}
                                              style={{
                                                width: "100%",
                                              }}
                                              id="outlined-multiline-flexible"
                                              label={`Marks out of ${val?.maxScore}`}
                                              type="number"
                                              value={val?.score}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>

                                  <div className="w-full flex justify-center items-center mt-4">
                                    <div className="w-4/5 laptop:w-full ipad:w-full mobile:w-full">
                                      <div className="flex flex-row ipad:flex-col mobile:flex-col">
                                        <div className="h-450 w-1/2 ipad:w-full mobile:w-full ipad:mb-12 mobile:mb-12">
                                          <div className="controls">
                                            {val?.question?.ans?.codeAns
                                              ?.length > 0 ? (
                                              <button
                                                onClick={(e) =>
                                                  onRunClick(
                                                    val?.answerFormat ===
                                                      "PYTHON"
                                                      ? 71
                                                      : 62,
                                                    e,
                                                    val?.question?.ans?.codeAns
                                                  )
                                                }
                                                className="bg-gray-700 text-white px-2 rounded-md uppercase"
                                              >
                                                RUN
                                              </button>
                                            ) : (
                                              <></>
                                            )}
                                          </div>

                                          <AceEditor
                                            placeholder="write your code"
                                            // mode="html"
                                            theme="github"
                                            name="blah2"
                                            width="100%"
                                            height="100%"
                                            readOnly={
                                              isAssignmentSubmitted === true ||
                                              isAdminLogin ||
                                              isTeacherLogin
                                                ? true
                                                : false
                                            }
                                            // onLoad={onLoad}
                                            onChange={(e) => {
                                              setFieldValue(
                                                `mainAns[${mainindex}].question.ans.codeAns`,
                                                e
                                              );
                                              setCode(e);
                                            }}
                                            fontSize={16}
                                            showPrintMargin={true}
                                            showGutter={true}
                                            highlightActiveLine={true}
                                            value={
                                              isAssignmentSubmitted
                                                ? val?.question?.ans?.codeAns
                                                : ""
                                            }
                                            setOptions={{
                                              enableBasicAutocompletion: true,
                                              enableLiveAutocompletion: true,
                                              enableSnippets: true,
                                              showLineNumbers: true,
                                              tabSize: 2,
                                            }}
                                          />
                                        </div>

                                        <div className="border output h-480 w-1/2 ipad:w-full mobile:w-full">
                                          <p className="text-xl border-b border-gray-300 py-1">
                                            Output:
                                          </p>

                                          <div className="">
                                            {loadingForCEditor === true ? (
                                              <div className="pt-5 flex justify-center items-center">
                                                <span className="text-primary font-semibold text-2xl z-10">
                                                  Loading...
                                                </span>
                                              </div>
                                            ) : showErrorMsg === true ? (
                                              <span className="text-red-600">
                                                {errorMsg}
                                              </span>
                                            ) : (
                                              <span className="">{output}</span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="w-full flex justify-center items-center">
                              {val?.answerFormat === "HTML" &&
                              val?.type === "CODE" &&
                              val?.answerFormat !== "SCRATCH" ? (
                                <div className="w-4/5 laptop:w-full ipad:w-full mobile:w-full">
                                  <div className="flex justify-center items-center">
                                    <div className="w-full">
                                      <div className="w-full my-3 flex items-center">
                                        <span className="text-2xl font-semibold mr-2 mobile:text-lg">{`Q.${
                                          mainindex + 1
                                        }`}</span>

                                        <div
                                          className="w-full text-2xl font-semibold mobile:text-lg"
                                          dangerouslySetInnerHTML={{
                                            __html: val?.text,
                                          }}
                                        ></div>
                                      </div>

                                      {isAssignmentSubmitted === true &&
                                      val?.score ? (
                                        <div className="teachers-remarks">
                                          <div className="text-lg">
                                            Teacher's Remarks
                                          </div>
                                          <div className="my-4">
                                            <TextField
                                              disabled={true}
                                              style={{
                                                width: "100%",
                                              }}
                                              id="outlined-multiline-flexible"
                                              label="Comment"
                                              value={val?.comments}
                                            />
                                          </div>

                                          <div className="">
                                            <TextField
                                              disabled={true}
                                              style={{
                                                width: "100%",
                                              }}
                                              id="outlined-multiline-flexible"
                                              label={`Marks out of ${val?.maxScore}`}
                                              type="number"
                                              value={val?.score}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>

                                  <div className="w-full flex justify-center mt-5 ipad:flex-col mobile:flex-col">
                                    <div
                                      style={{ width: "50%" }}
                                      className="pane flex flex-col top-pane w-11/12 ipad:w-full mobile:w-full mx-auto"
                                    >
                                      <div
                                        className={`editor-container ${
                                          openHtml ? "" : "collapsed"
                                        }`}
                                      >
                                        <div className={`editor-title `}>
                                          HTML
                                          <button
                                            type="button"
                                            className="expand-collapse-btn mobile:hidden"
                                            onClick={() =>
                                              setOpenHtml(
                                                (prevOpen) => !prevOpen
                                              )
                                            }
                                          >
                                            {openHtml ? (
                                              <BiCollapse />
                                            ) : (
                                              <BiExpand />
                                            )}
                                          </button>
                                        </div>

                                        <CodeMirror
                                          height="200px"
                                          onChange={(value, vu) => {
                                            setFieldValue(
                                              `mainAns[${mainindex}].question.additionalData.html`,
                                              value
                                            );
                                            setFieldValue(
                                              `mainAns[${mainindex}].question.ans.htmlAns`,
                                              "HTML"
                                            );
                                          }}
                                          value={
                                            isAssignmentSubmitted
                                              ? val?.question?.additionalData
                                                  ?.html
                                              : ""
                                          }
                                          className="code-mirror-wrapper"
                                          extensions={[
                                            html({
                                              autoCloseTags: true,
                                            }),
                                          ]}
                                        />
                                      </div>

                                      <div
                                        className={`editor-container ${
                                          openCss ? "" : "collapsed"
                                        }`}
                                      >
                                        <div className="editor-title">
                                          CSS
                                          <button
                                            type="button"
                                            className="expand-collapse-btn mobile:hidden"
                                            onClick={() =>
                                              setOpenCss(
                                                (prevOpen) => !prevOpen
                                              )
                                            }
                                          >
                                            {openCss ? (
                                              <BiCollapse />
                                            ) : (
                                              <BiExpand />
                                            )}
                                          </button>
                                        </div>

                                        <ControlledEditor
                                          value={
                                            isAssignmentSubmitted
                                              ? val?.question?.additionalData
                                                  ?.css
                                              : ""
                                          }
                                          onBeforeChange={(
                                            editor,
                                            data,
                                            value
                                          ) => {
                                            setFieldValue(
                                              `mainAns[${mainindex}].question.additionalData.css`,
                                              value
                                            );
                                            setFieldValue(
                                              `mainAns[${mainindex}].question.ans.htmlAns`,
                                              "HTML"
                                            );
                                            // setCSS(value);
                                          }}
                                          className="code-mirror-wrapper"
                                          options={{
                                            readOnly:
                                              isAssignmentSubmitted === true ||
                                              isAdminLogin ||
                                              isTeacherLogin
                                                ? true
                                                : false,
                                            lint: true,
                                            mode: "css",
                                            theme: "material",
                                            lineNumbers: true,
                                            lineWrapping: true,
                                            smartIndent: true,
                                            autoCloseTags: true,
                                            keyMap: "sublime",
                                            matchTags: true,
                                            matchBrackets: true,
                                            autoCloseBrackets: true,
                                            extraKeys: {
                                              "Ctrl-Space": "autocomplete",
                                            },
                                          }}
                                        />
                                      </div>

                                      <div
                                        className={`editor-container ${
                                          openJS ? "" : "collapsed"
                                        }`}
                                      >
                                        <div className="editor-title">
                                          JS
                                          <button
                                            type="button"
                                            className="expand-collapse-btn mobile:hidden"
                                            onClick={() =>
                                              setOpenJS((prevOpen) => !prevOpen)
                                            }
                                          >
                                            {openJS ? (
                                              <BiCollapse />
                                            ) : (
                                              <BiExpand />
                                            )}
                                          </button>
                                        </div>

                                        <ControlledEditor
                                          value={
                                            isAssignmentSubmitted
                                              ? val?.question?.additionalData
                                                  ?.js
                                              : ""
                                          }
                                          onBeforeChange={(
                                            editor,
                                            data,
                                            value
                                          ) => {
                                            setFieldValue(
                                              `mainAns[${mainindex}].question.additionalData.js`,
                                              value
                                            );
                                            setFieldValue(
                                              `mainAns[${mainindex}].question.ans.htmlAns`,
                                              "HTML"
                                            );
                                            // setJS(value);
                                          }}
                                          className="code-mirror-wrapper"
                                          options={{
                                            readOnly:
                                              isAssignmentSubmitted === true ||
                                              isAdminLogin ||
                                              isTeacherLogin
                                                ? true
                                                : false,
                                            lint: true,
                                            mode: "javascript",
                                            theme: "material",
                                            lineNumbers: true,
                                            lineWrapping: true,
                                            smartIndent: true,
                                            autoCloseTags: true,
                                            keyMap: "sublime",
                                            matchTags: true,
                                            matchBrackets: true,
                                            autoCloseBrackets: true,
                                            extraKeys: {
                                              "Ctrl-Space": "autocomplete",
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div
                                      style={{ width: "50%" }}
                                      className="pane border-2 border-primary w-11/12 ipad:w-full mobile:w-full mx-auto"
                                    >
                                      <div className="flex items-center p-1 border-b bg-secondary">
                                        {val?.question?.additionalData?.html
                                          ?.length > 0 ? (
                                          <button
                                            onClick={(e) =>
                                              handleRunHtmlCode(
                                                e,
                                                val?.question?.additionalData
                                                  ?.html,
                                                val?.question?.additionalData
                                                  ?.css,
                                                val?.question?.additionalData
                                                  ?.js
                                              )
                                            }
                                            className={`font-semibold bg-primary px-2 text-white rounded-md h-8`}
                                          >
                                            RUN
                                          </button>
                                        ) : (
                                          <></>
                                        )}

                                        <p className="text-center text-2xl font-semibold w-full">
                                          Output:
                                        </p>
                                      </div>

                                      <iframe
                                        className="pb-5"
                                        srcDoc={srcDoc}
                                        title="output"
                                        sandbox="allow-scripts"
                                        frameBorder="0"
                                        width="100%"
                                        height="100%"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="flex justify-center items-center h-full">
                              {val?.type === "FILE" ||
                              (val?.type === "CODE" &&
                                val?.answerFormat === "SCRATCH") ? (
                                <div className="w-full h-full">
                                  <div className="flex justify-center items-center">
                                    <div className="w-4/5 laptop:w-full ipad:w-full mobile:w-full">
                                      <div className="w-full mt-8 mb-5 flex items-center">
                                        <span className="text-2xl font-semibold mr-2 mobile:text-lg">{`Q.${
                                          mainindex + 1
                                        }`}</span>

                                        <div
                                          className="w-full text-2xl font-semibold mobile:text-lg"
                                          dangerouslySetInnerHTML={{
                                            __html: val?.text,
                                          }}
                                        ></div>
                                      </div>

                                      {isAssignmentSubmitted === true &&
                                      val?.score ? (
                                        <div className="teachers-remarks">
                                          <div className="text-lg">
                                            Teacher's Remarks
                                          </div>
                                          <div className="my-4">
                                            <TextField
                                              disabled={true}
                                              style={{
                                                width: "100%",
                                              }}
                                              id="outlined-multiline-flexible"
                                              label="Comment"
                                              value={val?.comments}
                                            />
                                          </div>

                                          <div className="">
                                            <TextField
                                              disabled={true}
                                              style={{
                                                width: "100%",
                                              }}
                                              id="outlined-multiline-flexible"
                                              label={`Marks out of ${val?.maxScore}`}
                                              type="number"
                                              value={val?.score}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>

                                  <div className="w-full h-full flex justify-center items-center mb-10">
                                    <div className="w-4/5 laptop:w-full ipad:w-full mobile:w-full flex justify-center items-center h-full">
                                      <iframe
                                        src={`${EXPRESS_API_BASE_URL}/scratch/`}
                                        style={{
                                          width: "100%",
                                          height: "700px",
                                        }}
                                        title="Scratch Iframe"
                                      ></iframe>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ViewAssignmentNotification;
