import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Loader from "../customs/Loader";
import { EXPRESS_API_BASE_URL } from "../constant";
import EnrolledCoursesCard from "../customs/EnrolledCoursesCard";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../constants/UseWindowSize";

const HomePageStudent = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [updatesArr, setUpdatesArr] = useState([]);
  let token = cookies.get("token");

  // check user logged in or not
  const checkUserLogin = () => {
    if (!cookies.get("token")) {
      navigate("/login");
    }
  };

  useEffect(() => {
    checkUserLogin();
  }, []);

  const getCoursesData = async () => {
    let token = cookies.get("token");
    try {
      setLoading(true);
      const res = await axios.get(`${EXPRESS_API_BASE_URL}/course/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // console.log("courses res", res.data.data);
      setEnrolledCourses(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("get my courses error", error);
    }
  };

  useEffect(() => {
    getCoursesData();
  }, []);

  // get Dashboard Updates
  const getDashboardUpdates = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${EXPRESS_API_BASE_URL}/dashboard/student-updates`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // console.log("getDashboardUpdates res", res.data.data);
      setUpdatesArr(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("getDashboardUpdates error", error);
    }
  };

  useEffect(() => {
    getDashboardUpdates();

    setInterval(() => {
      getDashboardUpdates();
    }, 10000);
  }, []);

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="w-4/5 mobile:w-full ipad:w-full position-class pb-10">
          <div className="w-full flex justify-center items-center">
            <div className="w-full">
              <div className="bg-secondary flex justify-between items-center p-5 stickyNavbar z-40">
                <p className="text-3xl font-semibold">Course Updates</p>
              </div>

              <div className="px-5">
                <div className="">
                  <div className="flex justify-between items-center my-5">
                    <p className="text-2xl font-semibold">Updates</p>
                  </div>

                  <div className="w-full h-48 bg-secondary overflow-y-auto border rounded-lg shadow-md p-1">
                    {updatesArr?.length === 0 ? (
                      <p className="text-lg p-2">No updates found!</p>
                    ) : (
                      <ul>
                        {updatesArr?.map((val, index) => {
                          return (
                            <li key={index}>
                              <p
                                className="font-bold cursor-pointer"
                                onClick={() =>
                                  val?.entityType === "PROJECT"
                                    ? navigate(
                                        `/notification/view/project/${val?.entityId}`
                                      )
                                    : val?.entityType === "ASSIGNMENT"
                                    ? navigate(
                                        `/notification/view/assignment/${val?.entityId}`
                                      )
                                    : ""
                                }
                              >
                                {val?.text}
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>

                <div className="mt-5">
                  <div className="flex justify-between items-center my-3">
                    <p className="text-2xl font-semibold">Enrolled Courses</p>
                  </div>

                  <div className="flex mobile:justify-center">
                    {enrolledCourses?.length === 0 ? (
                      <p
                        style={{
                          position: "absolute",
                          left: width < 500 ? "35%" : "40%",
                        }}
                        className="font-semibold text-xl pt-20"
                      >
                        No courses found!
                      </p>
                    ) : (
                      <div className="flex flex-wrap gap-5 ipad:justify-center mobile:justify-center mobile:w-full mobile:mx-auto">
                        {enrolledCourses?.map((newVal, index) => {
                          return (
                            <div key={index}>
                              <EnrolledCoursesCard
                                courseImg={newVal?.imageUrl}
                                Title={`${newVal?.name}`}
                                Desc={`${newVal?.CourseCode}`}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePageStudent;
