import React from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  FormControl,
  InputLabel,
  MenuItem,
  outlinedInputClasses,
  Select,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { usePrompt } from "../constants/UsePrompt";
import { EXPRESS_API_BASE_URL } from "../constant";
import { toast } from "react-toastify";
import FilledButton from "../customs/FilledButton";

// changing border colors for dropdown
const StyledForm = styled("form")(`
  display: flex;
  flex-wrap: wrap;
`);
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: 10,
  width: "100%",
}));
const StyledSelect = styled(Select)(`
  & .${outlinedInputClasses.notchedOutline} {
    border-color: "#006b5c";
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: "#006b5c";
  }
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: "#006b5c";
  }
`);

const CreateGrade = () => {
  const { schoolId, id } = useParams();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [navBlocker, setNavBlocker] = useState(false);
  const [gradeName, setGradeName] = useState("");
  const [level, setLevel] = useState("");
  let token = cookies.get("token");

  let levels = [
    {
      label: "Level 1",
      value: 1,
    },
    {
      label: "Level 2",
      value: 2,
    },
    {
      label: "Level 3",
      value: 3,
    },
    {
      label: "Level 4",
      value: 4,
    },
    {
      label: "Level 5",
      value: 5,
    },
    {
      label: "Level 6",
      value: 6,
    },
    {
      label: "Level 7",
      value: 7,
    },
    {
      label: "Level 8",
      value: 8,
    },
    {
      label: "Level 9",
      value: 9,
    },
    {
      label: "Level 10",
      value: 10,
    },
  ];

  // handle unsaved prompt
  useEffect(() => {
    if (gradeName.length !== 0 || level.length !== 0) {
      setNavBlocker(true);
    } else {
      setNavBlocker(false);
    }
  }, [gradeName, level]);
  usePrompt("Confirm it and save the changes!", navBlocker);

  // get grade details
  const getGradeDetails = async () => {
    if (id) {
      try {
        setLoading(true);
        const res = await axios.get(`${EXPRESS_API_BASE_URL}/grade/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // console.log("getSchoolsRes", res.data.data);
        setGradeName(res.data.data.name);
        setLevel(res.data.data.level);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("getSchools error", error);
      }
    }
  };

  useEffect(() => {
    getGradeDetails();
  }, [id]);

  // create/edit grade api integration
  const onCreateGrade = async (e) => {
    e.preventDefault();

    if (gradeName.length === 0) {
      toast.warn("Grade Name is required!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      if (id) {
        try {
          setNavBlocker(false);
          setLoading(true);
          const res = await axios.put(
            `${EXPRESS_API_BASE_URL}/grade/${id}`,
            {
              name: gradeName,
              // schoolId: parseInt(state?.schoolId),
              level: level,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          // console.log("edit grade res", res.data.data);
          navigate("/schools");
          toast.success("Grade updated successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log("edit grade error", error);
        }
      } else {
        try {
          setNavBlocker(false);
          setLoading(true);
          const res = await axios.post(
            `${EXPRESS_API_BASE_URL}/grade/`,
            {
              name: gradeName,
              schoolId: parseInt(schoolId),
              level: level,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          // console.log("create grade res", res.data.data);
          navigate("/schools");
          toast.success("Grade added successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log("create grade error", error);
        }
      }
    }
  };

  return (
    <div className="w-4/5 mobile:w-full ipad:w-full position-class pb-10">
      <div className="bg-secondary flex justify-between items-center p-5 stickyNavbar z-40">
        <p className="text-2xl font-semibold">
          {id ? "Edit Grade" : "Add Grade"}
        </p>

        <FilledButton
          onClickFilled={(e) => onCreateGrade(e)}
          filledBtnText="Save"
          loading={loading}
        />
      </div>

      <div className="w-3/4 px-5 ipad:w-full mobile:w-full my-5 ipad:mx-auto mobile:mx-auto">
        <div className="mb-2 w-full">
          <TextField
            style={{ width: "100%" }}
            id="outlined-multiline-flexible"
            label="Grade name *"
            value={gradeName}
            onChange={(e) => setGradeName(e.target.value)}
          />
        </div>

        <StyledForm autoComplete="off">
          <StyledFormControl variant="outlined">
            <InputLabel
              id="outlined-age-simple-label"
              sx={{
                color: "#006b5c",
              }}
            >
              Select level *
            </InputLabel>
            <StyledSelect
              sx={{
                color: "#006b5c",
              }}
              variant="outlined"
              label="Select level"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
            >
              {levels?.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        </StyledForm>
      </div>
    </div>
  );
};

export default CreateGrade;
