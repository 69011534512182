import React from "react";
import MainLogo from "../assets/MainLogo.png";

const EnrolledCoursesCard = (props) => {
  return (
    <div
      className="flex flex-col h-72 w-250 mobile:w-full mobile:h-96 bg-secondary shadow-lg 
        border rounded-xl outline-none overflow-hidden my-2 overflow-y-auto mobile:mx-auto"
    >
      <div className="rounded-xl outline-none h-3/5 pt-2 mb-2 overflow-hidden flex justify-center items-center mobile:h-4/5">
        <img
          src={props?.courseImg ? props.courseImg : MainLogo}
          alt="course img"
          className="course-card-image-width"
        />
      </div>

      <div className="flex flex-col justify-between my-3 mx-5 h-2/5">
        <div>
          {props.Title ? (
            <p className="text-lg text-primary font-semibold uppercase">
              {props.Title}
            </p>
          ) : null}

          {props.Desc ? (
            <p className="text-xs text-black py-2 capitalize">{props.Desc}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EnrolledCoursesCard;
