import React from "react";
import FilledButton from "./FilledButton";
import UnFilledButton from "./UnFilledButton";
import useWindowSize from "../constants/UseWindowSize";

const SchoolCard = ({
  name,
  unFilledBtnText,
  onClickUnFilled,
  loading,
  deleteBtnOnClick,
  deleteBtnText,
  softDelete,
  onClickDiv,
  filledBtnText,
  onClickFilled,
  noOfChildsText,
  noOfChilds,
}) => {
  const { width, height } = useWindowSize();

  return (
    <div
      style={{ width: width < 500 ? "330px" : "310px" }}
      className="border rounded-md shadow-md px-4 h-40 flex items-center bg-secondary overflow-y-auto mobile:mx-auto"
    >
      <div className="w-full">
        <div
          className={`my-2 w-full ${onClickDiv ? "cursor-pointer" : ""}`}
          onClick={onClickDiv}
        >
          <p className="font-semibold text-xl capitalize text-gray-600 break-words">
            {name}
          </p>

          {noOfChildsText ? (
            <p className="font-semibold text-sm text-gray-600 mt-2">
              {noOfChildsText}: {noOfChilds}
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="flex items-center justify-end w-full mt-8">
          <div className="flex items-center justify-between">
            {filledBtnText ? (
              <span className="mr-2">
                <FilledButton
                  filledBtnText={filledBtnText}
                  onClickFilled={onClickFilled}
                  loading={loading}
                />
              </span>
            ) : null}

            {unFilledBtnText ? (
              <UnFilledButton
                unFilledBtnText={unFilledBtnText}
                onClickUnFilled={onClickUnFilled}
                loading={loading}
              />
            ) : null}

            {deleteBtnText ? (
              <button
                disabled={loading === true ? true : false}
                onClick={deleteBtnOnClick}
                className={`border ${
                  softDelete === null || softDelete === 0
                    ? "border-red-800"
                    : "border-green-800"
                } ${
                  softDelete === null || softDelete === 0
                    ? "bg-red-500"
                    : "bg-green-600"
                }  text-white rounded-3xl 
                  py-2 px-4 text-sm font-semibold ml-2 ${
                    softDelete === null || softDelete === 0
                      ? "hover:bg-red-600"
                      : "hover:bg-green-700"
                  } `}
              >
                {loading === true ? "Loading..." : deleteBtnText}
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolCard;
