import React from "react";
import FilledButton from "./FilledButton";
import UnFilledButton from "./UnFilledButton";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteFilledButton from "./DeleteFilledButton";
import MainLogo from "../assets/MainLogo.png";

const AssignmentCard = (props) => {
  return (
    <div className="relative w-280 mobile:w-11/12 mobile:mx-auto overflow-hidden">
      {props?.isDelete === true ? (
        <button
          onClick={props?.handleRemove}
          className="absolute -right-3 -top-3 border rounded-full p-1 bg-red-500 z-30"
        >
          <RiDeleteBin6Line className="text-white text-lg" />
        </button>
      ) : (
        <></>
      )}

      <div
        key={props?.keyIndex}
        style={{ height: props?.teacherCourseCardHere ? "280px" : "320px" }}
        className={`flex flex-col w-full mobile:h-400 bg-secondary overflow-x-hidden shadow-lg 
          border rounded-xl outline-none my-2 overflow-y-auto`}
      >
        <div
          onClick={props?.onClickDiv}
          className={`rounded-xl outline-none h-3/5 flex justify-center items-center ${
            props?.onClickDiv ? "cursor-pointer" : ""
          } overflow-hidden mobile:p-5`}
        >
          <img
            src={props?.courseImg ? props.courseImg : MainLogo}
            alt="course img"
            className={props.imageClassname}
          />
        </div>

        <div className="flex flex-col justify-between mx-4 mt-3 h-2/5">
          <div onClick={props?.onClickDiv}>
            {props.Title ? (
              <p
                className={`text-primary font-semibold uppercase ${
                  props?.onClickDiv ? "cursor-pointer" : ""
                } text-lg`}
              >
                {props.Title}
              </p>
            ) : null}
          </div>

          <div className="absolute bottom-10 w-11/12 bg-red-600">
            {props.CreatedBy ? (
              <p className="font-semibold text-xs  gap-1 py-2 mobile:py-3">
                submitted by:{" "}
                <span className="text-sm ipad:text-sm mobile:text-sm flex flex-wrap">
                  {props.CreatedBy}
                </span>
              </p>
            ) : null}
          </div>

          <div className="flex justify-end gap-2 absolute bottom-5 right-3 mt-2">
            {props.unFilledBtnText ? (
              <UnFilledButton
                unFilledBtnText={props.unFilledBtnText}
                onClickUnFilled={props.onClickUnFilled}
                loading={props.loading}
              />
            ) : null}

            {props.filledBtnText ? (
              <FilledButton
                filledBtnText={props.filledBtnText}
                onClickFilled={props.onClickFilled}
                loading={props.loading}
              />
            ) : null}

            {props.deleteFilledBtnText ? (
              <DeleteFilledButton
                deleteFilledBtnText={props.deleteFilledBtnText}
                onClickDeleteFilled={props.onClickDeleteFilled}
                loading={props.loading}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentCard;
