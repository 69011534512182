import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import FilledButton from "../customs/FilledButton";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../customs/Loader";
import axios from "axios";
import { EXPRESS_API_BASE_URL } from "../constant";
import { usePrompt } from "../constants/UsePrompt";

const CreateSchoolPage = () => {
  const { id } = useParams();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [schoolName, setSchoolName] = useState("");
  const [schoolEmail, setSchoolEmail] = useState("");
  const [addressLineOne, setAddressLineOne] = useState("");
  const [addressLineTwo, setAddressLineTwo] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [navBlocker, setNavBlocker] = useState(false);
  let token = cookies.get("token");

  usePrompt("Confirm it and save the changes!", navBlocker);

  // get schools dynamic list
  const getSchoolDetails = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${EXPRESS_API_BASE_URL}/school/${parseInt(id)}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (res.data.data) {
        //  console.log("getSchoolDetails Res", res.data.data);
        setSchoolName(res.data.data?.name);
        setSchoolEmail(res.data.data?.email);
        setAddressLineOne(res.data.data?.addressLine1);
        setAddressLineTwo(res.data.data?.addressLine2);
        setState(res.data.data?.state);
        setCity(res.data.data?.city);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("getSchoolDetails error", error);
    }
  };

  useEffect(() => {
    getSchoolDetails();
  }, [id]);

  // add/edit school
  const onSaveSchool = async () => {
    const checkTitleLength = schoolName.length > 5;

    if (!checkTitleLength) {
      toast.warn("School Name should have minimum 5 characters!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else if (!schoolEmail) {
      toast.warn("School Email required!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else if (!addressLineOne) {
      toast.warn("Address required!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else if (!state) {
      toast.warn("State required!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else if (!city) {
      toast.warn("City required!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      if (id) {
        try {
          setLoading(true);
          const res = await axios.put(
            `${EXPRESS_API_BASE_URL}/school/${id}`,
            {
              name: schoolName,
              email: schoolEmail,
              addressLine1: addressLineOne,
              addressLine2: addressLineTwo,
              state: state,
              city: city,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );

          // console.log("edit school res", res.data.data);
          navigate("/schools");
          setNavBlocker(false);
          toast.success("School updated successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log("edit school error", error);
        }
      } else {
        try {
          setNavBlocker(false);
          setLoading(true);
          const res = await axios.post(
            `${EXPRESS_API_BASE_URL}/school/`,
            {
              name: schoolName,
              email: schoolEmail,
              addressLine1: addressLineOne,
              addressLine2: addressLineTwo,
              state: state,
              city: city,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );

          // console.log("create school res", res.data.data);
          navigate("/schools");
          toast.success("School created successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });

          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log("create school error", error);
        }
      }
    }
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="w-4/5 mobile:w-full ipad:w-full position-class pb-10">
          <div className="bg-secondary flex justify-between items-center p-5 stickyNavbar z-40">
            <p className="text-2xl font-semibold">
              {id ? "Edit School" : "Add School"}
            </p>

            <FilledButton
              onClickFilled={onSaveSchool}
              filledBtnText="Save"
              loading={loading}
            />
          </div>

          <div className="mt-5 w-3/4 ipad:w-full mobile:w-full px-5 ipad:mx-auto mobile:mx-auto">
            <TextField
              style={{ width: "100%" }}
              id="outlined-multiline-flexible"
              label="School name *"
              value={schoolName}
              onChange={(e) => {
                if (e.target.value.length !== 0) {
                  setNavBlocker(true);
                  setSchoolName(e.target.value);
                } else {
                  setNavBlocker(false);
                }
              }}
            />
          </div>

          <div className="mt-5 w-3/4 ipad:w-full mobile:w-full px-5 ipad:mx-auto mobile:mx-auto">
            <TextField
              style={{ width: "100%" }}
              id="outlined-multiline-flexible"
              label="School email *"
              value={schoolEmail}
              onChange={(e) => {
                if (e.target.value.length !== 0) {
                  setNavBlocker(true);
                  setSchoolEmail(e.target.value);
                } else {
                  setNavBlocker(false);
                }
              }}
            />
          </div>

          <div className="mt-5 w-3/4 ipad:w-full mobile:w-full px-5 ipad:mx-auto mobile:mx-auto">
            <TextField
              style={{ width: "100%" }}
              id="outlined-multiline-flexible"
              label="Address Line 1 *"
              value={addressLineOne}
              onChange={(e) => {
                if (e.target.value.length !== 0) {
                  setNavBlocker(true);
                  setAddressLineOne(e.target.value);
                } else {
                  setNavBlocker(false);
                }
              }}
            />
          </div>

          <div className="mt-5 w-3/4 ipad:w-full mobile:w-full px-5 ipad:mx-auto mobile:mx-auto">
            <TextField
              style={{ width: "100%" }}
              id="outlined-multiline-flexible"
              label="Address Line 2"
              value={addressLineTwo}
              onChange={(e) => {
                if (e.target.value.length !== 0) {
                  setNavBlocker(true);
                  setAddressLineTwo(e.target.value);
                } else {
                  setNavBlocker(false);
                }
              }}
            />
          </div>

          <div className="mt-5 w-3/4 ipad:w-full mobile:w-full px-5 ipad:mx-auto mobile:mx-auto">
            <TextField
              style={{ width: "100%" }}
              id="outlined-multiline-flexible"
              label="State *"
              value={state}
              onChange={(e) => {
                if (e.target.value.length !== 0) {
                  setNavBlocker(true);
                  setState(e.target.value);
                } else {
                  setNavBlocker(false);
                }
              }}
            />
          </div>

          <div className="mt-5 w-3/4 ipad:w-full mobile:w-full px-5 ipad:mx-auto mobile:mx-auto">
            <TextField
              style={{ width: "100%" }}
              id="outlined-multiline-flexible"
              label="City *"
              value={city}
              onChange={(e) => {
                if (e.target.value.length !== 0) {
                  setNavBlocker(true);
                  setCity(e.target.value);
                } else {
                  setNavBlocker(false);
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CreateSchoolPage;
